import React, { useEffect, useState } from "react";
import NavigationItem from "./NavigationItem";
import { NavItemType } from "shared/Navigation/NavigationItem";
import { MEGAMENU_TEMPLATES, cscLedMenu as initialMenu } from "data/navigation";
import axiosInstance from "api/AxiosInstance";
import { BaseApiResponse } from "entities/ApiResponse";
import { NavMenuDTO, ProductMenuItems } from "entities/ProductNavMenu";
import ncNanoId from "utils/ncNanoId";
import { BaseFilterString } from "entities/BaseEntities";

function Navigation() {
  const [cscLedMenu, setCscLedMenu] = useState(initialMenu);
  const [dataResult, setDataResult] =
    useState<BaseApiResponse<NavMenuDTO> | null>(null);

  const appendMegaMenu = (item: ProductMenuItems) => {
    return [
      appendMegaMenuItems(
        "Department",
        "product-type",
        item.departmentType,
        false
      ),
      appendMegaMenuItems(
        "Product Type",
        "product-series",
        item.prodType,
        false
      ),
      appendMegaMenuItems(
        "Product Series",
        "products",
        item.prodSeriesType,
        true
      ),
    ];
  };

  const appendMegaMenuItems = (
    menuTitle: string,
    menuRoute: string,
    item: BaseFilterString[],
    isEncodeUrl: boolean
  ) => {
    const navChildren: NavItemType[] = item.map((dept, index) => ({
      id: ncNanoId(),
      href: `/${menuRoute}/${
        isEncodeUrl ? encodeURIComponent(dept.filterName) : dept.filterId
      }`,
      name: dept.filterName,
    }));

    return {
      id: ncNanoId(),
      href: "/",
      name: `${menuTitle}`,
      children: navChildren,
    };
  };

  const appendDropdownMenuChildren = (
    name: string,
    menuChildren: NavItemType[]
  ) => {
    const resourcesMenuItem = cscLedMenu.find((item) => item.name === name);
  
    if (resourcesMenuItem) {
      // Ensure children array is initialized
      if (!resourcesMenuItem.children) {
        resourcesMenuItem.children = [];
      }
  
      menuChildren.forEach((item) => {
        // Check if the item already exists in the children array
        const isDuplicate = resourcesMenuItem.children!.some(
          (child) => child.href === item.href || child.name === item.name
        );
  
        // If not a duplicate, add the item to the children array
        if (!isDuplicate) {
          resourcesMenuItem.children!.push({
            id: ncNanoId(),
            href: item.href,
            name: item.name,
            targetBlank:true,
          });
        }
      });
    }
  };
  
  

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getWebsiteNavMenu")
      .then((response) => {
        setDataResult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (dataResult?.data) {
      setCscLedMenu((prevMenu) => {
        const updatedMenu = [...prevMenu];
        updatedMenu[0].children = appendMegaMenu(dataResult.data.productMenu);
        return updatedMenu;
      });
      appendDropdownMenuChildren("Resources",dataResult.data.resourceMenu);
    }
  }, [dataResult]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ul className="nc-Navigation flex items-center">
      {cscLedMenu.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
