import React from 'react';
import "./Alert.css";
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

const Alert = ({ headerErrorMsg = "", errorMessage = "" }) => {
    return (
      <div className="border-l-4 border-red-400 bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0 mt-1">
          <ExclamationTriangleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-base text-red-700">
          {headerErrorMsg}</p>
          <p> <span className='text-sm text-red-600'  dangerouslySetInnerHTML={{ __html: errorMessage }} />
          
          </p>
        </div>
      </div>
    </div>
    );
  };
  
  export default Alert;