import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useScript from "hooks/useScript";
import Divider from "components/Divider";
import HeadingText1 from "components/HeadingText/HeadingText1";

export interface RebateSavingsProps {
  className?: string;
}

const dataToken = "ab4abd68-e092-4598-af47-6481b7127af5";

const RebateSavings: FC<RebateSavingsProps> = ({ className = "" }) => {
  useScript("//cdn.encentivizer.com/widgets/widget_base.js");

  return (
    <div className={`nc-PrivatePolicy ${className}`} data-nc-id="RebateSavings">
      <Helmet>
        <title>Rebate Savings | CSC LED</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <HeadingText1 className="font-poppins">Rebate Savings</HeadingText1>
          </div>
          <Divider />
          <main>
            <div id="ee_widget_container" data-token={dataToken}></div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default RebateSavings;
