import BagIcon from "components/BagIcon";
import CurrencyFormatter from "components/CurrencyFormatter";
import Divider from "components/Divider";
import NcInputNumber from "components/NcInputNumber";
import { BaseProp } from "entities/BaseProp";
import { SubstituteClearanceItem } from "entities/ProductDetailsDTO";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import NcImage from "shared/NcImage/NcImage";
import NcLink from "shared/NcLink/NcLink";
import ModalNotifyToCart from "./ModalNotifyToCart";
import ModalErrorNotify from "./ModalErrorNotify";
import axiosInstance from "api/AxiosInstance";
import { getUserLoginData } from "utils/auth";
import AddToCartComponent from "./AddToCartComponent";
import FlyerPagination from "containers/SeriesFlyers/FlyerPagination";
import { useUserState } from "hooks/useUserState";
import HeadingText1 from "components/HeadingText/HeadingText1";
import { useAppDispatch } from "../../store/hooks";
import { getCartData } from "../../store/slices/cartSlice";
import { getCartListData } from "../../store/slices/cartDetailListSlice";
import { getCartIndexListData } from "../../store/slices/cartIndexListSlice";
import handleScrollToEl from "utils/HandleScrollToEl";

interface suggestedTradePriceProps extends BaseProp {
  productList: SubstituteClearanceItem[];
  pageTitle: string;
  isShortDataList?:boolean;
}

const SuggestedProducts: FC<suggestedTradePriceProps> = ({
  className = "",
  pageTitle = "",
  productList,
  isShortDataList
}) => {
  const userData = getUserLoginData();
  const _userStateConfig = useUserState();
  const dispatch = useAppDispatch();

  const [isModalAddToCart, setIsModalAddToCart] = useState(false);
  const [isModalWarningMessage, setIsModalWarningMessage] = useState(false);
  const [addToCartMessage, setAddToCartMessage] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productList?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    handleScrollToEl(pageTitle)
  };

  const onCartClose = () => setIsModalAddToCart(false);

  const renderAddToCart = (item: SubstituteClearanceItem) => {
    return (
      <AddToCartComponent
        item={item}
        onShowAddToCartModal={setIsModalAddToCart}
        setAddToCartMessage={setAddToCartMessage}
        setIsModalWarningMessage={setIsModalWarningMessage}
      />
    );
  };

  const renderMobileView = (item: SubstituteClearanceItem, index: number) => {
    return (
      <dl
        key={item.productId}
        className="nc-SuggestedProductMobile font-normal sm:hidden"
      >
        <dd className="mt-1 text-gray-700">
          <NcLink
            to={`/product-details/${item.productNumber.toUpperCase()}`}
          >
            <h2 className="nc-card-title block text-base font-bold text-neutral-900 dark:text-neutral-100 halloween:text-black">
              {item.productName}
            </h2>
            <h2 className="nc-card-title block text-base font-bold text-csccolor">
              {item.productTitle}
            </h2>
          </NcLink>
        </dd>
        <dd className="mt-1 text-gray-700">{item.description}</dd>
        {userData && (
          <>
            <dd className="mt-3 text-gray-700">
              <div className="flex flex-col">
                <div className="basis-full flex">
                  <span className="w-1/3">Your Price: </span>
                  <span>
                    <CurrencyFormatter value={item.price} />
                  </span>
                </div>
                <div className="basis-full flex">
                  <span className="w-1/3">AB Qty: </span>
                  <span>{item.abQty}</span>
                </div>
                <div className="basis-full flex">
                  <span className="w-1/3">BC Qty: </span>
                  <span>{item.bcQty}</span>
                </div>
                <div className="basis-full flex">
                  <span className="w-1/3">ON Qty: </span>
                  <span>{item.onQty}</span>
                </div>
              </div>
            </dd>
            {!_userStateConfig?.isHideCart && (
              <dd className="mt-1 block w-auto">{renderAddToCart(item)}</dd>
            )}
          </>
        )}
      </dl>
    );
  };

  const renderMain = () => {
    return (
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-sm text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-3 py-2">
              Product
            </th>
            <th className={`${!userData && "sm:w-1/3"}`}>
              <span className="sm:hidden">Description</span>
            </th>
            <th scope="col" className={`hidden sm:table-cell px-3 py-2 ${userData ? "w-1/4":"w-2/4"}`}>
              Description
            </th>
            {userData && (
              <>
                <th scope="col" className="hidden sm:table-cell px-3 py-2">
                  AB Qty
                </th>
                <th scope="col" className="hidden sm:table-cell px-3 py-2">
                  BC Qty
                </th>
                <th scope="col" className="hidden sm:table-cell px-3 py-2">
                  ON Qty
                </th>
                <th scope="col" className="hidden sm:table-cell px-3 py-2">
                  Your Price
                </th>
                {!_userStateConfig?.isHideCart && (
                  <th scope="col" className="hidden sm:table-cell px-3 py-2">
                    Quantity
                  </th>
                )}
              </>
            )}
          </tr>
        </thead>
        <tbody className="text-neutral-900 dark:text-neutral-100 halloween:text-black">
          {currentItems.map((item, index) => {
            return (
              <tr
                key={item.productId}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td scope="row" className="px-3 py-2">
                  <Link
                    to={`/product-details/${item.productNumber.toUpperCase()}`}
                    title={"title"}
                    className={`block w-20 flex-shrink-0 relative rounded-lg overflow-hidden sm:ml-4 sm:mb-0 group`}
                  >
                    <NcImage
                      containerClassName="h-20 w-20"
                      className="object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
                      src={item.lowResImage}
                    />
                  </Link>
                </td>
                <td className="py-2">
                  <NcLink
                    to={`/product-details/${item.productNumber.toUpperCase()}`}
                    className="hidden sm:block"
                  >
                    <h2 className="nc-card-title whitespace-nowrap block text-base font-bold text-neutral-900 dark:text-neutral-100 halloween:text-black">
                      {item.productName}
                    </h2>
                    <h2 className="nc-card-title whitespace-nowrap block text-base font-bold text-csccolor">
                      {item.productTitle}
                    </h2>
                  </NcLink>
                  {renderMobileView(item, index)}
                </td>
                <td className="hidden sm:table-cell px-3 py-2">
                  {item.description}
                </td>
                {userData && (
                  <>
                    <td
                      className="hidden sm:table-cell px-3 py-2 sm:w-20"
                      title="Alberta"
                    >
                      {item.abQty}
                    </td>
                    <td
                      className="hidden sm:table-cell px-3 py-2 sm:w-20"
                      title="British Columbia"
                    >
                      {item.bcQty}
                    </td>
                    <td
                      className="hidden sm:table-cell px-3 py-2 sm:w-20"
                      title="Ontario"
                    >
                      {item.onQty}
                    </td>
                    <td className="hidden sm:table-cell px-3 py-2 sm:w-20">
                      <span className="nc-card-title" title="Your Price">
                        <CurrencyFormatter value={item.price} />
                      </span>
                    </td>
                    {!_userStateConfig?.isHideCart && (
                      <td className="hidden sm:table-cell px-3 py-2 sm:w-32">
                        {renderAddToCart(item)}
                      </td>
                    )}
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const onShortDataListAddtoCart = () => {   
    setIsModalAddToCart(false); // Close the modal
    dispatch(getCartListData());
    dispatch(getCartData());
    dispatch(getCartIndexListData());
};

  return (
    <>
      {productList && productList.length > 0 && (
        <div className={`nc-SuggestedProducts ${className} space-y-10 scroll-mt-32`} id={pageTitle}>
          <HeadingText1 className="font-poppins my-8">{pageTitle}</HeadingText1>
          <div className="overflow-x-auto">
            {renderMain()}
            {/* PAGINATION */}
            {productList.length > itemsPerPage && (
              <FlyerPagination
                className="py-3 pr-3 sm:pr-0"
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={productList?.length as number}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      )}
      <ModalNotifyToCart
        isModalOpen={isModalAddToCart}
        onCloseModal={onCartClose}
        modalBody={addToCartMessage}
        isShortDataList={isShortDataList}
        onCloseIsShortListAdded={onShortDataListAddtoCart}
      />
      <ModalErrorNotify
        isModalOpen={isModalWarningMessage}
        onCloseModal={() => setIsModalWarningMessage(false)}
        titleMsg="Human, your selected quantity is either blank or zero. As result, I can't add this this product to your cart."
        confirmButtonText="I understand computer"
      />
    </>
  );
};

export default SuggestedProducts;
