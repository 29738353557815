import { BaseProp } from "entities/BaseProp";
import React, { FC } from "react";

interface Props extends BaseProp {
  children?: React.ReactNode;
}

const HeadingText1: FC<Props> = ({ 
    className = "my-2"
    , children 
}) => {
  return (
    <h2 className={`nc-HeaderText1 text-3xl md:text-4xl font-semibold ${className}`}>
        {children}
    </h2>
  );
};

export default HeadingText1;
