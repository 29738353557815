import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import JobComponent from "./JobComponent";
import { getCareerData } from "../../store/slices/careerSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import SkeletonCareer from "./SkeletonCareer";
import HeadingText2 from "components/HeadingText/HeadingText2";
import HeadingText1 from "components/HeadingText/HeadingText1";

interface CareersProps {
  className?: string;
}

const Careers: FC<CareersProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const careerData = useAppSelector((state) => state.career.data) as any;
  const isAPILoad = useAppSelector(
    (state) => state.career.isLoading
  ) as boolean;

  useEffect(() => {
    if (!careerData) {
      dispatch(getCareerData());
    }
  }, [dispatch, careerData]);

  if (isAPILoad) {
    return (
      <>
        <SkeletonCareer />
      </>
    );
  }

  return (
    <div className="nc-Career relative overflow-hidden">
      <Helmet>
        <title>Careers | CSC LED</title>
      </Helmet>
      <div className="relative overflow-hidden lg:aspect-w-16 lg:aspect-h-8 2xl:aspect-h-7">
        <img
          className="relative w-full h-full object-fill"
          src={`${careerData?.careerBanner}`}
          alt="hero"
        />
      </div>
      <div className="container mt-5 lg:mt-11 pb-16">
        <div className="space-y-5">
          <HeadingText1 className="font-poppins">
            Our Open Positions
          </HeadingText1>
          <div className="sm:max-w-full mt-7 halloween:text-black space-y-3">
            <p className="">
              CSC LED comprises a team of forward-thinking professionals
              dedicated to advancing the Canadian commercial and industrial
              lighting sectors. We are committed to delivering unparalleled
              customer service and premium-quality products at competitive
              prices. Our organization aims to foster an environment where
              individuals can thrive professionally, pursue their interests, and
              cultivate lasting professional relationships.
            </p>
            <div className="text-sm sm:text-lg">
              <span className="block font-semibold">
                Select one of our branches below to view available positions.
              </span>
            </div>
          </div>
          <JobComponent />
        </div>
      </div>
    </div>
  );
};

export default Careers;
