import React from "react";
import "styles/pageloader.css"; // Assuming you saved the CSS code in a file named PageLoader.css

interface PageLoaderProps {
  isLoading?: boolean;
}

const PageLoader: React.FC<PageLoaderProps> = ({ isLoading = true }) => {
  return (
    <>
      {isLoading && (
        <div id="CssLoader">
          <div className="exactCenter">
            <div className="spinftw"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageLoader;
