import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const AutocompleteExample = () => {
  const [address, setAddress] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [city, setCity] = useState<any>('');
  const [postcode, setPostcode] = useState<any>('');
  const [province, setProvince] = useState<any>('');

  const handleSelect = async (selectedAddress: any) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const addressComponents = results[0].address_components;
      let country = '';
      let city = '';
      let postcode = '';
      let province='';

      for (const component of addressComponents) {
        if (component.types.includes('country')) {
          country = component.long_name;
        }
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('postal_code')) {
          postcode = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          province = component.long_name;
        }
      }

      setCountry(country);
      setCity(city);
      setPostcode(postcode);
      setProvince(province);
    } catch (error) {
      console.error('Error fetching address data', error);
    }
  };

  return (
    <div>
      <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input {...getInputProps({ placeholder: 'Enter your address' })} />
            <div>
              {loading ? <div>Loading...</div> : null}

              {suggestions.map((suggestion: any) => {
                const style = {
                  backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                };

                return (
                  <div  {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <div>
        <label>Country</label>
        <input type="text" value={country} readOnly />
      </div>

      <div>
        <label>City</label>
        <input type="text" value={city} readOnly />
      </div>  
      <div>
        <label>Postcode</label>
        <input type="text" value={postcode} readOnly />
      </div>
      <div>
        <label>Province</label>
        <input type="text" value={province} readOnly />
      </div>
      
    </div>
  );
};

export default AutocompleteExample;
