import axiosInstance from "api/AxiosInstance";
import { BaseBanners } from "entities/BaseEntities";
import { BaseProp } from "entities/BaseProp";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useBoolean, useInterval } from "react-use";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import { getUserLoginData } from "utils/auth";
import { config } from "utils/Constance";

interface BannerCarouselProps extends BaseProp {
  images: BaseBanners[];
}

let TIME_OUT: NodeJS.Timeout | null = null;

const BannerCarousel: React.FC<BannerCarouselProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [direction, setDirection] = useState<"left" | "right" | null>(null);
  const [isRunning, toggleIsRunning] = useBoolean(true);

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );

  const handleAutoNext = () => {
    setDirection("right");
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevImage = () => {
    setDirection("left");
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    handleAfterClick();
  };

  const goToNextImage = () => {
    setDirection("right");
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };

  const handleBannerInteractionClick = (bannerId: number) => {
    const logClick = async () => {
      const response = await axiosInstance
        .post(`/Home/logBannerClick/${bannerId}`)
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    
    logClick();
  };

  return (
    <div className="relative overflow-hidden">
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
        {images.map((_, index) => {
          const isActive = currentImageIndex === index;
          return (
            <div
              key={index}
              onClick={() => {
                setCurrentImageIndex(index);
                handleAfterClick();
              }}
              className={`relative px-1 py-1.5 cursor-pointer`}
            >
              <div
                className={`relative w-3 sm:w-5 h-1 shadow-sm rounded-md bg-white`}
              >
                {isActive && (
                  <div
                    className={`absolute inset-0 bg-slate-900 rounded-md ${
                      isActive ? "" : ""
                    }`}
                  ></div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <Prev
        className="absolute left-1 sm:left-5 top-2/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
        btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
        svgSize="w-6 h-6"
        onClickPrev={goToPrevImage}
      />
      <Next
        className="absolute right-1 sm:right-5 top-2/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
        btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
        svgSize="w-6 h-6"
        onClickNext={goToNextImage}
      />
      <div
        className={`transition-transform duration-500 ${
          direction === "left"
            ? "-translate-x-full"
            : direction === "right"
            ? "translate-x-full"
            : ""
        }`}
        style={{
          width: `${images.length}00%`,
          transform: `translateX(-${
            currentImageIndex * (100 / images.length)
          }%)`,
        }}
      >
        {images.map((item, index) => (
          <Link
            key={index}
            to={item.bannerLink}
            target="_blank"
            onClick={() => handleBannerInteractionClick(item.id)}
          >
            <img
              src={item.bannersURL}
              alt={`BannerImage ${index}`}
              className="inline-block w-full h-auto"
              style={{ width: `${100 / images.length}%` }}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BannerCarousel;
