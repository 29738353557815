import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { careerDTO, CareerPosting } from "entities/careerDTO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faHandPointRight,
  faListCheck,
  faPen,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface JobOpeningsProps {
  className?: string;
}

const JobOpeningsComponent: FC<JobOpeningsProps> = () => {
  var activeLocation = useAppSelector(
    (state) => state.career.activeLocation
  ) as string;
  const obj = useAppSelector(
    (state) => state.career.data?.openingsJobList
  ) as CareerPosting[];
  const [openingList, setOpeningList] = useState<CareerPosting[]>([]);

  useEffect(() => {
    // Fetch the job list based on the active location
    if (activeLocation && obj) {
      var activeOpenings = obj.filter((a) => a.location === activeLocation);
      setOpeningList(activeOpenings);
    }
  }, [activeLocation]);

  const renderOpeningHtml = (
    title: string,
    rawHtml: string,
    icon: IconProp
  ) => {
    return (
      <div className="">
        <div className="my-4 font-semibold text-sm">
          <FontAwesomeIcon icon={icon} className="text-sm mr-2" />
          {title}:
        </div>
        <div
          className="pl-5 prose prose-sm sm:prose dark:prose-invert bio-container !text-sm max sm:max-w-3xl"
          dangerouslySetInnerHTML={{
            __html: rawHtml,
          }}
        ></div>
      </div>
    );
  };

  const renderJobOpenings = () => {
    return (
      <>
        {openingList.map((items, index) => {
          return (
            <div
              key={index}
              className="p-4 border border-neutral-100 halloween:border-white rounded-md"
            >
              <div className="">
                <span className="text-sm font-semibold">
                  <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
                  Position:
                </span>
                <div className="pl-5 mt-3 text-black dark:text-neutral-400 text-sm font-semibold">
                  {items.jobTitle}
                </div>
              </div>
              {items.description &&
                renderOpeningHtml(`About The Role`, items.description, faUser)}
              {items.responsibilities &&
                renderOpeningHtml(
                  `Responsibilities`,
                  items.responsibilities,
                  faListCheck
                )}
              {items.requirements &&
                renderOpeningHtml(`Qualifications`, items.requirements, faPen)}
              {items.benifitesandPerks &&
                renderOpeningHtml(
                  `Benefits And Perks`,
                  items.benifitesandPerks,
                  faHandPointRight
                )}
            </div>
          );
        })}
      </>
    );
  };

  const renderNoOpenings = () => {
    return (
      <div className="border-l-4 border-blue-400 bg-blue-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <p className="text-sm text-blue-800">
              Unfortunately we are not looking for any new team members for this
              location right now. Please check back soon!
            </p>
          </div>
        </div>
      </div>
    );
  };

  const applyButton = () => {
    return (
      <>
        {openingList.length !== 0 && (
          <div className="my-5">
            <ButtonPrimary className="w-full sm:w-64" href="/apply-now">
              Apply Now
            </ButtonPrimary>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="nc-JobOpenings flex-grow mt-3 sm:mt-0" id="jobOpenings">
        <div className="flex flex-col">
          {openingList.length === 0 ? (
            renderNoOpenings()
          ) : (
            <div className="space-y-5">{renderJobOpenings()}</div>
          )}
        </div>
        <div>{applyButton()}</div>
      </div>
    </>
  );
};

export default JobOpeningsComponent;
