import axiosInstance from "api/AxiosInstance";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "store/hooks";

interface UserStateContextProps {
  isHideCart: boolean;
  isHideOrder:boolean;
  isViewChangePassword:boolean;
}

const UserStateContext = createContext<UserStateContextProps | undefined>(
  undefined
);

export const UserStateProvider = ({ children }: { children: ReactNode }) => {
  const userData = useAppSelector((state) => state.auth);

  const [userState, setUserState] = useState<UserStateContextProps | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/SFAccount/getUserState");
        if (response.status === 200) {
          setUserState(response.data.data);
        }
      } catch (error) {
        // Suppress console error logging
      }
    };

    if (userData.isAuthenticated) {
      fetchData();
    }else{
      setUserState(undefined)
    }
  }, [userData]);

  return (
    <UserStateContext.Provider value={userState}>
      {children}
    </UserStateContext.Provider>
  );
};

export const useUserState = (): UserStateContextProps|null => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    return null;
    //throw new Error("useUserState must be used within a UserStateProvider");
  }
  return context;
};
