////////////////////

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/AxiosInstance";
import { RootState } from "store/store";

interface CartState {
  count: number;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CartState = {
  count: 0,
  status: 'idle',
  error: null,
};

export const getCartData = createAsyncThunk('cart/getCartData', async (_, { getState }) => {
  const state = getState() as RootState;
  const user = state.auth; // Adjust based on your auth state structure

  const response = await axiosInstance.get(`/Cart/getCartCountsById`);
  //console.log("response data", response.data);
  return response.data;
});

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCartData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCartData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.count = action.payload; // Assuming the API returns { count: number }
      })
      .addCase(getCartData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to load cart data';
      });
  },
});

export default cartSlice.reducer;
