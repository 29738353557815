import React from 'react'
import Button, { ButtonProps } from './Button'

export interface ButtonCSCLedProps extends ButtonProps {}

const ButtonCSCLed: React.FC<ButtonCSCLedProps> = ({
    className = "",
    ...args
  })  => {
  return (
    <Button
    className={`ttnc-ButtonCSCLed disabled:bg-opacity-90 bg-csccolor hover:bg-blue-700 text-slate-50 shadow-xl ${className}`}
    {...args}
  />
  )
}

export default ButtonCSCLed
