import ContactUsRawHTML from "containers/ContactUs/ContactUsRawHTML";
import { BaseProp } from "entities/BaseProp";
import { BannerItem } from "entities/ProductsDTO";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";

export interface ProductBannerProps extends BaseProp {
  className?: string;
  item: BannerItem;
}

const ProductBanner: FC<ProductBannerProps> = ({ className = "", item }) => {
  return (
    <div className={`${className} space-y-4`}>
      {item.bannerImage && (
        <NcImage className="w-full" src={`${item.bannerImage}`} />
      )}
      {item.bannerHtml && (
        <ContactUsRawHTML className="ck-content" rawHtml={item.bannerHtml} />
      )}
      {item.bannerText && (
        <ContactUsRawHTML className="ck-content" rawHtml={item.bannerText} />
      )}
    </div>
  );
};

export default ProductBanner;
