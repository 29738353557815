import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "api/AxiosInstance";

const RegistrationSuccessPage = () => {
  const { activation } = useParams<{ activation: string }>();
  const [AlreadyClickedOnlink, SetAlreadyClickedOnlink] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (activation) {
          const response = await axiosInstance.get(
            `Home/RegistrationSuccessPage?activation=${activation}`
          );
          SetAlreadyClickedOnlink(response.data);
        } else {
          console.error("Activation parameter is missing.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [activation]);

  return (
    <>
      {AlreadyClickedOnlink == false && (
        <div className="nc-RegistrationSuccessPage container mb-12 lg:mb-32" id="RegistrationSuccessPage">
          <h2 className="my-20 mt-10 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] capitalize font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Email Address Verified
          </h2>
          <span
            style={{ marginTop: "-65px" }}
            className="my-20 flex items-center text-1xl leading-[115%] md:text-1xl md:leading-[115%] text-neutral-900 dark:text-neutral-100 justify-center"
          >
            So it turns out you weren't an imposter.
          </span>
          <div className="mx-5">
            <hr
              className="border-t border-gray-300"
              style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
            />
          </div>
          <div className="w-full text-center items-center space-y-5">
            <p>We will be in touch within 1-2 business after your account has been set up.</p>
            <p> We will send you an email once you're good to go!</p>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationSuccessPage;
