import { BaseProp } from "entities/BaseProp";
import React from "react";
import { Link } from "react-router-dom";
import ncNanoId from "utils/ncNanoId";

interface ServerPaginationProps extends BaseProp {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
}

const ServerPagination: React.FC<ServerPaginationProps> = ({
  className = "",
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const getPaginationRange = () => {
    const range: (number | string)[] = [];
    const minPage = Math.max(2, currentPage - 2);
    const maxPage = Math.min(totalPages - 1, currentPage + 2);

    if (minPage > 2) range.push("...");
    for (let i = minPage; i <= maxPage; i++) range.push(i);
    if (maxPage < totalPages - 1) range.push("...");

    return [1, ...range, totalPages];
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPages = (page: number | string, index: number) => {
    return (
      <li key={ncNanoId()}>
        {page === currentPage ? (
          <span className="inline-flex w-11 h-11 items-center justify-center rounded-full bg-custom-Blue text-white">
            {page}
          </span>
        ) : typeof page === "number" ? (
          <Link
            to=""
            className="inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Link>
        ) : (
          <span className="inline-flex w-11 h-11 items-center justify-center">
            {page}
          </span>
        )}
      </li>
    );
  };

  const renderPaginationItems = () => {
    return (
      <>
        <li key="prev">
          <Link
            to=""
            className="inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <i className="fi fi-rr-angle-left"></i>
          </Link>
        </li>
        {getPaginationRange().map((page, index) => renderPages(page, index))}
        <li key="next">
          <Link
            to=""
            className="inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <i className="fi fi-rr-angle-right"></i>
          </Link>
        </li>
      </>
    );
  };

  return (
    <div className="flex flex-col mt-5 lg:mt-5 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
      <nav
        className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
      >
        <ul className="flex space-x-1">{renderPaginationItems()}</ul>
      </nav>
    </div>
  );
};

export default ServerPagination;
