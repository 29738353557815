import { XCircleIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";

interface ErrorMessageProps {
  className?: string;
  errorTitle: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ errorTitle }) => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
          {errorTitle}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
