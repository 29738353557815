import { configureStore } from '@reduxjs/toolkit'
import careerReducer from "./slices/careerSlice"
import homePageSlice from './slices/homePageSlice'
import contactUsSlice from './slices/contactUsSlice'
import productTypeSlice from './slices/productTypeSlice'
import cartReducer from './slices/cartSlice'
import authReducer from './slices/authSlice';
import cartListReducer from './slices/cartDetailListSlice';
import cartIndexListReducer from './slices/cartIndexListSlice';

export const store = configureStore({
  reducer: {
    career:careerReducer,
    homePage:homePageSlice,
    contactUs:contactUsSlice,
    productType:productTypeSlice,
    cart: cartReducer,
    auth: authReducer,
    cartList:cartListReducer,
    cartIndexList:cartIndexListReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch