import Heading from "components/Heading/Heading";
import NavItem2 from "components/NavItem2";
import FlyerPagination from "containers/SeriesFlyers/FlyerPagination";
import { BaseProp } from "entities/BaseProp";
import {
  ProductDownloadFileItems,
  ProductDownloadTypes,
} from "entities/ProductDetailsDTO";
import React, { FC, useEffect, useState } from "react";
import Nav from "shared/Nav/Nav";
import NcLink from "shared/NcLink/NcLink";
import Select from "shared/Select/Select";
import { config } from "utils/Constance";
import handleScrollToEl from "utils/HandleScrollToEl";

interface Props extends BaseProp {
  data: ProductDownloadTypes;
}

const ProductDownload: FC<Props> = ({ className = "", data }) => {
  const { fileTypeList, productDownloadFileItems } = data;
  const [tabActive, setTabActive] = useState(0);
  const [dataResult, setDataResult] = useState<ProductDownloadFileItems[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (tabActive) {
      var activeOpenings = productDownloadFileItems.filter(
        (a) => a.fileTypeId === tabActive
      );
      setDataResult(activeOpenings);
    }
  }, [tabActive]);

  useEffect(() => {
    {
      fileTypeList.length && setTabActive(fileTypeList[0].filterId);
    }
    {
      productDownloadFileItems && setDataResult(productDownloadFileItems);
    }
  }, [data]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    handleScrollToEl("ProductDownload");
  };

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataResult?.slice(indexOfFirstItem, indexOfLastItem);

  const assignAWSPath = (rawPath?: string) => {    
    if (!rawPath) return null; // or return an empty string, or a default value

    //let buttonName = rawPath?.startsWith("inventory") ? "Open" : "View";
    let buttonName = rawPath?.endsWith(".zip")
    ? "Download" 
    : rawPath?.startsWith("inventory") 
    ? "Open" 
    : "View";
    
    let buttonLink = rawPath?.startsWith("inventory")
      ? `${config.url.AWS_S3_URL}${rawPath}`
      : `${rawPath}`;

    return (
      <NcLink
        to={`${buttonLink}`}
        target="_blank"
        className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
      >
        {buttonName}
      </NcLink>
    );
  };

  const renderTabs = () => {
    return (
      <Nav
        className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-x-auto hiddenScrollbar"
        containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
      >
        {fileTypeList.map((item, index) => (
          <NavItem2
            key={index}
            isActive={tabActive === item.filterId}
            onClick={() => {
              setTabActive(item.filterId);
              setCurrentPage(1);
            }}
          >
            <div
              key={index}
              className="flex items-center justify-center space-x-1.5 sm:space-x-2.5 text-xs sm:text-sm "
            >
              <span>{item.filterName}</span>
            </div>
          </NavItem2>
        ))}
      </Nav>
    );
  };

  const renderMobileTab = () => {
    return (
      <Select
        className="mb-12 lg:mb-14"
        onChange={(e) => setTabActive(+e.target.value)}
      >
        {fileTypeList.map((item, index) => (
          <option key={item.filterId} value={item.filterId}>
            {item.filterName}
          </option>
        ))}
      </Select>
    );
  };

  const renderTable = () => {
    return (
      <div
        className="px-4 sm:px-6 lg:px-8 bg-white rounded-lg border border-slate-100 scroll-mt-32"
        id="ProductDownload"
      >
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-5 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentItems.map((person, index) => (
                    <tr key={index}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-1/2 sm:pl-0">
                        {person.fileName}
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only">Description</dt>
                          <dd className="mt-1 text-gray-700">
                            {person.description}
                          </dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-black-700 sm:table-cell">
                        {person.description}
                      </td>
                      <td className="py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        {assignAWSPath(person.downloadUrl)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* PAGINATION */}
              {dataResult.length > itemsPerPage && (
                <FlyerPagination
                  className="py-3 pr-3 sm:pr-0"
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={dataResult?.length as number}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {data.fileTypeList && data.fileTypeList.length > 0 && (
        <div className={`nc-ProductDownload container bg-neutral-100 py-16 scroll-mt-32 rounded-3xl`} id="divProductDownload">
          <Heading
            className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
            fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold font-poppins"
            isCenter
            desc=""
          >
            Product Resources
          </Heading>
          {fileTypeList && (
            <>
              <div className="hidden sm:block">{renderTabs()}</div>
              <div className="sm:hidden">{renderMobileTab()}</div>
            </>
          )}
          {dataResult && renderTable()}
        </div>
      )}
    </>
  );
};

export default ProductDownload;
