import React, { FC,useState ,useEffect,Fragment,useRef} from "react";
import { Helmet } from "react-helmet-async";
import Rewards_Banner from "images/rewards/Rewards-Banner.png";
import axiosInstance from "api/AxiosInstance";
import { RewardsDataDTO ,RewardsInventoryItem,UserAddress,Country,Province,SelectedProductsState,ProductSelection } from "entities/RewardsDataDTO"
import FlyerPagination from "containers/SeriesFlyers/FlyerPagination";
import handleScrollToEl from "utils/HandleScrollToEl";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import toast from "react-hot-toast";
import BagIcon from "components/BagIcon";
import { config } from "utils/Constance";
import { Dialog, Transition } from '@headlessui/react'
import Input from "shared/Input/Input";
import RewardsShopModalQuickView from "./RewardsShopModalQuickView";
import Select from "shared/Select/Select";
import Label from "components/Label/Label";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import Received_Icon from "images/rewards/Received_Icon.png";
import AlertMessage from "./AlertMessage";
import Divider from "components/Divider";
import Text from './Text';
// import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import "./Rewards.css"
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import Heading from "components/Heading/Heading";

export interface RewardsShopProps {
  className?: string;
}

interface Product {
  id: number;
  qty:number;
  pointCost: number;
  totalPointCost: number;
}

export interface RewardInventoryDTO {
  rewardItems: Product[],
  chkSaveShippingAdd: boolean,
  shippingFrom: number,
  companyName: string,
  contactFirstName:string,
  contactLastName: string,
  street1: string,
  street2: string,
  city:string,
  province: number,
  postalCode: string,
  country: number,
  contactPhoneNumber: string,
  contactEmail:string,
  isWebOrder: boolean
}

const RewardsShop: FC<RewardsShopProps> = ({ className = "" }) => {

  const user = useAppSelector((state: RootState) => state.auth);  
  const [RewardConfirmOrderId,setRewardConfirmOrderId] = useState<number>(0);
  const [rewardsShopData, setRewardsShopData] = useState<RewardsDataDTO | null>(null);
  const [rewardsInventoryList, setRewardsInventoryList] = useState<RewardsInventoryItem[]>([]);
  const [showModalQuickView, setShowModalQuickView] = useState<{ show: boolean; selectedImage: string }>({
    show: false,
    selectedImage: ''
  });
  const [isOpen, setIsOpen] = useState(false); 
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState<number | null>();
  const [totalPointCost, setTotalPointCost] = useState<string | null>();
  const [tempPrice, setTempPrice] = useState<string | null>();
  const [point,setPoint] = useState<number>(0);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProductsState>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rewardId,setRewardId] = useState<number>(0);
  const [data,setData] = useState<any>();
  const [userAddress, setUserAddress] = useState({
    id: 0,
    shipmentNumber: null,
    status: null,
    shippingFrom: 0,
    customerId: null,
    companyName: '',
    contactFirstName: '',
    contactLastName: '',
    street1: '',
    street2: '',
    city: '',
    province: 0,
    postalCode: '',
    country: 0,
    contactPhoneNumber: '',
    contactEmail: '',
    notes: null,
    inProgressDate: null,
    shippedDate: null,
    createdOn: null,
    createdBy: null,
    isArchived: null,
    isDeleted: null,
    updateOn: null,
    updateBy: null,
    websiteAccountId: 0,
    isWebOrder: false,
    uploadShippingLabelPath: null,
    uploadShippingLabel: null,
    isAddressSave: null,
    customer: {},
    rewardShipmentItems: [],
    rewardShipmentsAuditTrails: []
  });
  const [errors, setErrors] = useState({
    id: 0,
    shipmentNumber: null,
    status: null,
    shippingFrom: 0,
    customerId: null,
    companyName: '',
    contactFirstName: '',
    contactLastName: '',
    street1: '',
    street2: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    contactPhoneNumber: '',
    contactEmail: '',
    notes: null,
    inProgressDate: null,
    shippedDate: null,
    createdOn: null,
    createdBy: null,
    isArchived: null,
    isDeleted: null,
    updateOn: null,
    updateBy: null,
    websiteAccountId: 0,
    isWebOrder: false,
    uploadShippingLabelPath: null,
    uploadShippingLabel: null,
    isAddressSave: null,
    customer: {},
    rewardShipmentItems: [],
    rewardShipmentsAuditTrails: [],
    termsCondition:''
  });
  const [countries, setCountries] = useState<Country[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [address, setAddress] = useState<any>("");
  const [address2, setAddress2] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [postcode, setPostcode] = useState<any>("");
  const [contactPhoneNumber, setcontactPhoneNumber] = useState<any>("");
  const [selectedCountryOption, setSelectedCountryOption] = useState<any | null>();
  const [selectedProvinceOption, setselectedProvinceOption] = useState<any | null>();
  const [termsCondition, setTermsCondition] = useState(false);
  const [saveShippingAddress,setSaveShippingAddress] = useState(false);
  const [productArr, setProductArr] = useState<Product[]>([]);
  const [isPointsOpen, setIsPointsOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('');
  const [showPointsModal, setShowPointsModal] = useState(false);
  const [IsRewardsShopSubmitted,setRewardsShopSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRewardConfirmLoading, setIsRewardConfirmLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const itemsPerPage = 10;
  const rewardMessageRef = useRef<HTMLDivElement>(null);
  const [IsRewardsOrderClicked, setIsRewardsOrderClicked] = useState(false);

  // useEffect(() => {
  //   if (window.google && window.google.translate) {
  //     window.googleTranslateElementInit();
  //   }
  // }, []);
  
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    handleScrollToEl("ScrollRewardsShop");
  };

  // Get data for the current page
  useEffect(() => {
    setIsRewardsOrderClicked(false);
    fetchRewardsShopData();
  }, [RewardConfirmOrderId]);

  const fetchRewardsShopData = async () => {
    try {
      const response = await axiosInstance
        .get(
          `/Rewards/rewards-shop?RewardConfirmOrderId=${RewardConfirmOrderId}`
        )
        .then((response) => {
          setRewardsShopData(response.data ?? {});
          setRewardsInventoryList(response.data?.rewardsInventoryList ?? []);
          setUserAddress(response.data?.userAddress ?? {});
          setCountries(response.data?.countryList ?? []);
          setProvinces(response.data?.provinceList ?? []);
          setAddress(response.data?.userAddress?.street1 ?? "");
          setAddress2(response.data?.userAddress?.street2 ?? "")
          setSelectedCountryOption(response.data?.userAddress?.country ?? "");
          setCity(response.data?.userAddress?.city ?? "");
          setPostcode(response.data?.userAddress?.postalCode ?? "");
          setselectedProvinceOption(response.data?.userAddress?.province ?? "");
          setcontactPhoneNumber(response.data?.userAddress?.contactPhoneNumber ?? "");
          setTimeout(() => {
            setIsLoading(false);
          }, 3000); 
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, rewardsInventoryList?.length);
  const currentData = rewardsInventoryList?.slice(startIndex, endIndex);

  const handleOpen = () => {
    setIsOpen(true);
    setShowModal(false);
    setQuantity(1);
  };

  const handleClose = () => {
    setIsOpen(false);
    setQuantity(null);
  };  

  const handleQtyChange  = (event : any) => {
    const value = parseInt(event.target.value);
    if(!isNaN(value)){
      setQuantity(value);
      calculateTotalPoints(value);
    }
    else
      setQuantity(null);
  };
  
  const handleKeyUp = (event : any) => {
    const { id, value } = event.target;
    if (!isNaN(value)) {
      calculateTotalPoints(value);
    }
  };

  const calculateTotalPoints = (qty : any) => {
    if (point) {
      const total = point * qty;
      setTotalPointCost(formatNumber(total.toFixed(0)));
    }
  };

  const formatNumber = (num: string) => {
    return new Intl.NumberFormat().format(parseFloat(num));
  };

  const updateSelectedProduct = (productId: string, productSelection: ProductSelection) => {
    setSelectedProducts((prevState: SelectedProductsState) => {
      const newState = { ...prevState };
      newState[productId] = productSelection;
      return newState;
    });
  };

  const ClaimThisEvent = (item: any) => {
    setPoint(item.pointCost);
    setTempPrice(item.pointCosts); 
    setTotalPointCost(item.pointCosts);
    setRewardId(item.id);
    handleOpen();
    setData(item);
  };

  const addProduct = (Id: number, totalcostsum: number, qty: number) => {
    // Create a new product object
    const newProduct: Product = { id: Id, qty: qty, pointCost: totalcostsum, totalPointCost:0};

    // Update the state with the new product array
    setProductArr((prevProductArr) => [...prevProductArr, newProduct]);
  };

  const removeProduct = (rewardId: number) => {
    // Update the state by filtering out the product with the given id
    setProductArr((prevProductArr) => prevProductArr.filter(product => product.id !== rewardId));
  };

  const checkTile = (item:any,qty : number) => {
    // const totalcostsum = (qty * parseFloat(item.pointCost));
    const totalcostsum = qty * parseFloat(item.pointCost);
    const formattedTotalCost = formatNumber(totalcostsum.toFixed(0));
    const rewardId = item.id; 
    updateSelectedProduct(String(rewardId), { selected: true, qty, totalCost: formattedTotalCost });
    //updateSelectedProduct(rewardId, { selected : true, qty : qty, totalCost:formatNumber(totalcostsum.toFixed(0))}); 
    handleClose();
    notifyRewardsShop(item,qty);
    addProduct(rewardId, totalcostsum, qty);
  };

 const handleClick = (rewardId:any) =>{
  updateSelectedProduct(rewardId, {selected:false,qty:null,totalCost:null});
  removeProduct(parseInt(rewardId));
 };

 const notifyRewardsShop = (item : any,qty:any) => {
    toast.custom(
      (t : any) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
             <Text>{`Reward Claimed!`}</Text>
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderRewardsShopCartOnNotify(item,qty)}
        </Transition>
      ),
      { position: "top-right", id: "nc-RewardsShop-notify", duration: 3000 }
    );
 };

  const renderRewardsShopCartOnNotify = (item : any,qty: any) => {
    const totalcostsum = (qty * parseFloat(item.pointCost));
    const totalCost = formatNumber(totalcostsum.toFixed(0));
    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
             src={`${config.url.AWS_S3_URL}${item.itemPhotoPath}`}
             alt={item.itemPhotoPath}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{item.itemName}</h3>
                <p className="mt-1 text-sm text-black dark:text-slate-400">
                  <span>
                   {item.webGridDescription}
                  </span>
                  {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-1 justify-between text-sm">
              <span className="text-black dark:text-slate-400">Quantity selected: {qty}</span>
            </div>
            <div className="flex flex-1 justify-between text-sm">
             <span className="text-black dark:text-slate-400"><Text>{`Total point cost: ${totalCost}`}</Text></span>
            </div>
          </div>
        
          {/* <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">Quantity selected: {qty}</p>
            <p className="text-gray-500 dark:text-slate-400">Total point cost: {totalCost}</p>
          </div> */}
        </div>
      </div>
    );
  };

  const renderGroupButtons = (item: any) => {
    return (
      <div className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
        <ButtonPrimary
          className="shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick= {() => ClaimThisEvent(item)}
        >
          <BagIcon className="w-3.5 h-3.5 mb-0.5" />
          <span className="ml-1">Claim this!</span>
        </ButtonPrimary>
        <ButtonSecondary
          className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => setShowModalQuickView({ show: true, selectedImage: item.itemPhotoPath })}
        >
          <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
          <span className="ml-1">Quick view</span>
        </ButtonSecondary>
      </div>
    );
  };

  const handleCountrySelectChange = (e : any) => {
    if(e.target.value == ''){
      setSelectedCountryOption(0);
      setErrors({
        ...errors,
        country: 'Please select a country.'
      });
    }
    else{
      setSelectedCountryOption(e.target.value);
      GetAllProvinceByCountryId(parseInt(e.target.value),"");
      setselectedProvinceOption(0);
      setErrors({
        ...errors,
        country: ''
      });
    }
  };

  const GetAllProvinceByCountryId = async (countryId : number,provName : string) =>{
    try {
      const response = await axiosInstance.post(
          `/Rewards/GetAllProvinceByCountryId?CountryId=${countryId}`
        )
        .then((response) => {
          setProvinces(response.data);
          const provinceName = response.data.find((item: { name: string }) => item.name === provName);
          if(provinceName)
            {
              setselectedProvinceOption(provinceName.id);
            }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleProvinceSelectChange = (e : any) => {
    if(e.target.value == ''){
      setselectedProvinceOption(0);
      setErrors({
        ...errors,
        province: 'Please select a province.'
      });
    }
    else{
      setselectedProvinceOption(e.target.value);
      setErrors({
        ...errors,
        province: ''
      });
    }
  };

  const handleCheckboxChange = (e : any)=>{
    if(e.target.checked){
      setSaveShippingAddress(e.target.checked);
    }
    else{
      setSaveShippingAddress(e.target.checked);
    }
  };

  const handleTermsCondition = (e: any) =>{
    if(e.target.checked){
      setTermsCondition(e.target.checked);
      setErrors({
        ...errors,
        termsCondition: ''
      });
    }
    else{
      setTermsCondition(e.target.checked);
      setErrors({
        ...errors,
        termsCondition: 'You must confirm the rewards shipping address information by clicking on the red checkbox before you can place your rewards order.'
      });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let errorMessage = "";

    switch (name) {
      // case "companyName":
      //   errorMessage = value.trim() === "" ? "Please enter your company name." : "";
      //   break;
      case "contactFirstName":
        errorMessage = value.trim() === "" ? "Please enter your first name." : "";
        break;
      case "contactLastName":
      errorMessage = value.trim() === "" ? "Please enter your last name." : "";
      break;
      case "street1":
        errorMessage = value.trim() === "" ? "Please enter a street address." : "";
        break;
      case "street2":
        errorMessage = value.trim() === "" ? "Please enter a street 2 address." : "";
        setAddress2(value);
        break;
      case "contactPhoneNumber":
        errorMessage = value.trim() === "" ? "Please enter your phone number." : "";
        setcontactPhoneNumber(value);
        break;
      case "contactEmail":
        errorMessage =
          value.trim() === ""
            ? "Please enter an email."
            : !/^\S+@\S+\.\S+$/.test(value)
            ? "Please enter a valid email address."
            : "";
        break;
      case "city":
        errorMessage = value.trim() === "" ? "Please enter a city." : "";
        setCity(value);
        break;
      case "postalCode":
        errorMessage = value.trim() === "" ? "Please enter a postal code." : "";
        setPostcode(value);
        break;
      case "province":
        errorMessage = value.trim() === "" ? "Please select a province." : "";
        selectedProvinceOption(value);
        break;
      case "country":
        errorMessage = value.trim() === "" ? "Please select a country." : "";
        setSelectedCountryOption(value);
        break;
      default:
        break;
    }

    setUserAddress({ ...userAddress, [name]: value });
    setErrors({ ...errors, [name]: errorMessage });
  };

  const validateForm = () => {
    const newErrors: any = {};
    // if (!userAddress?.companyName.trim()) {
    //   newErrors.companyName = "Please enter your company name.";
    // }
    if (!contactPhoneNumber) {
      newErrors.contactPhoneNumber = "Please enter your phone number.";
    }
    if (!userAddress?.contactFirstName.trim()) {
      newErrors.contactFirstName = "Please enter your first name.";
    }
    if (!userAddress?.contactLastName.trim()) {
      newErrors.contactLastName = "Please enter your last name.";
    }
    if (!userAddress?.contactEmail.trim()) {
      newErrors.contactEmail = "Please enter an email.";
    }
    if (!userAddress?.city.trim()) {
      newErrors.city = "Please enter a city.";
    }
    if (userAddress?.postalCode != null && !userAddress.postalCode.trim()) {
      newErrors.postalCode = "Please enter a postal code.";
    }
    if (!selectedCountryOption) {
      newErrors.country = "Please select a country.";
    }
    if (!address) {
      newErrors.street1 = "Please enter a street address.";
    }
    if(!termsCondition){
      newErrors.termsCondition = "You must confirm the rewards shipping address information by clicking on the red checkbox before you can place your rewards order.";
    }
    if(!selectedProvinceOption){
      newErrors.province = "Please select a province.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length !== 0 ? true : false;
  };

  const handleSelect = async (selectedAddress: any) => {
    try {      
      const results = await geocodeByAddress(selectedAddress);
      let addressComponents: google.maps.GeocoderAddressComponent[];
      if (results.length === 1) {
          addressComponents = results[0].address_components;
      } else if (results.length >= 2) {
          addressComponents = results[1].address_components;
      } else {
          addressComponents = [];
      }

      const componentMap: { [key: string]: string } = {
        locality: '',
        administrative_area_level_1: '',
        postal_code: '',
        street_number: '',
        route: '',
        country: '',
        neighborhood: '',
        sublocality_level_1: '',
        sublocality_level_2: '',
        sublocality_level_3: ''
      };

      for (const component of addressComponents) {
        for (const type of component.types) {
          if (componentMap.hasOwnProperty(type)) {
            if (type === 'route' || type === 'street_number') {
              componentMap[type] = componentMap[type]
                ? `${componentMap[type]} ${component.long_name}`
                : component.long_name;
            } else if (
              type === 'sublocality_level_1' ||
              type === 'sublocality_level_2' ||
              type === 'sublocality_level_3' ||
              type === 'neighborhood'
            ) {
              componentMap[type] = componentMap[type]
                ? `${componentMap[type]}, ${component.long_name}`
                : component.long_name;
            } else {
              componentMap[type] = component.long_name;
            }
          }
        }
      }

      const foundCountry = countries.find(c => c.name === componentMap.country)?.id || 0;
      setSelectedCountryOption(foundCountry);
      setCity(componentMap.locality);
      setPostcode(componentMap.postal_code);
      await GetAllProvinceByCountryId(foundCountry,componentMap.administrative_area_level_1);
      setAddress(`${componentMap.street_number} ${componentMap.route}`.trim());
      setAddress2(
        `${componentMap.neighborhood} ${componentMap.sublocality_level_1} ${componentMap.sublocality_level_2} ${componentMap.sublocality_level_3}`.trim()
      );   
    } catch (error) {
      console.error("Error fetching address data", error);
    }
  };

  const handleAddress = (selectedAddress : any) =>{
    if (selectedAddress.trim() === '') {
      setAddress("");
      setErrors({
        ...errors,
        street1: 'Please enter a street address.'
      });
    } else {
      setAddress(selectedAddress);
      setErrors({
        ...errors,
        street1: ''
      });
    }
  };

  const handlePointsOpen = () => {
    setIsPointsOpen(true);
    setShowPointsModal(false);
  };

  const handlePointsClose = () => {
    setIsPointsOpen(false);
    handleScrollToEl("ScrollRewardsShop");
  };

  const lackOfPointsPopup = () => { 
    let errorMsg = '';
    let errorTitle = '';
    let submitButtonText = 'I understand computer';
    let userPoints = rewardsShopData && rewardsShopData.rewardsPoints ? rewardsShopData.rewardsPoints :0 ;

    if (productArr.length <= 0 || userPoints <= 0) {
      errorMsg = 'You have not selected any rewards. Scroll back up and click on a reward you\'d like to cash in your points for.';
      errorTitle = 'Something is not quite right';
      submitButtonText = 'Will do!';
    }

    const pointsSum = productArr.reduce((a, b) => (a + b.pointCost), 0);
    const points = formatNumber(pointsSum.toFixed(0));
    let formattedUserPoints = formatNumber(userPoints.toFixed(0));
    if (userPoints.toString().indexOf(',') !== -1) {
        formattedUserPoints = formattedUserPoints.replace(/,/g, '');
    }

    if (userPoints < pointsSum) {
        errorTitle = 'Oh no, you need more reward points!';
        errorMsg = `<p class="mt-0 mb-4">The total point cost of your selected inventory is <b>${points}</b>, and your reward point balance is only <b>${formattedUserPoints}</b>. You will need to collect more points.</p>`;
        errorMsg += `<div>It can take up to 48 hours for newly submitted proof of purchases to be converted into points, but if you feel there's been a mistake, please email ${config.emails.EmailMarketing} and we'll get this sorted!</div>`;
    }

    if (errorMsg !== ``) {
      setErrorTitle(errorTitle);
      setErrorMsg(errorMsg);
      setSubmitButtonText(submitButtonText);
      handlePointsOpen();
      return false;
    } 
    return true;  
  };

  const lackOfQtyCheck = async () => {
    let errorMsg = '';
    let errorTitle = '';
    const submitButtonText = `I can't believe this happened`;
  
    if (productArr.length > 0) {
      try {
        const response = await axiosInstance.post('/Rewards/checkRewardInventoryTotalQty', productArr, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        });
  
        const data = await response.data;
  
        if (data.listOfInSufficientQty.length > 0 && data.listOfInSufficientQty.includes(false)) {
          errorTitle = `Human, I have some terrible news`;
          errorMsg = `Somewhere between the time the rewards shop was loaded and you submitted your rewards order, some of your selected rewards ran out of stock. We sincerely apologize for the inconvenience and horrible luck. Please close this pop-up and refresh your browser to select some new rewards.`;
  
          if (errorMsg !== '') {
            setErrorTitle(errorTitle);
            setErrorMsg(errorMsg);
            setSubmitButtonText(submitButtonText);
            handlePointsOpen();
            return false;
          }
          
        } 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isPointsPopupValid = lackOfPointsPopup();
  
    if (!isPointsPopupValid) return; // Stop if there's an error in lackOfPointsPopup

    const isQtyCheckValid = await lackOfQtyCheck();
    
    if (!isQtyCheckValid) return; // Stop if there's an error in lackOfQtyCheck
    // Call the onSubmit function from props with the form data
    await handleFormSubmit();      
  };

  useEffect(() => {
    if (!isRewardConfirmLoading && IsRewardsShopSubmitted && RewardConfirmOrderId != 0) {
      scrollToRewardsMessage();
    }
  }, [isRewardConfirmLoading, IsRewardsShopSubmitted, RewardConfirmOrderId]);


  const scrollToRewardsMessage = () => {
    if (window.innerWidth <= 768 && rewardMessageRef.current) {
      rewardMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleFormSubmit = async () => {
    //If there are validation errors, return
    var isValid = validateForm();
    if (isValid) {
      return;
    } else {
      if(!IsRewardsOrderClicked){ 
        setIsRewardsOrderClicked(true);
      try {
          const rewardDTO: RewardInventoryDTO = {
            rewardItems:productArr,
            chkSaveShippingAdd : saveShippingAddress,
            shippingFrom: 0,
            companyName: userAddress.companyName,
            contactFirstName: userAddress.contactFirstName,
            contactLastName:userAddress.contactLastName,
            street1: address,
            street2:address2,
            city: userAddress.city,
            province: selectedProvinceOption,
            postalCode: userAddress.postalCode,
            country: selectedCountryOption,
            contactPhoneNumber: contactPhoneNumber,
            contactEmail: userAddress.contactEmail,
            isWebOrder: false
          }
          setIsButtonDisabled(true);  
          const response = await axiosInstance.post('/Rewards/rewards-shop', rewardDTO, {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          }).then(response => {
            if(response.data != 0){
              setRewardConfirmOrderId(response.data.rewardConfirmOrderId);
              setRewardsShopSubmitted(true);           
              setTimeout(() => {
                setIsRewardConfirmLoading(false);
              }, 3000); 
            }
          });
      } catch (error) {
        console.error("API Error:", error);
      }
      }
    }
  };

  const renderCheckoutForm = () =>{
    return(
        <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28 pl-0 ml-0 mb-[-130px]">
          <div className="space-y-10 sm:space-y-14">
            <div className="w-full">
            <Heading
              className="text-neutral-900 dark:text-neutral-50 font-poppins"
              isCenter={false}
            >
             Check Out
            </Heading>
              {/* <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
                Check Out
              </h2> */}
              <span className="block mt-4 text-black-500 dark:text-black-400 halloween:text-black text-sm sm:text-base">
              After you have made your selection above, we just need to know where to send your goods!
              </span>
            </div>
            <Divider className="mt-4"/>
            <h4 className="block font-medium capitalize text-2xl sm:text-3xl lg:text-4xl !mb-[-2.5rem]">
              shipping address
            </h4>
             <form onSubmit={handleSubmit}>
                <div className="flex-grow sm:w-3/5 space-y-6">
                  <div>
                    <Label>Company Name (if applicable)</Label>
                    <Input
                      className="mt-1.5"
                      type="text"
                      name="companyName"
                      maxLength={50}
                      tabIndex={1}
                      autoComplete="off"
                      value={userAddress?.companyName}
                      onChange={handleInputChange}
                      // style={{
                      //   border:
                      //     errors.companyName && errors.companyName !== ""
                      //       ? "1px solid #fd397a"
                      //       : "",
                      // }}
                    />
                    {/* {errors.companyName && (
                        <span style={{ color: "#fd397a" }}>{errors.companyName}</span>
                      )} */}
                  </div>
                  <div>
                    <Label>First Name</Label>
                    <Input
                      className="mt-1.5"
                      type="text"
                      name="contactFirstName"
                      maxLength={50}
                      tabIndex={2}
                      autoComplete="off"
                      value={userAddress?.contactFirstName}
                      onChange={handleInputChange}
                      style={{
                        border:
                          errors.contactFirstName && errors.contactFirstName !== ""
                            ? "1px solid #fd397a"
                            : "",
                      }}
                    />
                      {errors.contactFirstName && (
                        <span style={{ color: "#fd397a" }}>{errors.contactFirstName}</span>
                      )}
                  </div>
                  <div>
                    <Label>Last Name</Label>
                    <Input
                      className="mt-1.5"
                      type="text"
                      name="contactLastName"
                      maxLength={50}
                      tabIndex={3}
                      autoComplete="off"
                      value={userAddress?.contactLastName}
                      onChange={handleInputChange}
                      style={{
                        border:
                          errors.contactLastName && errors.contactLastName !== ""
                            ? "1px solid #fd397a"
                            : "",
                      }}
                    />
                    {errors.contactLastName && (
                        <span style={{ color: "#fd397a" }}>{errors.contactLastName}</span>
                      )}
                  </div>
                  <PlacesAutocomplete
                      value={address}
                      onChange={handleAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Label>Address Line 1</Label>
                          <Input
                            name="street1"
                            maxLength={100}
                            tabIndex={4}
                            style={{
                              border:
                                errors.street1 && errors.street1 !== ""
                                  ? "1px solid #fd397a"
                                  : "",
                            }}
                            {...getInputProps({
                              placeholder: "Enter your address",
                            })}
                          />
                          {errors.street1 && (
                            <span style={{ color: "#fd397a" }}>
                              {errors.street1}
                            </span>
                          )}
                          <div>
                            {loading ? <div>Loading...</div> : null}
                            <div className="">
                              {suggestions.map((suggestion: any) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#e9f1f9"
                                    : "#f8fafc",
                                };
                              
                                return (
                                  <div
                                    className="p-2 px-4 first:rounded-t-sm last:rounded-b-sm overflow-hidden text-gray-700 text-base  transition-all cursor-pointer"
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                    key={suggestion.placeId}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                  </PlacesAutocomplete>

                  <div>
                      <Label>Address Line 2</Label>
                      <Input
                        className="mt-1.5"
                        type="text"
                        name="street2"
                        maxLength={80}
                        tabIndex={5}
                        autoComplete="off"
                        value={address2 || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <Label>City</Label>
                      <Input
                        className="mt-1.5"
                        type="text"
                        name="city"
                        maxLength={30}
                        tabIndex={6}
                        autoComplete="off"
                        value={city || ""}
                        onChange={handleInputChange}
                        style={{
                          border:
                            errors.city && errors.city !== ""
                              ? "1px solid #fd397a"
                              : "",
                        }}
                      />
                        {errors.city && (
                        <span style={{ color: "#fd397a" }}>{errors.city}</span>
                      )}
                    </div>

                    <div>
                      <Label>Province</Label>
                      <Select
                        className="mt-1.5"
                        name="province"
                        tabIndex={7}
                        value={selectedProvinceOption} 
                        onChange={handleProvinceSelectChange}
                        style={{
                          border:
                            errors.province
                              ? "1px solid #fd397a"
                              : "",
                        }}
                      >
                        <option value={""}>--Select Province--</option>
                        {provinces && provinces.map((option, index) => (
                          <option
                            key={index}
                            value={option.id}
                            data-abb={option.abbreviation}
                          >
                            {option.name}
                          </option>
                        ))}
                      </Select>
                      {errors.province && (
                        <span style={{ color: "#fd397a" }}>{errors.province}</span>
                      )}
                    </div>

                    <div>
                      <Label>Country</Label>
                      <Select
                        className="mt-1.5" 
                        name="country"
                        tabIndex={8}
                        value={selectedCountryOption || undefined}
                        onChange={handleCountrySelectChange}
                        style={{
                          border:
                            errors.country 
                              ? "1px solid #fd397a"
                              : "",
                        }}
                      >
                        <option value={""}>--Select Country--</option>
                        {countries && countries.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                      {errors.country  && (
                        <span style={{ color: "#fd397a" }}>{errors.country}</span>
                      )}
                    </div>
                    <div>
                      <Label>Postal Code</Label>
                      <Input
                        className="mt-1.5"
                        type="text"
                        name="postalCode"
                        maxLength={10}
                        tabIndex={9}
                        autoComplete="off"
                        value={postcode || ""}
                        onChange={handleInputChange}
                        style={{
                          border:
                            errors.postalCode && errors.postalCode !== ""
                              ? "1px solid #fd397a"
                              : "",
                        }}
                      />
                      {errors.postalCode && (
                        <span style={{ color: "#fd397a" }}>{errors.postalCode}</span>
                      )}
                    </div>

                    {/* ---- */}
                    <div>
                      <Label>Phone number</Label>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <i className="text-2xl las la-phone-volume"></i>
                        </span>
                        <Input
                          className="!rounded-l-none"
                          type="text"
                          name="contactPhoneNumber"
                          maxLength={15}
                          tabIndex={10}
                          autoComplete="off"
                          value={contactPhoneNumber || ""}
                          onChange={handleInputChange}
                          style={{
                            border:
                              errors.contactPhoneNumber && errors.contactPhoneNumber !== ""
                                ? "1px solid #fd397a"
                                : "",
                          }}
                        />
                      </div>
                      {errors.contactPhoneNumber && (
                        <span style={{ color: "#fd397a" }}>{errors.contactPhoneNumber}</span>
                      )}
                    </div>
                    <div>
                      <Label>Email</Label>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <i className="text-2xl las la-envelope"></i>
                        </span>
                        <Input
                          className="!rounded-l-none"
                          type="text"
                          name="contactEmail"
                          maxLength={50}
                          tabIndex={11}
                          autoComplete="off"
                          value={userAddress?.contactEmail}
                          onChange={handleInputChange}
                          style={{
                            border:
                              errors.contactEmail && errors.contactEmail !== ""
                                ? "1px solid #fd397a"
                                : "",
                          }}
                        />
                      </div>
                      {errors.contactEmail && (
                        <span style={{ color: "#fd397a" }}>{errors.contactEmail}</span>
                      )}
                    </div>
                  {/* <div className=""> */}
                  <label className="block flex pt-2 w-full items-center">
                    <input
                      id="saveShippingAddress"
                      name="saveShippingAddress"
                      className="cursor-pointer rounded text-indigo-600 focus:ring-indigo-600"
                      type="checkbox"
                      checked={saveShippingAddress || false}
                      onChange={(e) => handleCheckboxChange(e)}
                    />
                      {/* <Checkbox
                        name="saveShippingAddress"
                        className="cursor-pointer focus:outline-none  focus:ring-0 ring-offset-0"
                        onChange={(e) => handleCheckboxChange(e)}
                      /> */}
                      <span className="ml-3">
                        Please save my shipping address above for next time.
                      </span>
                      </label>
                    {/* </div> */}
                    {/* <div className="flex w-full items-center"> */}
                    <label className="block flex w-full items-center">
                      <input
                        id="termsCondition"
                        name="termsCondition"
                        className={errors.termsCondition ? "cursor-pointer h-5 w-5 rounded border-4 border-red-600 text-indigo-600 focus:ring-indigo-600" : "cursor-pointer rounded text-indigo-600 focus:ring-indigo-600"}
                        type="checkbox"
                        checked={termsCondition || false}
                        onChange={(e) => handleTermsCondition(e)}
                      />
                      <span className="ml-3">
                        I <strong>{userAddress?.contactFirstName}  {userAddress?.contactLastName}</strong> confirm that the rewards selection and shipping address information above is correct.
                      </span>
                      </label>
                    {/* </div> */}
                    {errors.termsCondition ? (
                        <div className="mt-6 w-full">
                          <AlertMessage message={errors.termsCondition}/>
                        </div>
                      ) : (
                        ""
                      )}
                    {/* {errors.termsCondition && (
                        <span style={{ color: "#fd397a" }}>{errors.termsCondition}</span>
                      )} */}
                    <div className="pt-4 pb-8">
                      <ButtonPrimary type="submit" className="w-full md:w-auto" disabled={isButtonDisabled}>Submit Rewards Order</ButtonPrimary>
                    </div>
                </div>
             </form>
          </div>
        </div>
    );
  };

  const renderCheckoutSkeleton = () =>{
    return(
        <div className="w-full" id="ScrollCheckout">
            <div className="space-y-10 sm:space-y-14">
                <div className="skeleton-loader h-8 w-2/3"></div>
                <hr className="border-slate-200 dark:border-slate-700"/>
                <div className="skeleton-loader h-4 w-full"></div>
                <div className="skeleton-loader h-8 w-1/2"></div>
                <div className="space-y-6">
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                  <div className="skeleton-loader h-4 w-full"></div>
                </div>
            </div>
        </div>
    )
  }
  return (
    <div className={`nc-RewardsShop ${className}`} data-nc-id="RewardsShop"  id="ScrollRewardsShop">
      <Helmet>
        <title>Rewards Shop | CSC LED</title>
      </Helmet>
      
      {!IsRewardsShopSubmitted && RewardConfirmOrderId == 0 && (
       <div className="w-full">
        <div className="sm:container p-0 sm:mt-10 mt-0">
          <div className=" bg-white flex w-full relative items-center justify-left Rewards_shop_banner Rewards_hero md:py-24 sm:py-6 py-2 px-6">
            <div className="flex left-0 absolute md:h-full h-auto w-full">
              <img
                className="h-auto min-h-full border hero__image rewards_shop_hero_img  border-gray-200 w-auto min-w-full object-fill object-left"
                src={Rewards_Banner}
                alt="Rewards Banner"
              />
            </div>

            <div className="relative z-1 items-start flex  flex-col">
              <h2 className="md:text-3xl sm:text-xl text-lg font-medium tracking-tight text-gray-900 lg:text-4xl">
                CSC LED Rewards
              </h2>
              <p className="lg:my-6 w-full sm:my-3 my-1 lg:text-2xl md:text-xl text-xs sm:text-base text-gray-700">
                Yet another reason to buy CSC LED.
              </p>
              {rewardsShopData && ( 
                <span className="inline-flex capitalize items-center rounded-md bg-yellow-500 px-4 sm:py-2.5 py-2 sm:text-base text-xs font-medium text-gray-900 ">
                  Your available reward points: {rewardsShopData.rewardPoint}
                </span>
             )}
            </div>
          </div>
        </div>

        <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
          <div className="space-y-10 lg:space-y-14">
            <div className="max-w-screen-sm" id="ScrollRewardsShop">
            <Heading
              className="text-neutral-900 dark:text-neutral-50 font-poppins"
              isCenter={false}
            >
             Available Rewards
            </Heading>
              {/* <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
                Available Rewards
              </h2> */}
              <span className="block mt-4 text-black-500 dark:text-black-400 halloween:text-black text-sm sm:text-base">
                Hover over the image of the reward you want, and select "Claim this!"
              </span>
            </div>
            <Divider/>
            <main>
            {isLoading ? (
                  <div className="grid grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                    {[...Array(8)].map((_, index) => (
                      <div
                        key={index}
                        className="nc-RewardsShopCard relative flex flex-col bg-transparent animate-pulse"
                      >
                        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
                          <div className="block aspect-w-4 aspect-h-3 w-full h-0 bg-gray-200" />
                        </div>
                        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
                          <div>
                              <div className="animate-pulse h-4 bg-slate-300 dark:bg-slate-600 rounded w-3/4"></div>
                              <div className="animate-pulse h-3 bg-slate-300 dark:bg-slate-600 rounded mt-2 w-1/2"></div>
                          </div>
                          <div className="flex justify-between mt-1">
                            <div className="animate-pulse bg-gray-200 h-4 rounded w-1/2"></div>
                          </div>
                          {/* <div className="h-3 bg-slate-300 dark:bg-slate-600 rounded mt-2 w-1/4"></div> */}
                       </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                    {currentData && currentData.map((item,index) => (
                      <div
                      className={`nc-RewardsShopCard relative flex flex-col bg-transparent ${className}`}
                      data-nc-id="RewardsShopCard"
                      key={index}
                      onClick={() => handleClick(item.id)}
                    >
                        <Link to={""} className="absolute inset-0"></Link>
                        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
                        <Link to={""} className="block">
                          <NcImage
                              containerClassName="flex aspect-w-4 aspect-h-3 w-full h-0"
                              src={`${config.url.AWS_S3_URL}${item.itemPhotoPath}` || undefined}
                              className="object-cover w-full h-full drop-shadow-xl object-center group-hover:scale-110 transform transition-transform duration-300"
                            />
                        </Link>
                    
                        {renderGroupButtons(item)}
                        </div>
                        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
                          <div>
                            <h2
                              className={`nc-RewardsShopCard__title text-base font-semibold transition-colors`}
                            >
                              {item.itemName}
                            </h2>
                            <p className={`text-sm text-black-500 dark:text-black-400 halloween:text-black mt-1 `}>
                              {item.webGridDescription}
                            </p>
                          </div>
                            {selectedProducts && selectedProducts[item.id] && selectedProducts[item.id].selected ? (
                                <div className="flex justify-between items-end">
                                  <div
                                      className={`flex items-center border-2 border-green-700 rounded-lg py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium`}
                                  >
                                      <span className="text-green-700 dark:text-green-700 !leading-none">
                                        <p className="font-bold mb-2"> Quantity selected: {selectedProducts[item.id].qty}
</p>
                                        <p className="font-bold" >Total point cost: {selectedProducts[item.id].totalCost}</p>
                                      </span>
                                  </div>
                                </div>
                              ) : (
                                  <div className="flex justify-between !mt-1">
                                    <div className={`flex py-1 md:py-1.5 text-sm font-medium`}>
                                      <span className="transition-colors !leading-none"  translate="no">
                                        {item.pointCosts} points
                                      </span>
                                    </div>
                                  </div>
                            )}   
                        </div>
                      </div>
                    ))}
                  </div> 
                )}
          
               {/* PAGINATION */}
               <div className="flex w-full justify-between pb-6 items-center px-6">
                  <div className="w-full">
                    
                    <FlyerPagination
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      totalItems={rewardsInventoryList.length as number}
                      onPageChange={handlePageChange}
                    />

                  </div>
               </div>

                <RewardsShopModalQuickView
                  show={showModalQuickView.show}
                  image_url={showModalQuickView.selectedImage}
                  onCloseModalQuickView={() => setShowModalQuickView({ show: false, selectedImage: '' })}
                />
                    
                    {isOpen && !showModal && (
                      <Transition appear show={isOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={handleClose}>
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="fixed inset-0 bg-black/25" />
                              </Transition.Child>
          
                              <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                  >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                      <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                      >
                                      </Dialog.Title>
                                      <div className="w-full text-center justify-center">
                                          <div>
                                            <div className="h-6 w-6 text-yellow-400 mb-3">
                                              <br/>
                                            </div>
                                            <div className="flex justify-center items-center">
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-24 h-24 mb-1" style={{stroke:'#034c9e'}}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                              </svg>
                                            </div>
                                            <p className="font-medium text-2xl text-slate-900 items-center justify-center">Nice pick!</p>
                                            <p className="my-3 text-black-900 items-center justify-center">How many of these did you want to get?<br/>Each one costs <b>{tempPrice}</b> points.</p>
                                            <div>
                                              <label className="d-flex">Quantity</label>
                                              <Input
                                                type="text"
                                                className="form-control mr-8 mt-4"
                                                id="txtRewardQty"
                                                min="1"
                                                max="50"
                                                pattern="^\d*\.?\d*$"
                                                value={quantity !== null && quantity !== undefined ? quantity : ''}
                                                placeholder="Ex. 1"
                                                onChange={handleQtyChange}
                                                onKeyUp={handleKeyUp}
                                              />
                                              <div className="flex mt-4">
                                                <span>Total point cost:</span>
                                                <span className="ml-1">{totalPointCost}</span>
                                              </div>
                                            </div>
                                            </div>
                                      </div>
                                      <div className="my-4 mt-8 flex-wrap flex justify-center">
                                        <ButtonCSCLed
                                          type="button"
                                          className="w-48 sm:w-52 mx-1" 
                                          onClick={ () => checkTile(data,quantity ?? 0)}
                                          disabled={!quantity || quantity === 0}
                                          >
                                          I'll take it!
                                        </ButtonCSCLed>
                                        <ButtonSecondary
                                          type="button"
                                          className="mx-1 sm:mt-0 mt-2 w-40 border border-slate-200 dark:border-slate-700"
                                          onClick={handleClose}>
                                          Cancel
                                        </ButtonSecondary>
                                      </div>
                                    </Dialog.Panel>
                                  </Transition.Child>
                                </div>
                              </div>
                        </Dialog>
                      </Transition>
                    )} 

                    {isPointsOpen && !showPointsModal && (
                      <Transition appear show={isPointsOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={handlePointsClose}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    > 
                                   
                                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                            <div className="flex justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                              id="Layer_1" 
                                              data-name="Layer 1" 
                                              viewBox="0 0 24 24" 
                                              className="w-24 h-24 mb-1"
                                              >
                                                <path d="m15.854,8.854l-3.146,3.146,3.146,3.146c.195.195.195.512,0,.707-.098.098-.226.146-.354.146s-.256-.049-.354-.146l-3.146-3.146-3.146,3.146c-.098.098-.226.146-.354.146s-.256-.049-.354-.146c-.195-.195-.195-.512,0-.707l3.146-3.146-3.146-3.146c-.195-.195-.195-.512,0-.707s.512-.195.707,0l3.146,3.146,3.146-3.146c.195-.195.512-.195.707,0s.195.512,0,.707Zm8.146,3.146c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-1,0c0-6.065-4.935-11-11-11S1,5.935,1,12s4.935,11,11,11,11-4.935,11-11Z"/>
                                            </svg>
                                            </div>
                                          </div>
                                            <Dialog.Title as="h3" className="flex justify-center text-lg font-medium leading-6 text-gray-900">
                                                {errorTitle}
                                            </Dialog.Title>
                                            <div className="block justify-center mt-2 mb-8 break-words text-wrap text-center" dangerouslySetInnerHTML={{ __html: errorMsg }}></div>
                                            <div className="mt-4 flex justify-center">
                                            <ButtonPrimary
                                              type="button"
                                              className="w-48 sm:w-auto mx-1" 
                                              onClick={handlePointsClose}
                                              >
                                              {submitButtonText}
                                            </ButtonPrimary>
                                                {/* <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={handlePointsClose}
                                                >
                                                    {submitButtonText}
                                                </button> */}
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                      </Transition>
                    )}
                    {isLoading ? renderCheckoutSkeleton() : renderCheckoutForm()}
            </main>
          </div>
        </div>
       </div>
      
      )}
   
      {isRewardConfirmLoading && IsRewardsShopSubmitted &&  RewardConfirmOrderId != 0 ? (
          <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
            <div className="space-y-10 lg:space-y-14">
              <main>
                <div className="w-full bg-white mb-0">
                      <div className="border-b">
                        <div className="px-4 py-5 sm:px-6">
                          <div className="skeleton-loader h-6 w-1/2"></div>
                        </div>
                      </div>
                      <div className="px-4 py-5 sm:px-6">
                        <div className="flex flex-wrap">
                          <div className="w-full lg:w-2/3 md:w-full">
                            <div className="skeleton-loader h-4 w-full"></div>
                            <div className="skeleton-loader h-4 w-3/4"></div>
                            <div className="skeleton-loader h-4 w-1/2"></div>
                            <div className="skeleton-loader h-4 w-full"></div>
                            <div className="skeleton-loader h-4 w-3/4"></div>
                            <div className="skeleton-loader h-4 w-1/2"></div>
                            <div className="skeleton-loader h-4 w-full"></div>
                          </div>
                          <div className="w-full lg:w-1/3 md:w-full">
                            <div className="skeleton-loader skeleton-loader--image"></div>
                          </div>
                        </div>
                      </div>
                </div>
              </main>
            </div>
          </div>
      ) : (
        IsRewardsShopSubmitted &&  RewardConfirmOrderId != 0 && (
          <div ref={rewardMessageRef} className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
            <div className="space-y-10 lg:space-y-14">
              <main>
                <div className="w-full bg-white mb-0">
                        <div className="border-b">
                          <div className="px-4 py-5 sm:px-6">
                            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
                            Rewards Order Received!
                            </h2>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-6">
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-2/3 md:w-full">
                                    <p className="mt-2 text-gray-700">We have received your rewards order and are sending you a confirmation email now.</p>
                                    <p className="mt-2 text-gray-700">Your rewards order number is: <b>{rewardsShopData?.confirmShipmentNo}</b></p>
                                    <p className="mt-2 text-gray-700">You will receive a shipping notification within <b> 1-2 business days </b>when your rewards have been shipped.</p>
                                    <p className="mt-2 text-gray-700">Should you have any questions, please do not hesitate to reach out to <Link className="text-csccolor" to={`mailto:${config.emails.EmailMarketing}`}>{config.emails.EmailMarketing}</Link></p>
                                    <p className="mt-2 text-gray-700">Thank you for supporting CSC LED!</p>
                                    <p className="mt-2 text-gray-700">The CSC LED Team</p>
                                </div>
                                <div className="flex justify-center w-full lg:w-1/3 md:w-full lg:order-last items-center">
                                    <img
                                    src={Received_Icon} 
                                    className="object-contain h-72 rewards-coin"
                                    alt="Received Icon" />
                                </div>
                            </div>
                        </div>
                </div>
              </main>
            </div>
          </div>
      ))}
    </div> 
  );
};

export default RewardsShop;
