import { BaseProp } from "entities/BaseProp";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Contact_Us_Banner from "images/contact-us/Contact_Us_Banner.jpg";

interface ContactHeaderProp extends BaseProp {
  bodyMsg: string;
}

const ContactHeader: FC<ContactHeaderProp> = ({ bodyMsg }) => {
  return (
    <div className="nc-ContactHeader relative">
      <div className={`nc-NcImage relative`} data-nc-id="NcImage">
        <img
          src={Contact_Us_Banner}
          className="w-full h-full object-cover"
          alt="Contact Us Banner"
        />
        <div className="px-5 sm:container sm:space-y-5 absolute inset-0 flex flex-col items-start justify-center text-white">
          <h1 className="text-lg font-bold tracking-tight sm:text-6xl">
            Contact Us
          </h1>
          <div className="w-60 sm:w-full">
            <span className="text-xs sm:text-lg sm:leading-8 text-gray-300">
              {bodyMsg}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHeader;
