export const ProductSortData = [
    { id: "productnameSort", name: 'Product Name: A to Z' },
    { id: "productnameSort_desc", name: 'Product Name: Z to A' },
    { id: "productnumber", name: 'Product Number: A to Z' },
    { id: "productnumber_desc", name: 'Product Number: Z to A' },
    { id: "watt", name: 'Wattage: Low to High' },
    { id: "watt_desc", name: 'Wattage: High to Low' },
    { id: "voltage", name: 'Voltage: Low to High' },
    { id: "voltage_desc", name: 'Voltage: High to Low' },
    { id: "colortemperature", name: 'Color Temperature: Low to High' },
    { id: "colortemperature_desc", name: 'Color Temperature: High to Low' },
  ];