import React from "react";

const APIQuestion = () => {
  return (
    <>
      {/* HEADING */}
      <div className="">
        <h2 className="block text-2xl sm:text-3xl font-medium">
          What Inventory Is Available?
        </h2>
        <div className="mt-2 dark:text-slate-400 text-sm sm:text-base">
          All CSC LED products which are displayed on our website are available
          through this API. This includes clearance, end of life, or even
          discontinued products. Should you wish to not include these products
          on your website, filter them out via the Clearance and Lifecycle
          Status fields included in the API.
        </div>
      </div>
    </>
  );
};

export default APIQuestion;
