// PhoneNumberInput.tsx

import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

interface PhoneNumberMaskProps {
  onChange: (value: string) => void;
  name:string;
  value:string;
  className:string;  
}

const PhoneNumberMask: React.FC<PhoneNumberMaskProps> = ({ onChange,name,value,className }) => {
  const [phoneNumber, setPhoneNumber] = useState(value);


  useEffect(() => {
    setPhoneNumber(value);
  }, [value]);

  const handlePhoneNumberChange = (event: any) => {
    const inputvalue  = event.target.value;
    setPhoneNumber(inputvalue);
    // if(onchange)
        onChange(inputvalue);
  };

  return (
    <InputMask
      className="!rounded-l-none"
      name={name}
      mask="(999) 999-9999"
      maskChar=" "
      value={phoneNumber}
      onChange={handlePhoneNumberChange}
    />
  );
};

export default PhoneNumberMask;
