import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/AxiosInstance";
import { careerDTO } from "entities/careerDTO";

export interface careerState {
  data: careerDTO | null;
  isLoading: boolean;
  error: string | null;
  activeLocation: string | null;
}

const initialState: careerState = {
  data: null,
  isLoading: false,
  error: "",
  activeLocation: null,
};

export const getCareerData = createAsyncThunk("career", async () => {
  return await axiosInstance.get("/Home/getCareers").then((res) => res.data);
});

export const careerSlice = createSlice({
  name: "career",
  initialState,
  reducers: {
    setActiveLocation: (state, action: PayloadAction<string | null>) => {
      state.activeLocation = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCareerData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCareerData.fulfilled, (state, actions) => {
      state.isLoading = false;
      state.data = actions.payload;
      state.error = null;
    });
    builder.addCase(
      getCareerData.rejected,
      (state, actions: PayloadAction<any>) => {
        state.isLoading = false;
        state.data = null;
        state.error = actions.payload ?? "An error occurred.";
      }
    );
  },
});

export const {setActiveLocation} = careerSlice.actions;

export default careerSlice.reducer;
