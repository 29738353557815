import React, { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

interface QRCodeRouteProps {}

const QRCodeRoute: FC<QRCodeRouteProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  var param = searchParams.get("productseries") || searchParams.get("searchString");

  const { productslug } = useParams<{ productslug: string }>();
  if(productslug){
    param=productslug;
  }

  useEffect(() => {
    const specificParam = routekeysArr.find((x) => x.key == param);

    if (specificParam) {
      navigate(`${specificParam.navigateUrl}`);
      return;
    }

    if (param) {
      navigate(`/products?pser=${param.toUpperCase()}`);
    } else {
      navigate(`/products`);
    }
  }, [param]);

  useEffect(() => {
    if (location.pathname === "/SFAccount/Register") {
      navigate("/signup");
      return;
    }
  }, [location]);

  

  const routekeysArr = [
    { key: "flex", navigateUrl: "/products?pser=FL05" },
    { key: "fl05 accessories", navigateUrl: "/products?dept=Accessories&pser=FL05" },
    { key: "CDL02", navigateUrl: "/products?pser=CDL02"},
    { key: "SS-HG70-20W", navigateUrl: "/products?pser=SS"},
    { key: "wr1", navigateUrl: "/product-details/wr1-35w-40k-lv"},
    { key: "wr2", navigateUrl: "/product-details/wr2-24w-35w-3cct-ud"},
    {
      key: "CES-J100",
      navigateUrl: "/products?pser=CES",
    },
    {
      key: "FL04B Series",
      navigateUrl: "/products?pser=FL04B",
    },
    {key:"WR1-35W-40K-LV",navigateUrl: "/product-details/wr1-35w-40k-lv",},
    {key:"WR2-24W-35W-3CCT-UD",navigateUrl: "/product-details/wr2-24w-35w-3cct-ud",},
  ];

  return <></>;
};

export default QRCodeRoute;
