import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { TeamMember } from "./OurTeam";
import NcImage from "shared/NcImage/NcImage";
import { config } from "utils/Constance";

interface ModalMemberBioProps {
  show: boolean;
  onCloseModal: () => void;
  teamMember?: TeamMember | null; // Add this line
}

const ModalMemberBio: FC<ModalMemberBioProps> = ({
  show,
  onCloseModal,
  teamMember,
}) => {
  const renderQuestionAnswer = (question?: string, answer?: string) =>
    question && (
      <div>
        <h2 className="font-semibold text-base">{question}:</h2>
        <span className="text-black-800 dark:text-black-300 text-base font-light">
          {answer}
        </span>
      </div>
    );

  const renderModalBody = () => {
    return (
      <div className="bg-white my-5">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div
            key={teamMember?.employeeName}
            className="flex flex-col gap-6 sm:flex-row"
          >
            <NcImage
              containerClassName="shrink-0 sm:w-80"
              className="aspect-[5/5] rounded-2xl object-cover"
              src={`${config.url.AWS_S3_URL}${teamMember?.employeePictureUrl}`}
              alt=""
            />
            <div className="flex-auto">
              <h3 className="text-xl font-medium leading-8 tracking-tight text-csccolor">
                {teamMember?.employeeName}
              </h3>
              <p className="text-base leading-7 text-black-800 font-medium">
                {`${teamMember?.department}, ${teamMember?.jobTitle}`}
              </p>
              {/* {teamMember?.bioDescription && (
              <div
                className="prose prose-sm sm:prose dark:prose-invert bio-container !text-sm"
                dangerouslySetInnerHTML={{
                  __html: teamMember?.bioDescription,
                }}
              ></div>
            )} */}
              {teamMember?.bioDescription && (
                <p
                  className="mt-4 leading-5 text-base leading-7 text-black-800 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: teamMember?.bioDescription,
                  }}
                ></p>
              )}
              <hr className="my-4 border-slate-200 dark:border-slate-700" />
              <div className="space-y-3">
                {renderQuestionAnswer(
                  teamMember?.questionTitle1,
                  teamMember?.questionAnswer1
                )}
                {renderQuestionAnswer(
                  teamMember?.questionTitle2,
                  teamMember?.questionAnswer2
                )}
                {renderQuestionAnswer(
                  teamMember?.questionTitle3,
                  teamMember?.questionAnswer3
                )}
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4 border-slate-200 dark:border-slate-700" />
      </div>
    );
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onCloseModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 h-auto w-full max-w-5xl">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="headlessui-dialog-title-70"
                  >
                    Team Member Bio
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={onCloseModal} />
                  </span>
                </div>
                {/**Modal body */}
                {renderModalBody()}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalMemberBio;
