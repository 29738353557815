import React, { FC } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

interface SuccessMessageProp {
  className?: string;
  headerTitle?: string;
  bodyMessage?: string;
}

const SuccessMessage: FC<SuccessMessageProp> = ({
  className = "",
  headerTitle,
  bodyMessage,
}) => {
  return (
    <div className="rounded-md bg-green-50 p-4 scroll-mt-32" id="SuccessMsg">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {headerTitle && (
            <h3 className="text-sm font-medium text-green-800">
              {headerTitle}
            </h3>
          )}
          {bodyMessage && (
            <div
              className="mt-2 text-sm text-green-700"
              dangerouslySetInnerHTML={{
                __html: bodyMessage.replace(/\n/g, "<br>"),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
