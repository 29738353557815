import ContactUsRawHTML from "containers/ContactUs/ContactUsRawHTML";
import { BaseProp } from "entities/BaseProp";
import { ContactPageSetting, UserForm } from "entities/ContestDTO";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import ApplyForContest from "./ApplyForContest";
import { BaseFilterString } from "entities/BaseEntities";
import Divider from "components/Divider";

interface ContestDetailProp extends BaseProp {
  contactPageSetting: ContactPageSetting | null;
  occupationList: BaseFilterString[];
  userFormList: UserForm[];
}

const ContestDetail: FC<ContestDetailProp> = ({
  className = "",
  contactPageSetting,
  occupationList,
  userFormList,
}) => {
  const renderBannerImage = () => {
    return (
      <div className="relative overflow-hidden lg:aspect-w-16 lg:aspect-h-8 2xl:aspect-h-7">
        {contactPageSetting && (
          <img
            className="relative w-full h-full object-fill"
            src={`${contactPageSetting.bannerImageUrl}`}
            alt="Contest Banner"
          />
        )}
      </div>
    );
  };

  const renderContestDetail = () => {
    return (
      <main>
        <div
          className={`flex flex-col sm:flex-row sm:items-center group${className}`}
        >
          <div className="flex flex-col flex-grow space-y-5">
            <h2 className="block text-2xl sm:text-3xl font-medium">
              {contactPageSetting?.detailSubtitle}
            </h2>
            {contactPageSetting?.detail && (
              <ContactUsRawHTML className="ck-content" rawHtml={contactPageSetting.detail}/>
            )}
          </div>

          <div
            className={`block flex-shrink-0 sm:w-1/4 sm:ml-6 rounded-xl overflow-hidden mb-5 sm:mb-0`}
          >
            <div className={`block w-full h-0 aspect-h-16 aspect-w-16 `}>
              <NcImage
                src={contactPageSetting?.detailImageUrl}
                containerClassName="absolute inset-0 object-cover"
              />
            </div>
          </div>
        </div>
      </main>
    );
  };

  const renderFinePrint = () => {
    return (
      <>
        {/* HEADING */}
        <Divider/>
        <div className="max-w-screen-sm">
          <h2 className="block text-2xl sm:text-3xl font-medium">
            Contest Details
          </h2>
        </div>
        {contactPageSetting?.finePrint && (
          <ContactUsRawHTML className="ck-content" rawHtml={contactPageSetting.finePrint}/>
        )}
      </>
    );
  };

  return (
    <>
      {contactPageSetting && (
        <div className={`nc-ContestDetail ${className}`}>
          {renderBannerImage()}
          <div className="container mt-5 lg:mt-11 pb-16 scroll-mt-32">
            <div className="space-y-10 lg:space-y-14">
              {/* HEADING */}
              <div className="max-w-screen-sm">
                <h2 className="block text-2xl sm:text-3xl font-medium">
                  {contactPageSetting?.detailTitle}
                </h2>
              </div>
              <Divider/>
              {renderContestDetail()}
              <ApplyForContest
                contestId={contactPageSetting.contestId}
                contestTitle={contactPageSetting.detailTitle}
                FormTitle={contactPageSetting.formTitle}
                userFormList={userFormList}
                occupationList={occupationList}
              />
              {contactPageSetting?.finePrint && renderFinePrint()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContestDetail;
