import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { decryptData, encryptData } from 'utils/Encryption';

interface UserData {
  userId: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

interface AuthState {
  isAuthenticated: boolean;
  userData: UserData | null;
  token: string | null;
  keepLoggedIn: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  userData: null,
  token: null,
  keepLoggedIn: false,
};

// const authStateFromCookie = Cookies.get('_authState');
// if (authStateFromCookie) {
//   debugger
//   const decryptedUserData = decryptUserData(authStateFromCookie);
//   const user = {
//     userId: decryptedUserData.userId,
//     email: decryptedUserData.email,
//     password: decryptedUserData.password,
//     firstName: decryptedUserData.firstName,
//     lastName: decryptedUserData.lastName
//   }; 
//   initialState.isAuthenticated = decryptedUserData.isAuthenticated;
//   initialState.userData = user;
//   initialState.keepLoggedIn = decryptedUserData.keepLoggedIn;
// }
const loadStateFromCookies = (): AuthState => {
  const authStateFromCookie = Cookies.get('_authState');
  if (authStateFromCookie) {
    try {
      const decryptedUserData = decryptData(authStateFromCookie);
      const user: UserData = {
        userId: decryptedUserData.userId,
        email: decryptedUserData.email,
        password: decryptedUserData.password,
        firstName: decryptedUserData.firstName,
        lastName: decryptedUserData.lastName,
      };
      return {
        isAuthenticated: decryptedUserData.isAuthenticated,
        userData: user,
        token: decryptedUserData.token,
        keepLoggedIn: decryptedUserData.keepLoggedIn,
      };
    } catch (error) {
      console.error('Failed to decrypt user data:', error);
      // Optionally remove invalid cookie
      Cookies.remove('_authState');
      return initialState;
    }
  }
  return initialState;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: loadStateFromCookies(),
  reducers: {
    login(state, action: PayloadAction<{isAuthenticated : boolean; userData: UserData; token: string; keepLoggedIn: boolean }>) {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.userData = action.payload.userData;
      state.token = action.payload.token;
      state.keepLoggedIn = action.payload.keepLoggedIn;
      const encryptedUserData = encryptData({isAuthenticated : action.payload.isAuthenticated, ...action.payload.userData,  token: action.payload.token, keepLoggedIn: action.payload.keepLoggedIn });
      const expires = action.payload.keepLoggedIn ? new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) :  new Date(new Date().setMinutes(new Date().getMinutes() + 240));
      Cookies.set('_authState', encryptedUserData, { expires });
    },
    logout(state) {
      state.isAuthenticated = false;
      state.token = null;
      if (!state.keepLoggedIn) {
        state.userData = null;
        //Cookies.remove('_authState');
      }
    }, 
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;