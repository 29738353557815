import React from "react";
import { FC,useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import axiosInstance from "api/AxiosInstance";
//import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import { useUserState } from "hooks/useUserState";

export interface CommonLayoutProps {
  children?: React.ReactNode;
}



const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {


  // const jsonString = localStorage.getItem('user');
  // let userId:any;
  // const [data, setData] = useState<any>(null);
  const user = useAppSelector((state: RootState) => state.auth);
   const [selectedOption, setSelectedOption] = useState<any>('');
   const [isToggled, setIsToggled] = useState(false);
   const [accountName, setaccountName] = useState<any>('');
   const _userStateConfig = useUserState();

   const handleToggle = () => {    
    setIsToggled(!isToggled);
  };  

// Check if the ID exists
// if (jsonString) { 
//   // Step 2: Parse the JSON string into a JavaScript object
//   const jsonObject = JSON.parse(jsonString);
//   // Step 3: Access the userid property from the object
//   userId = jsonObject.userId;

// } else {
//   console.log("ID not found in localStorage");
// }

  const fetchData = async () => {
    let id = user.userData?.userId;
    const response = await axiosInstance
      .get(`/SFAccount`).then((response) => {
          // Update the state with fetched data
         //console.log("====>",response.data);
         setaccountName(response.data);
      }).catch((error) => {
        console.error("Error fetching data:", error);
      });
    
  };

  useEffect(() => {
    fetchData();
  }, []);



  return (
    <div className="nc-CommonLayoutProps container">
      <div className="mt-14 sm:mt-20">
        <div className="max-w-4xl mx-auto">
          <div className="max-w-2xl">
            <h2 className="text-3xl xl:text-4xl font-semibold font-poppins">My Information</h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-base sm:text-lg">
              <span className="text-slate-900 dark:text-slate-200 font-semibold">
                {accountName.firstName} {accountName.lastName},
              </span>{" "}
              {accountName.emailAddress} · {accountName.city}, {accountName.province}
            </span>
          </div>
          <hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>

          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            {[
              {
                name: "My Information",
                link: "/account",
              },
              // {
              //   name: "Shipping Address",
              //   link: "/shipping-address",
              // },
              // {
              //   name: "Favourites",
              //   link: "/account-savelists",
              // },
              // {
              //   name: " My order",
              //   link: "/account-my-order",
              // },
              {
                name: "Update Password",
                link: "/account-change-password",
              },
              // {
              //   name: "Change Billing",
              //   link: "/account-billing",
              // },
            ].filter((item) => item.name !== "Update Password" || !_userStateConfig?.isViewChangePassword).map((item, index) => (
              <NavLink
                key={index}
                to={item.link}
                className={({ isActive }) =>
                  `block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0  text-sm sm:text-base ${
                    isActive
                      ? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"
                      : "text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"
                  }`
                }
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <hr className="border-slate-200 dark:border-slate-700"></hr>
        </div>
      </div>
      <div className="max-w-4xl mx-auto pt-9 sm:pt-26 pb-9 lg:pb-32">
        {children}
      </div>
    </div>
  );
};

export default CommonLayout;
