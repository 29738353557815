import { Popover, Transition,Dialog } from "@headlessui/react";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import { Fragment,useEffect,useState,useRef } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import axiosInstance from "api/AxiosInstance";
import { config } from "utils/Constance";
import ImageNotFound from "images/NoImageAvailable.png";
import CurrencyFormat from "components/CurrencyFormatter";
import Backdrop from "../../shared/Confirmdialogbox/Backdrop";
import DialogPanel from "../../shared/Confirmdialogbox/DialogPanel";
import { getCartData } from "../../store/slices/cartSlice";
import { getCartListData } from "../../store/slices/cartDetailListSlice";
import { getCartIndexListData } from "../../store/slices/cartIndexListSlice";

//import {  useSelector } from "react-redux";
import { RootState } from "store/store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";

export interface ProductList {
  id: number;
  name: string;
  price: number;
  image: string;
  description: string;
}

export default function CartDropdown() {

  let totalCartPrice : any;
  totalCartPrice = 0.0;

  let Discount: any;
  Discount = 0.0;


  const dispatch = useAppDispatch();
  const cartCount = useAppSelector((state) => state.cart.count);
  //console.log("carttttCount",cartCount);
 
  const cartList = useAppSelector((state) => state.cartList.CartDetailList);
  
  //console.log("========CartList Data",cartList);

  const [shoppingCartDetails, setShoppingCartDetails] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [websiteDiscount, setWebsiteDiscount] = useState<boolean>();
  const initialFetch = useRef(true); 
  
  const fetchData = async () => {
    const secondApiResponse = await axiosInstance.get(
      `/Cart/GetWebsiteDiscount`
    );
    setWebsiteDiscount(secondApiResponse.data);
  }

  useEffect(() => {
    if (initialFetch.current) {   
    initialFetch.current = false;
   }
  }, []);


  // Ref to track if the dispatch has already happened
  const hasDispatchedCartCount = useRef(false);
  const hasDispatchedCartList = useRef(false); 

  useEffect(() => {
    if (!hasDispatchedCartCount.current && (!cartCount || cartCount === 0)) {
     // console.log("====>Entered here on Index cart count");
      dispatch(getCartData());
      hasDispatchedCartCount.current = true; // Mark as dispatched
    }
  }, [dispatch, cartCount]);

  useEffect(() => {
    // Reset the ref if cartCount is reset, allowing a new dispatch if needed
    if (cartCount > 0) {
      hasDispatchedCartCount.current = false;
    }
  }, [cartCount]);

  // Dispatch for cartList
  useEffect(() => {
    if (!hasDispatchedCartList.current && (!cartList || cartList.length === 0)) {
      //console.log("====>Entered here on Cart List");
      dispatch(getCartListData());
      hasDispatchedCartList.current = true;
    }

    // Optionally reset if cartList gets populated
    if (cartList && cartList.length > 0) {
      hasDispatchedCartList.current = false;
    }
  }, [dispatch, cartList]);


  const handleOpen = () => {
    setIsOpen(true);
    setShowModal(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const showDeleteConfirmation = (id: any) => {
    setSelectedItem(id);
    setShowConfirmation(true);
    handleOpen();
  };

  const handleDelete = () => {
    // Perform delete action
  //  console.log("Item deleted:", selectedItem);
    setShowConfirmation(false);
    callDeleteAPI(selectedItem);
  };

  const callDeleteAPI = async (itemId: any) => {
    try {
      const id = itemId;
      await axiosInstance.get(`/Cart/DeleteCartItem?id=${id}`);      
      dispatch(getCartListData());
      dispatch(getCartData());
      dispatch(getCartIndexListData());
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const renderProduct = (item: any, index: any, close: () => void) => {
    // const { name, price, image } = item;
    return (
      <div key={index} className="flex py-5 last:pb-0">
        <div className="relative h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl border border-gray-200">
        {item.inventoryImagesLowRes != null &&
                          item.inventoryImagesLowRes.length !== 0 ? (
          <><img
                src={config.url.ERP_Domain +
                  item.inventoryImagesLowRes[0].imageUrl}
                alt={`productImage`}
                className="h-full w-full object-contain object-center" /><Link
                  onClick={close}
                  className="absolute inset-0"
                  to={`/product-details/${encodeURIComponent(item.invDTO.productNumber).toUpperCase()}`}/></>
                          ): (
                            <img
                              src={ImageNotFound}
                              alt={`productImageNotfound`}
                              className="h-full w-full object-contain object-center"
                            />
                          )}
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="text-black dark:text-slate-400 text-sm">
                <h3 className="text-base font-medium ">
                  <Link onClick={close} to={`/product-details/${encodeURIComponent(item.invDTO.productNumber).toUpperCase()}`}>
                  {item.invDTO != null
                                      ? item.invDTO.productNumber
                                      : ""}
                  </Link>
                </h3>
                <p className="mt-1">
                <span>Unit Price: <CurrencyFormat
                          value={item.price.toFixed(2)}
                        /></span>
                </p>
                <p className="">
                <span> Quantity: {item.qty}</span>
                  </p>
              </div>
              <Prices
                    price={item.totalPrice}
                    className="mt-0.5"
                  />
            </div>
          </div>
           {/* calculate totalPrice of order */}
           <span hidden>
                            {
                              (totalCartPrice = item.cartSubTotalPrice)
                            }
                          </span>
                          <span hidden>
                            {
                              (Discount = item.discount)
                            }
                          </span>
          <div className="flex flex-1 items-end justify-between text-sm">
            {/* <p className="text-gray-500 dark:text-slate-400">Quantity: {item.qty}</p> */}
            <p className="text-gray-500 dark:text-slate-400"></p>
            <div className="flex">
              {/* <button
                type="button"
                className="font-medium text-primary-600 dark:text-primary-500 "
              >
                Remove
              </button> */}
              <a
                                href="javascript:void(0);"
                                onClick={() => showDeleteConfirmation(item.id)}
                                className="relative z-10 flex items-center font-medium text-primary-600 hover:text-primary-500 text-sm "
                              >
                                <span className="text-slate-500 ">Remove</span>
                              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : "text-opacity-90"}
                 group w-10 h-10 sm:w-12 sm:h-12 hover:bg-slate-100 dark:hover:bg-slate-800 rounded-full inline-flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
          >
            <div className="w-3.5 h-3.5 flex items-center justify-center bg-primary-500 absolute top-1.5 right-1.5 rounded-full text-[10px] leading-none text-white font-medium">
              <span className="mt-[1px]">
                {/* {shoppingCartDetails.length} */}
                {cartCount}</span>
            </div>
            <svg
              className="w-6 h-6 halloween:text-black"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 8H21"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Link className="block md:hidden absolute inset-0" to={"/Cart/index"} />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="hidden md:block absolute z-10 w-screen max-w-xs sm:max-w-md px-4 mt-3.5 -right-28 sm:right-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                <div className="relative bg-white dark:bg-neutral-800">
                  <div className="max-h-[60vh] p-5 overflow-y-auto hiddenScrollbar">
                    <h3 className="text-black text-xl font-semibold">Your cart</h3>
                    {cartList.length !== 0 ? (
                      <div className="divide-y divide-slate-100 dark:divide-slate-700">
                      {cartList.map(
                        (item: any, index: any) => renderProduct(item, index, close)
                      )}
                    </div>
                    ) : (
                      <p className="mt-3">Your cart is emptier than a monday morning coffee cup.</p>
                    )}

                  </div>
                  <div className="bg-neutral-50 dark:bg-slate-900 p-5">
                  {websiteDiscount === true ? (
                  <p className="flex justify-between font-semibold text-slate-500 dark:text-slate-200">
                  <span className="text-csccolor-green">
                      2% Online Order Discount
                      </span>

                      <span className="text-csccolor-green">
                      -<CurrencyFormat value={Discount} />
                        </span>
                    </p>
                  ) : ("")}
                    <p className="flex justify-between font-semibold text-slate-900 dark:text-slate-100">
                      <span>
                        <span>Subtotal</span>
                      </span>

                      <span className="">
                        <CurrencyFormat value={totalCartPrice} />
                        </span>
                    </p>
                    <div className="flex space-x-2 mt-5">                                      
                    <ButtonCSCLed
                      href="/Cart/index"
                      className="flex-1"
                      type="submit"
                      onClick={close}
                    >
                         It's cart time
                    </ButtonCSCLed>                              
                    </div>
                  </div>
                </div>
              </div>


            </Popover.Panel>
          </Transition>
        </>

      )}
    </Popover>
  {showConfirmation && (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={handleClose}
      >
        <Backdrop isOpen={isOpen} />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel
              title={"Wait a Second!"}
              message={
                "Are you sure you want to remove this item from your cart?"
              }
              onConfirm={handleDelete}
              handleClose={handleClose}
            />
          </div>
        </div>
      </Dialog>
    </Transition>

  )}
    </>
  );
}
