import { SearchBarDTO } from "entities/SearchBarDTO";
import React, { FC, useEffect, useState } from "react";

const UseAutocomplete = (
  data: SearchBarDTO[],
  inputSearchRef: HTMLInputElement | null
) => {
  const [searchedValue, setSearchedValue] = useState("");
  const [suggestions, setSuggestions] = useState<SearchBarDTO[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [activeSuggestion, setActiveSuggestion] = useState(0);

  useEffect(() => {
    if (inputSearchRef) {
      inputSearchRef.focus();
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setSearchedValue(value);

    if (value === "") {
      setSuggestions([]);
      return;
    }

    const filteredSuggestions = data.filter((itemData) => {
      const name = itemData.heading.toUpperCase();
      return name.startsWith(value.toUpperCase()) //&& name !== value;
    });

    setSuggestions(filteredSuggestions);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "ArrowDown" && activeSuggestion < suggestions.length) {
      setActiveSuggestion(activeSuggestion + 1);
    } else if (event.key === "ArrowUp" && activeSuggestion > 1) {
      setActiveSuggestion(activeSuggestion - 1);
    } else if (event.key === "Enter" && activeSuggestion > 0) {
      setSearchedValue(suggestions[activeSuggestion - 1].heading);
      setSelectedSuggestion(suggestions[activeSuggestion - 1].heading);
      setSuggestions([]);
      setActiveSuggestion(0);
    }
  };

  const handleClick = (value: string) => {
    setSelectedSuggestion(value);
    setSearchedValue(value);
    setSuggestions([]);
    setActiveSuggestion(0);
    //do something else
  };

  const clearInputVal = () => {
    setSearchedValue("")
    setSuggestions([]);
    setActiveSuggestion(0);
  };

  return {
    searchedValue,
    suggestions,
    selectedSuggestion,
    activeSuggestion,
    handleChange,
    handleKeyDown,
    handleClick,
    clearInputVal,
  };
};

export default UseAutocomplete;
