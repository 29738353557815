import Heading from "components/Heading/Heading";
import { FeaturedProduct } from "entities/HomePageBannerList";
import usePagination from "hooks/usePagination";
import React, { FC, useEffect, useId } from "react";
import NcImage from "shared/NcImage/NcImage";
import { config } from "utils/Constance";
import HomePagination from "./HomePagination";

export interface FeaturedProductsProps {
  className?: string;
  productList: FeaturedProduct[];
  headerTitle?: string;
}

const FeaturedProducts: FC<FeaturedProductsProps> = ({
  className = "",
  productList,
  headerTitle,
}) => {
  {
    /**Hook for pagination */
  }
  const { currentItems, currentPage, itemsPerPage, totallength, onPageChange } =
    usePagination(productList, 3, "FeaturedProducts");

  const renderTiles = () => {
    return (
      <div className="mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0 !font-poppins">
        {currentItems.map((product, index) => (
          <a target="_blank" key={index} href={product.url} className="group block">
            <NcImage
              containerClassName="w-2/4 sm:w-full sm:aspect-w-8 sm:aspect-h-5 overflow-hidden rounded-lg group-hover:opacity-75"
              className="h-full w-full object-cover object-center"
              src={`${config.url.ERP_Domain}${product.fileUrl}`}
            />
            <h3 className="mt-4 font-medium text-lg sm:text-xl text-csccolor">
              {product.title}
            </h3>
            <p className="mt-2 text-base text-black">{product.subtitle}</p>
          </a>
        ))}
      </div>
    );
  };

  return (
    <div className={`nc-FeaturedProducts scroll-mt-32 ${className}`} id="FeaturedProducts">
      <div className={`flow-root`}>
        <Heading
          className="mb-12 text-neutral-900 dark:text-neutral-50 font-poppins"
          isCenter={false}
        >
          {headerTitle}
        </Heading>
        {renderTiles()}
        {/* PAGINATION */}
        {productList && productList.length > itemsPerPage && (
          <HomePagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totallength as number}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default FeaturedProducts;
