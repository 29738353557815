import React, { useState } from 'react';

interface AccordionProps {
  question: string;
  answer: string;
}

const Accordion: React.FC<AccordionProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
       <div className="bg-gray-50 hover:bg-gray-100 cursor-pointer flex justify-center items-center"  onClick={toggleAccordion}>
      <button
        className="flex justify-between items-center p-4 pr-4 px-6 w-full text-left text-gray-900  focus:outline-none"      
      >
        <span>{question}</span>
        </button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`transform  max-w-12 w-12 flex justify-end items-end transition-transform ${isOpen ? 'rotate-180' : ''}`}
          viewBox="0 0 20 20"
          fill="currentColor"
          width="20"
          height="20"
        >
          <path
         
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        </div>
     
      {isOpen && (
        <div className="p-6 text-gray-600 halloween:text-black">
          {answer}
        </div>
      )}
    </div>
  );
};

export default Accordion;