import { ContactUsPageDTO } from "entities/ContactUsPageDTO";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ContactHeader from "./ContactHeader";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getContactUs } from "store/slices/contactUsSlice";
import { BaseProp } from "entities/BaseProp";
import Divider from "components/Divider";

interface ContactUsProp extends BaseProp {
}

const ContactUs: FC<ContactUsProp> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const response = useAppSelector((state) => state.contactUs.data);
  const isAPILoad = useAppSelector(
    (state) => state.contactUs.isLoading
  ) as boolean;

  useEffect(() => {
    if (!response) {
      dispatch(getContactUs());
    }
  }, [dispatch, response]);

  const primaryLocation = response?.data
    .filter((a) => a.isPrimaryLocation)
    .sort((a, b) => a.locationName.localeCompare(b.locationName) || 0);
    
  const secondaryLoc = response?.data
    .filter((a) => !a.isPrimaryLocation)
    .sort((a, b) => a.locationName.localeCompare(b.locationName) || 0);

  const renderLocationCard = (item: ContactUsPageDTO, index: number) => {
    return (
      <div
        key={index}
        className="relative aspect-w-5 aspect-h-3 rounded-3xl overflow-hidden group"
      >
        <Link to={`/contact-us/${item.systemName}`} />
        <NcImage
          className="object-fit w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={item.logoUrl}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{item.locationName}</span>
          </h2>
          {/* <div className="flex">
          <span className="block text-neutral-200 hover:text-white font-medium truncate">
            Canada
          </span>
          <span className="mx-1.5 font-medium">·</span>
          <span className="font-normal truncate">May 20, 2021</span>
        </div> */}
        </div>
        <Link to={`/contact-us/${item.systemName}`} />
      </div>
    );
  };

  const renderBranches = (titleName: string, array: ContactUsPageDTO[]) => {
    return (
      <>
        {/* HEADING */}
        <div className="max-w-screen-sm">
          <h2 className="block text-2xl sm:text-3xl font-medium">
            {titleName}
          </h2>
        </div>
        <Divider/>
        <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {array.map((v, i) => renderLocationCard(v, i))}
        </div>
      </>
    );
  };

  return (
    <div className={`nc-ContactUs ${className}`} data-nc-id="ContactUs">
      <Helmet>
        <title>Contact Us | CSC LED</title>
        <meta name="title" content="CSC LED Contact Us" />
        <meta
          name="description"
          content="Contact any one of our offices or sales agents for immediate assistance."
        />
        <meta
          name="keywords"
          content="LED, Light, Wholesale, Canada, Canadian, Distributor, Support, Sales, Certified, LED Lights, Commercial LED Lights, Industrial LED Lights, LED Fixtures, LED Lights, LED Tubes, LED Bulbs, Contact, Contact Us"
        />
      </Helmet>
      <ContactHeader bodyMsg={`Select a CSC LED branch or partnered sales agency below to get in
          touch today.`}/>
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          {primaryLocation &&
            renderBranches("CSC LED Branches", primaryLocation)}
          {secondaryLoc &&
            renderBranches("Our Partnered Sales Agencies", secondaryLoc)}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
