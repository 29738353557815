import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { BaseProp } from "entities/BaseProp";
import React, { FC, Fragment } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface Props extends BaseProp {
  isModalOpen: boolean;
  onCloseModal: () => void;
  titleMsg: string;
  modalBody?: string;
  confirmButtonText?: string;
}

const ModalErrorNotify: FC<Props> = ({
  className = "",
  isModalOpen,
  onCloseModal,
  titleMsg,
  modalBody,
  confirmButtonText = "Confirm",
}) => {
  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 sm:p-10 text-left align-middle shadow-xl transition-all">
                <div>
                  <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-red-100">
                    <ExclamationTriangleIcon
                      aria-hidden="true"
                      className="h-14 w-14 text-red-600"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {titleMsg}
                    </Dialog.Title>
                    {modalBody && (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{modalBody}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center">
                  <ButtonPrimary onClick={onCloseModal} className="w-full">
                    {confirmButtonText}
                  </ButtonPrimary>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalErrorNotify;
