import { BaseProp } from "entities/BaseProp";
import { SearchBarDTO } from "entities/SearchBarDTO";
import React, { FC, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

interface AutocompleteProps extends BaseProp {
  searchedValue: string;
  suggestions: SearchBarDTO[];
  selectedSuggestion: string;
  activeSuggestion: number;
  handleClick: (value: string) => void;
  onClickClose?: () => void;
}

const Autocomplete: FC<AutocompleteProps> = ({
  className = "",
  searchedValue,
  suggestions,
  selectedSuggestion,
  activeSuggestion,
  handleClick,
  onClickClose,
}) => {
  return (
    <>
      <ul
        className={`nc-Autocomplete bg-white shadow rounded max-h-72 overflow-auto customScrollBar divide-y divide-slate-100${className}`}
      >
        {!suggestions.length &&
        searchedValue.length &&
        !selectedSuggestion.length ? (
          <></>
        ) : (
          // <li className="p-4 text-center text-gray-500">
          //   <li className={``}>Nothing to show :(</li>
          // </li>
          <>
            {suggestions.map(
              (
                {
                  heading,
                  description,
                  lowresimage,
                  productnumber,
                }: SearchBarDTO,
                index
              ) => (
                <li
                  key={index}
                  className={`p-4 cursor-pointer hover:bg-gray-100 ${
                    index === activeSuggestion - 1 ? "bg-gray-200" : ""
                  }`}
                  onClick={() => {
                    handleClick(heading);
                    onClickClose?.();
                  }}
                >
                  <NavLink
                    className="flex"
                    to={`/product-details/${encodeURIComponent(productnumber).toUpperCase()}`}
                  >
                    <NcImage
                      src={lowresimage}
                      alt={heading}
                      className="w-16 h-16 mr-2"
                    ></NcImage>
                    <div className="flex flex-col">
                      <div className="font-medium text-gray-950">
                        <span>{heading}</span>
                      </div>
                      <div className="text-slate-500 font-normal text-sm">
                        <span>{description}</span>
                      </div>
                    </div>
                  </NavLink>
                </li>
              )
            )}
          </>
        )}
      </ul>
    </>
  );
};

export default Autocomplete;
