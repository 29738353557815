import { BaseProp } from "entities/BaseProp";
import React, { FC } from "react";

interface ContactUsRawHTMLProp extends BaseProp{
    rawHtml:string,
}

const ContactUsRawHTML:FC<ContactUsRawHTMLProp> = ({
    className="",
    rawHtml,
}) => {
  return (
    <div
      className={`prose prose-p:mt-0 sm:prose-p:mt-0 
                prose-h5:font-medium prose-h5:text-xl prose-h5:mb-2
                prose-h4:font-medium prose-h4:text-2xl prose-h4:mt-0 sm:prose-h4:mt-0
                prose-a:no-underline sm:prose-a:no-underline
                sm:prose dark:prose-invert
                sm:max-w-full
                ${className}
                `}

      dangerouslySetInnerHTML={{ __html: rawHtml }}
    ></div>
  );
};

export default ContactUsRawHTML;
