import Label from "components/Label/Label";
import React, { useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import axiosInstance from "api/AxiosInstance";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import AlertMessage from "../../containers/Cart/AlertMessage";
import ModalAccountPassword from "./ModalAccountPassword";
import { Helmet } from "react-helmet-async";
import { useUserState } from "hooks/useUserState";
import { useNavigate } from "react-router-dom";

const AccountPass = () => {
  const user = useAppSelector((state: RootState) => state.auth);
  const _userStateConfig = useUserState();
  const navigate = useNavigate();

  // Form data code
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [securityStamp, setsecurityStamp] = useState<any>("");
  const [currentPassword, setcurrentPassword] = useState<any>("");
  const [newPassword, setnewPassword] = useState<any>("");
  const [confirmNewPassword, setconfirmNewPassword] = useState<any>("");
  const [isAlertMessageDisplay, setIsAlertMessageDisplay] = useState(true);
  const [isSpecialCharMessageDisplay, setSpecialCharMessageDisplay] =
    useState(false);
  const [ispasswordMatchingMessageDisplay, setpasswordMatchingMessageDisplay] =
    useState(false);
  const [isModalAccountSave, setIsModalAccountSave] = useState(false);
  const [accountSaveMessage, setaccountSaveMessage] = useState<any>("");

  const onModalClose = () => setIsModalAccountSave(false);
  const regex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$"
  );

  const fetchData = async () => {
    const response = await axiosInstance
      .get(`/SFAccount`)
      .then((response) => {
        // console.log("===>", response);
        setsecurityStamp(response.data.securityStamp);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCurrentPassWordChange = async (e: any) => {
    let errorMessage = "";
    const name = "currentPassword";
    if (e.target.value !== "") {
      formData.currentPassword = e.target.value;
    } else {
      errorMessage = "Current password is required.";
    }
    setcurrentPassword(e.target.value);
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleNewPassWordChange = async (e: any) => {
    let errorMessage = "";
    const name = "newPassword";
    if (e.target.value !== "") {
      formData.newPassword = e.target.value;
    } else {
      errorMessage = "New password is required.";
    }
    setnewPassword(e.target.value);
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleconfirmNewPasswordChange = async (e: any) => {
    let errorMessage = "";
    const name = "confirmNewPassword";
    if (e.target.value !== "") {
      formData.confirmNewPassword = e.target.value;
    } else {
      errorMessage = "Confirm new password is required.";
    }
    setconfirmNewPassword(e.target.value);
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Call the onSubmit function from props with the form data
    handleFormSubmit(formData);
  };

  const handleFormSubmit = async (data: any) => {
    if (data.currentPassword !== "") {
      const responseValidPassword = await axiosInstance.get(
        `/SFAccount/CheckCurrentPassword?CurrentPwd=${data.currentPassword}&Stamp=${securityStamp}`
      );

      //console.log("===validPassword", responseValidPassword.data);
      if (responseValidPassword.data === false) {
        setIsAlertMessageDisplay(responseValidPassword.data);
      } else {
        setIsAlertMessageDisplay(true);

        //  allow to change password if current password is entered correctly
        if (data.newPassword === data.confirmNewPassword) {
          if (
            data.newPassword !== "" &&
            data.confirmNewPassword !== "" &&
            !regex.test(data.newPassword)
          ) {
            setSpecialCharMessageDisplay(true);
            setpasswordMatchingMessageDisplay(false);
          } else {
            setSpecialCharMessageDisplay(false);
            setpasswordMatchingMessageDisplay(false);
            // Submit data to API

            //console.log("====>", formData);

            var isValid = validateForm();
            console.log("=error", Object.keys(errors).length);
            if (isValid) {
              console.log("errors", errors);
              return;
            } else {
              try {
                const responseUpdatedPassword = await axiosInstance.post(
                  `/SFAccount/UpdateSFAccountPassword`,
                  formData
                );
                //console.log("after updated", responseUpdatedPassword);
                if (responseUpdatedPassword.data === true) {
                  setIsModalAccountSave(true);
                  setcurrentPassword("");
                  setnewPassword("");
                  setconfirmNewPassword("");
                  formData.currentPassword = "";
                  formData.newPassword = "";
                  formData.confirmNewPassword = "";
                }
              } catch (error) {
                console.error("API Error:", error);
                // Handle error, show an error message, etc.
              }
            }
          }
        } else {
          setpasswordMatchingMessageDisplay(true);
        }
      }
    } else {
      //setIsAlertMessageDisplay(true);
      var isValid = validateForm();
      console.log("=error", Object.keys(errors).length);
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.currentPassword.trim()) {
      newErrors.currentPassword = "Current password is required.";
    }
    if (!formData.newPassword.trim()) {
      newErrors.newPassword = "New password is required.";
    }
    if (!formData.confirmNewPassword.trim()) {
      newErrors.confirmNewPassword = "Confirm new password is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length !== 0 ? true : false;
  };

  return (
    <div>
      <Helmet>
        <title>Update Password | CSCLED</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Update your password
          </h2>
          <form onSubmit={handleSubmit}>
            <div className=" max-w-xl space-y-6">
              <div>
                <Label>Current password</Label>
                <Input
                  style={{
                    border:
                      errors.currentPassword && errors.currentPassword !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                  value={currentPassword}
                  onChange={handleCurrentPassWordChange}
                  name="currentPassword"
                  type="password"
                  className="mt-1.5"
                />
                {errors.currentPassword && (
                  <span style={{ color: "#fd397a" }}>
                    {errors.currentPassword}
                  </span>
                )}
              </div>
              <div>
                <Label>New password</Label>
                <Input
                  style={{
                    border:
                      errors.newPassword && errors.newPassword !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                  type="password"
                  value={newPassword}
                  onChange={handleNewPassWordChange}
                  name="newPassword"
                  className="mt-1.5"
                />
                {errors.newPassword && (
                  <span style={{ color: "#fd397a" }}>{errors.newPassword}</span>
                )}
              </div>
              <div>
                <Label>Confirm password</Label>
                <Input
                  style={{
                    border:
                      errors.confirmNewPassword &&
                      errors.confirmNewPassword !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                  type="password"
                  value={confirmNewPassword}
                  onChange={handleconfirmNewPasswordChange}
                  name="confirmNewPassword"
                  className="mt-1.5"
                />
                {errors.confirmNewPassword && (
                  <span style={{ color: "#fd397a" }}>
                    {errors.confirmNewPassword}
                  </span>
                )}
              </div>
              {isAlertMessageDisplay === false ? (
                <div className="mt-6 w-full">
                  <AlertMessage
                    message={`Your current password is not correct. Please try again.`}
                  />
                </div>
              ) : (
                ""
              )}

              {isSpecialCharMessageDisplay === true ? (
                <div className="mt-6 w-full">
                  <AlertMessage
                    message={`Your password must contain at least 7 characters, containing an uppercase letter, a special character and a number.`}
                  />
                </div>
              ) : (
                ""
              )}

              {ispasswordMatchingMessageDisplay === true ? (
                <div className="mt-6 w-full">
                  <AlertMessage
                    message={`Your new password and confirm password do not match. Please try again.`}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="pt-2">
                <ButtonPrimary type="submit">Update password</ButtonPrimary>
              </div>
            </div>
          </form>
          <ModalAccountPassword
            isModalOpen={isModalAccountSave}
            onCloseModal={onModalClose}
            modalBody={accountSaveMessage}
          />
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
