import { ExclamationTriangleIcon, StarIcon } from "@heroicons/react/24/solid";
import { CheckIcon, TrashIcon } from "@heroicons/react/24/outline";
import { BaseProp } from "entities/BaseProp";
import {
  ProductDetailsDTO,
  ProductRatingsDTO,
  ProductReviewItemDataList,
} from "entities/ProductDetailsDTO";
import { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import avtar_review from "images/avatars/avtar_human.png";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Rating from "components/Rating";
import WriteReview from "./WriteReview";
import { getUserLoginData } from "utils/auth";
import axiosInstance from "api/AxiosInstance";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import Heading from "components/Heading/Heading";
import HeadingText1 from "components/HeadingText/HeadingText1";

interface ProductReviewProps extends BaseProp {
  data?: ProductDetailsDTO;
  onLoaderShow?: (isShow: boolean) => void;
  assignParameters: (filterProp: string, filterVal: any) => void;
  isShortDataList?:boolean;
}

const _noofshowReview = 4;

const ProductReview: FC<ProductReviewProps> = ({
  className = "",
  data,
  onLoaderShow,
  assignParameters,
  isShortDataList
}) => {
  const userData = getUserLoginData();
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);
  const [isOpenModalWriteReview, setisOpenModalWriteReview] = useState(false);
  const [isOpenModalRemoveReview, setisOpenModalRemoveReview] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState<number | null>(null);
  const [dataResult, setDataResult] = useState<ProductRatingsDTO | undefined>(
    data?.productRatings
  );
  const [isConfirmReviewDisable, setisConfirmReviewDisable] = useState(false);

  useEffect(() => {
    setDataResult(data?.productRatings);
  }, [data]);

  const showRating = () => {
    return (
      <>
        {dataResult && dataResult.productStars > 0 && (
          <>
            <Rating
              count={dataResult.productStars}
              value={dataResult.productStars}
            />
            <span className="">
              <span className="mx-1">·</span>
              {dataResult.productReviewsList.length}{" "}
              {dataResult.productReviewsList.length > 1 ? "Reviews" : "Review"}
            </span>
          </>
        )}
      </>
    );
  };

  const rendderNoReviewFound = () => {
    return (
      <div className={`nc-ProductReview space-y-14`}>
        <div className="flex flex-col items-center space-y-8 sm:space-y-14">
          <Heading
            className="text-neutral-900 dark:text-neutral-50"
            fontClass="text-base md:text-2xl font-semibold"
            isCenter
            desc=""
          >
            This product does not have any reviews yet.{" "}
            {userData && "Be the first!"}
          </Heading>
          {leaveReviewButton()}
        </div>
      </div>
    );
  };

  const leaveReviewButton = () => {
    return (
      <>
        {userData && (
          <ButtonCSCLed
            className="w-full sm:w-fit"
            onClick={() => setisOpenModalWriteReview(true)}
          >
            Leave a review
          </ButtonCSCLed>
        )}
      </>
    );
  };

  const renderReviews = () => {
    
    return (
      <div
        className={`nc-ProductReview space-y-14 ${className}`}
        id="nc-ProductReview"
      >
        <div id="reviews" className="scroll-mt-[150px]">
          <div>
            <HeadingText1 className="font-poppins">Product Reviews</HeadingText1>
            <div className="flex flex-col sm:flex-row justify-between space-y-6 sm:space-y-0">
              {/* HEADING */}
              <h2 className="text-2xl font-semibold flex items-center">
                {showRating()}
              </h2>
              {leaveReviewButton()}
            </div>
          </div>
          {/* comment */}
          {dataResult && (
            <div className="mt-10">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-14 sm:gap-y-11 sm:gap-x-28">
                {dataResult.productReviewsList
                  .slice(0, _noofshowReview)
                  .map((item, index) => renderReviewItem(item, index))}
              </div>
              {dataResult.productReviewsList.length > _noofshowReview && (
                <ButtonSecondary
                  onClick={() => setIsOpenModalViewAllReviews(true)}
                  className="mt-10 border border-slate-300 dark:border-slate-700 "
                >
                  {`Show me all ${dataResult.productReviewsList.length} reviews`}
                </ButtonSecondary>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderReviewsWithoutContainer = () => {
    
    return (
      <div
        className={`nc-ProductReview pb-24 lg:pb-28 pt-14 space-y-14 ${className}`}
        id="nc-ProductReview"
      >
        <div id="reviews" className="scroll-mt-[150px]">
          <div>
            <HeadingText1 className="font-poppins">Product Reviews</HeadingText1>
            <div className="flex flex-col sm:flex-row justify-between space-y-6 sm:space-y-0">
              {/* HEADING */}
              <h2 className="text-2xl font-semibold flex items-center">
                {showRating()}
              </h2>
              {leaveReviewButton()}
            </div>
          </div>
          {/* comment */}
          {dataResult && (
            <div className="mt-10">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-14 sm:gap-y-11 sm:gap-x-28">
                {dataResult.productReviewsList
                  .slice(0, _noofshowReview)
                  .map((item, index) => renderReviewItem(item, index))}
              </div>
              {dataResult.productReviewsList.length > _noofshowReview && (
                <ButtonSecondary
                  onClick={() => setIsOpenModalViewAllReviews(true)}
                  className="mt-10 border border-slate-300 dark:border-slate-700 "
                >
                  {`Show me all ${dataResult.productReviewsList.length} reviews`}
                </ButtonSecondary>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderReviewItem = (
    review: ProductReviewItemDataList,
    index: number
  ) => {
    return (
      <div
        key={index}
        className="divide-y divide-gray-200 rounded-lg bg-white shadow"
      >
        <div className="px-4 py-5 sm:px-6">
          <div className=" flex space-x-4">
            <div className="flex-1 flex justify-between">
              <div className="text-sm sm:text-base">
                <span className="block font-semibold">{review.raterName}</span>
                <span className="block mt-0.5 dark:text-slate-400 text-sm">
                  {review.createDate}
                </span>
              </div>
              <div className="flex flex-col items-end">
                <div className="mt-0.5 flex text-yellow-500">
                  <Rating count={5} value={review.starRating} />
                </div>
                {review.isDelete && (
                  <div
                    className="mt-2 cursor-pointer"
                    onClick={() => {
                      setReviewToDelete(review.id);
                      setisOpenModalRemoveReview(true);
                    }}
                  >
                    <TrashIcon
                      className="w-5 h-5 text-slate-300"
                      title="Remove my review"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6 text-sm sm:text-base">
          <p className="font-medium">{review.headLine}</p>
          <p className="dark:text-slate-300 text-sm">{review.review}</p>
        </div>
      </div>
    );
  };

  const ModalViewAllReviews = () => {
    return (
      <Transition appear show={isOpenModalViewAllReviews} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => setIsOpenModalViewAllReviews(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-5xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      View all reviews
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose
                        onClick={() => setIsOpenModalViewAllReviews(false)}
                      />
                    </span>
                  </div>
                  <div className="px-8 my-5 flex justify-between flex-wrap">
                    <h2 className="text-xl sm:text-2xl font-semibold flex items-center">
                      {showRating()}
                    </h2>
                    {/* <SortOrderFilter
                  className="my-2"
                  data={[
                    { name: "Sort order" },
                    { name: "Newest rating" },
                    { name: "Highest rating" },
                    { name: "Lowest rating" },
                  ]}
                /> */}
                  </div>
                  <div className="px-8 py-8 border-t border-slate-200 dark:border-slate-700 overflow-auto grid grid-cols-1 md:grid-cols-2 gap-x-7 gap-y-5 sm:gap-x-14 sm:gap-y-10 customScrollBar">
                    {dataResult &&
                      dataResult.productReviewsList.map((item, index) =>
                        renderReviewItem(item, index)
                      )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const ModalRemoveReview = () => {
    const handleConfirmDelete = async () => {
      if (reviewToDelete !== null) {
        setisConfirmReviewDisable(true);
        try {
          const response = await axiosInstance.post(
            `/Home/removeProductReview`,
            {
              reviewId: reviewToDelete,
              productId: data?.inventoryId,
            }
          );
          const responseData = response.data;
          if (responseData.data) {
            assignParameters("productRatings", responseData.data);
          }
          setReviewToDelete(null);
          // Update the review list or perform any additional actions needed
        } catch (error) {
          setisOpenModalRemoveReview(false);
        }
        setisOpenModalRemoveReview(false);
        setisConfirmReviewDisable(false);
      }
    };

    return (
      <Transition appear show={isOpenModalRemoveReview} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setisOpenModalRemoveReview(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-red-600"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Remove my Review
                      </Dialog.Title>
                      <div className="mt-2 pb-5">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to remove your review?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row sm:space-x-2 pt-6">
                    <ButtonPrimary
                      className="sm:!px-7 shadow-none"
                      onClick={handleConfirmDelete}
                      disabled={isConfirmReviewDisable}
                      loading={isConfirmReviewDisable}
                      focusCssClass={false}
                    >
                      Yes please
                    </ButtonPrimary>
                    <ButtonSecondary
                      className="mt-3 sm:mt-0 sm:ml-3"
                      type="button"
                      onClick={() => setisOpenModalRemoveReview(false)}
                    >
                      Cancel
                    </ButtonSecondary>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      {dataResult && dataResult.productStars > 0
        ?  isShortDataList === false ? renderReviews() : renderReviewsWithoutContainer()
        : rendderNoReviewFound()}

      {/****Modal for rating */}
      {ModalViewAllReviews()}
      {isOpenModalWriteReview && data?.inventoryId && (
        <WriteReview
          inventoryId={data.inventoryId}
          shortUserName={data?.loginReviewer}
          show={isOpenModalWriteReview}
          onCloseModal={() => setisOpenModalWriteReview(false)}
          onLoaderShow={onLoaderShow}
          assignParameters={assignParameters}
        />
      )}
      {ModalRemoveReview()}
    </>
  );
};

export default ProductReview;
