import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "utils/Constance";

interface GoogleRecaptchaProps {
  onChange: (value: string | null) => void;
}

export interface GoogleRecaptchaRef {
  reset: () => void;
}

const GoogleRecaptcha: React.ForwardRefRenderFunction<GoogleRecaptchaRef, GoogleRecaptchaProps> = (
  { onChange },
  ref
) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleRecaptchaChange = (value: string | null) => {
    onChange(value);
  };

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    },
  }));

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      id="recaptchaToken"
      className="recaptcha"
      sitekey={config.recaptchaKey}
      onChange={handleRecaptchaChange}
    />
  );
};

export default React.forwardRef<GoogleRecaptchaRef, GoogleRecaptchaProps>(GoogleRecaptcha);

