import axiosInstance from "api/AxiosInstance";
import { BaseApiResponse } from "entities/ApiResponse";
import { BaseProp } from "entities/BaseProp";
import { ContestDTO } from "entities/ContestDTO";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ContestDetail from "./ContestDetail";
import SkeletonContest from "./SkeletonContest";

interface ContestProp extends BaseProp {}

const Contest: FC<ContestProp> = ({ className = "" }) => {
  const [dataResult, setDataResult] =
    useState<BaseApiResponse<ContestDTO> | null>(null);

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/home/getContest")
      .then((response) => {
        setDataResult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { isContestActive, contactPageSetting, occupationList, userFormsList } =
    dataResult?.data || {};

  const renderContestCloseMsg = () => {
    return (
      <div className="container mt-5 lg:mt-11 pb-16 scroll-mt-32">
        <div className="mx-auto lg:mx-0">
          <h2 className="text-2xl font-semibold space-x-2.5">
            <span>
              <i className="fi fi-rr-sad-cry w-6 h-6 text-blue-800"></i>
            </span>
            <span>Our Last Contest Has Wrapped Up.</span>
            <span className="text-sm text-gray-400">Oh, No!</span>
          </h2>
          <div className="flex-col text-sm mt-8 space-y-4 sm:text-base">
            <p>
              There's no easy way to say this, but our last contest has
              concluded.
            </p>
            <p>
              If you feel this is a mistake, an outrage, or perhaps a technical
              glitch, please email{" "}
              <Link
                to={`mailto:marketing@csc-led.com?Subject=Regarding Contest Page`}
              >
                marketing@csc-led.com
              </Link>{" "}
              directly to have your greivances heard.
            </p>
            <p>
              As always, we want to thank you for being our business partner,
              and look forward to running many more contests in the future.
            </p>
            <p>
              Talk soon,
              <br />
              CSC LED Team
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderMetaData = () => {
    return (
      <>
        {contactPageSetting?.metaTitle && (
          <>
            <title>Contest | CSC LED</title>
            <meta name="title" content={contactPageSetting.metaTitle} />
          </>
        )}
        {contactPageSetting?.metaTitle && (
          <meta name="description" content={contactPageSetting?.metaTitle} />
        )}
        {contactPageSetting?.metaKeywords && (
          <meta name="keywords" content={contactPageSetting.metaKeywords} />
        )}
      </>
    );
  };

  const renderBody = () => {
    return (
      <>
        {isContestActive ? (
          <ContestDetail
            occupationList={occupationList || []}
            contactPageSetting={contactPageSetting || null}
            userFormList={userFormsList || []}
          />
        ) : (
          renderContestCloseMsg()
        )}
      </>
    );
  };

  return (
    <div className="nc-ApplyNow relative overflow-hidden">
      <Helmet>{renderMetaData()}</Helmet>
      {dataResult ? renderBody() : <SkeletonContest />}
    </div>
  );
};

export default Contest;
