import React, { FC, useState } from "react";
import NcImage from "shared/NcImage/NcImage";

export interface ImageHoverProps {
  className?: string;
  imagePath: string;
  hoverPath?: string;
  onOpenModal: () => void,
}

const ImageHover: FC<ImageHoverProps> = ({
  className = "",
  imagePath = "",
  hoverPath = "",  
  onOpenModal,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <a
        onClick={() => onOpenModal()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="cursor-pointer"
      >
        <NcImage
          containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
          className="absolute inset-0 object-cover"
          src={isHovered ? hoverPath : imagePath}
        />
      </a>
    </div>
  );
};

export default ImageHover;
