import React, { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axiosInstance from "api/AxiosInstance";
import { Link } from "react-router-dom";

export interface PageForgotPasswordProps {
  className?: string;
}

const PageForgotPassword: FC<PageForgotPasswordProps> = ({
  className = "",
}) => {
  const [Message, SetMessage] = useState("");
  const [IsSuccess,SetIsSuccess] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });
  const [isDisable, setIsDisable] = useState(false);

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    let errorMessage = "";
    switch (id) {
      case "emailAddress":
        errorMessage =
          value.trim() === ""
            ? "The Email field is required."
            : !/^\S+@\S+\.\S+$/.test(value)
            ? "Invalid email address."
            : "";
        break;
      default:
        break;
    }

    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: errorMessage });
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.email.trim()) {
      newErrors.email = "The Email field is required.";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length != 0 ? true : false;
  };

  const handleForgetPassword = async (e: any) => {
    e.preventDefault();
    var isValid = validateForm();
    if (isValid) {
      return;
    }
    try {
      setIsDisable(true);
      // Send data to the API using Axios
      await axiosInstance
        .get("SFRegister/Forgot?Email=" + formData.email)
        .then((response) => {
           if(response.data.isSuccess){
            SetMessage(response.data.message); 
            SetIsSuccess(response.data.isSuccess);
           }
           else if(!response.data.isSuccess && response.data.message == "Limit Exhausted"){
            SetMessage(response.data.message);
            SetIsSuccess(response.data.isSuccess);
           }
           else {
            SetMessage("");
            setErrors({ email: response.data.message });
            SetIsSuccess(response.data.isSuccess);
           }
           setIsDisable(false);
        });
    } catch (error) {
      console.error("API Error:", error);
      setIsDisable(false);
    }
  };

  return (
    <div
      className={`nc-PageForgotPassword ${className}`}
      data-nc-id="PageForgotPassword"
    >
      <Helmet>
        <title>Forgot Password | CSC LED</title>
      </Helmet>
      {IsSuccess && Message == "Success"  && ( <div className="container mb-12 lg:mb-32">
          <h2 className="my-20 mt-10 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] capitalize font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Password Reset Email Sent
          </h2>
          <span
            style={{ marginTop: "-65px" }}
            className="my-20 flex items-center text-1xl leading-[115%] md:text-1xl md:leading-[115%] text-neutral-900 dark:text-neutral-100 justify-center"
          >
            Alright, just one more step to go.
          </span>
          <div className="mx-5">
            <hr
              className="border-t border-gray-300"
              style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
            />
          </div>
          <div className="flex flex-col w-full text-center items-center mb-8 justify-center space-y-8">
            <span>
              You will receive an email containing a link to reset your password
              momentarily.
            </span>
            <span>
              If you have any difficulties, please email
              <Link to={`mailto:info@csc-led.com?Subject=Reset Password`}>
                <strong> info@csc-led.com </strong>
              </Link>
              for direct assistance.
            </span>
          </div>
        </div>)}
        {!IsSuccess && Message == "Limit Exhausted"  && (
          <div className="container mb-12 lg:mb-32">
          <h2 className="my-20 mt-10 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] capitalize font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          forgot your password?
          </h2>
          <div className="mx-5">
            <hr
              className="border-t border-gray-300"
              style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
            />
          </div>
          <div className="flex flex-col w-full text-center items-center mb-8 justify-center space-y-8">
            <span>
              You have reached your forgot password reset limit.<br /><br />
              Please try again after 24 hours or contact info@csc-led.com for assistance.
            </span>
          </div>
        </div>
          
        )}
        
        {!IsSuccess && Message == "" && (
          <div className="container mb-12 lg:mb-32">
              <h2 className="my-20 mt-10 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] capitalize font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                forgot your password?
              </h2>
              <span
                style={{ marginTop: "-65px" }}
                className="my-20 flex items-center text-1xl leading-[115%] md:text-1xl md:leading-[115%] text-neutral-900 dark:text-neutral-100 justify-center"
              >
                No problem, we won't tell anyone.
              </span>
              <div className="mx-5">
                <hr
                  className="border-t border-gray-300"
                  style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
                />
              </div>
              <div className="flex w-full text-center items-center mb-8 justify-center">
                Enter your email address below and we will send you a link so you
                can reset it. No one will ever know this happened.
              </div>
              <div className="max-w-md mx-auto space-y-6">
                <form
                  className="grid grid-cols-1 gap-6"
                  onSubmit={handleForgetPassword}
                  method="post"
                >
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Your Account's Email Address
                    </span>
                    <Input
                      id="email"
                      type="text"
                      className="mt-1"
                      onChange={handleInputChange}
                      value={formData.email}
                      style={{
                        border:
                          errors.email && errors.email != ""
                            ? "1px solid #fd397a"
                            : "",
                      }}
                    />
                    {errors.email && (
                      <span style={{ color: "#fd397a" }}  dangerouslySetInnerHTML={{ __html: errors.email }} />
                      // <span style={{ color: "#fd397a" }}>{errors.email}</span>
                    )}
                  </label>
                  <ButtonPrimary type="submit" loading={isDisable}>
                    Send Password Reset Email
                  </ButtonPrimary>
                </form>
              </div>
          </div>
        )}
    </div>
  );
};

export default PageForgotPassword;
