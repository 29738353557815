import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "api/AxiosInstance";
import { BaseApiResponse } from "entities/ApiResponse";
import { ProductTypeDTO, ProductTypeFilterParams } from "entities/ProductTypeDTO";

export interface productTypeState {
    data: BaseApiResponse<ProductTypeDTO> | null;
    isLoading: boolean;
    error: string | null;
    filter:ProductTypeFilterParams;
  }
  
  const initialState: productTypeState = {
    data: null,
    isLoading: false,
    error: "",
    filter:{}
  };

  export const getProductTypeData = createAsyncThunk("productType", async () => {
    return await axiosInstance.get("/products/getProductType").then((res) => res.data);
  });

  export const productTypeSlice = createSlice({
    name: "productType",
    initialState,
    reducers: {
      setProductFilter: (state, action: PayloadAction<{ key: keyof ProductTypeFilterParams; value: any}>) => {
        state.filter[action.payload.key] = action.payload.value ?? undefined;
      },
      setClearProductFilter: (state) => {
        state.filter={}
      },
    },
    extraReducers(builder) {
      builder.addCase(getProductTypeData.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getProductTypeData.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.data = actions.payload;
        state.error = null;
      });
      builder.addCase(
        getProductTypeData.rejected,
        (state, actions: PayloadAction<any>) => {
          state.isLoading = false;
          state.data = null;
          state.error = actions.payload ?? "An error occurred.";
        }
      );
    },
  });

  export const {setProductFilter,setClearProductFilter} = productTypeSlice.actions;

  export default productTypeSlice.reducer;