import React, { FC, SelectHTMLAttributes } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  sizeClass?: string;
  register?: UseFormRegisterReturn;
  errorMsg?: FieldError;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void; // Add onChange prop
}

const Select: FC<SelectProps> = ({
  className = "",
  sizeClass = "h-11",
  children,
  register,
  errorMsg,
  onChange, // Add onChange prop
  ...args
}) => {
  return (
    <select
      className={`nc-Select ${sizeClass} ${className} block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${
        errorMsg ? "border-red-600 focus:ring-red-200" : ""
      }`}
      {...args}
      {...register}
      onChange={(e) => {
        if (onChange) onChange(e); // Call the onChange prop if it exists
        if (register) {
          register.onChange(e); // Call the onChange method from register
        }
      }}
    >
      {children}
    </select>
  );
};

export default Select;
