import * as CryptoJS from 'crypto-js';
import { config } from './Constance';


export const encryptData = (data: any) => {
  const secretKey = config.Encryption.EncryptionKey;
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  return ciphertext;
};

export const decryptData = (ciphertext: string) => {
  const secretKey = config.Encryption.EncryptionKey;
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return originalData;
};

export const encryptAES = (plainText: any,key:string) => {
  // Generate a 256-bit key from the original key
  const hashedKey = CryptoJS.SHA256(key);
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV

  // Encrypt the plaintext
  const encrypted = CryptoJS.AES.encrypt(plainText, hashedKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });

  // Combine IV and encrypted data
  const encryptedTextWithIV = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);

  return encryptedTextWithIV;
};

export const decryptAES=(encryptedText: string, key: string)=> {
  // Convert the key to a 256-bit key using SHA256
  const hashedKey = CryptoJS.SHA256(key);

  const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedText);

  // Extract the IV from the beginning of the encrypted bytes (first 16 bytes)
  const iv = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(0, 4), 16);

  // Extract the actual cipher text (remaining bytes)
  const cipherText = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(4), encryptedBytes.sigBytes - 16);

  // Create a CipherParams object with the cipher text and IV
  const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: cipherText,
      iv: iv
  });

  // Decrypt the cipher text
  const decrypted = CryptoJS.AES.decrypt(cipherParams, hashedKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}