import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import axiosInstance from "api/AxiosInstance";
import Label from "components/Label/Label";
import Rating from "components/Rating";
import InputComponent from "containers/Careers/InputComponent";
import { BaseProp } from "entities/BaseProp";
import { ProductRatingsDTO } from "entities/ProductDetailsDTO";
import React, { FC, Fragment, useState } from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Textarea from "shared/Textarea/Textarea";
import * as yup from "yup";

interface ReviewDataType {
  productId: number;
  reviewRating: number;
  reviewHeadline: string;
  reviewCustomerReview: string;
}

interface WriteReviewProp extends BaseProp {
  inventoryId: number;
  shortUserName?: string;
  show: boolean;
  onCloseModal: () => void;
  onLoaderShow?: (isShow: boolean) => void;
  assignParameters: (filterProp: string, filterVal: any) => void;
}

// Define the validation schema
const schema = yup.object().shape({
  reviewRating: yup.number().min(1, "Please provide a rating for your review."),
  reviewHeadline: yup
    .string()
    .transform((value) => (typeof value === "string" ? value.trim() : value))
    .required("Please provide a headline for your review.")
    .max(55, "Maximum 55 characters are allowed."),
  reviewCustomerReview: yup
    .string()
    .transform((value) => (typeof value === "string" ? value.trim() : value))
    .required("Please provide at least a couple words for your review.")
    .max(300, "Maximum 300 characters are allowed."),
});

const WriteReview: FC<WriteReviewProp> = ({
  className = "",
  inventoryId,
  shortUserName = "User.",
  show,
  onCloseModal,
  onLoaderShow,
  assignParameters,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    trigger,
  } = useForm<ReviewDataType>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      reviewRating: 0,
      reviewHeadline: "",
      reviewCustomerReview: "",
    },
  });
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);

  const onSubmit: SubmitHandler<ReviewDataType> = async (data) => {
    data.productId = inventoryId;
    setIsSubmitDisable(true);
    const response = await axiosInstance
      .post("/Home/addProductReview", data)
      .then((response) => {
        assignParameters("productRatings", response.data.data);
        onCloseModal();
        setIsSubmitDisable(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitDisable(false);
      });
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={onCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block py-8 h-screen sm:h-auto w-full max-w-3xl ${className}`}
              >
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Leave a Review
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={onCloseModal} />
                    </span>
                  </div>
                  <div className="px-8 py-8 border-t border-slate-200 dark:border-slate-700 overflow-auto">
                    <div id="ReviewForm" className="">
                      <form className="" onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-1 gap-6">
                          <div>
                            <Label className="text-base font-medium text-neutral-900 dark:text-neutral-200 mb-3">
                              Rating (more stars is better)
                            </Label>
                            <Controller
                              name="reviewRating"
                              control={control}
                              render={({ field }) => (
                                <Rating
                                  count={5}
                                  value={field.value}
                                  edit={true}
                                  onChange={(value) => {
                                    setValue("reviewRating", value);
                                    trigger("reviewRating"); // Trigger validation
                                  }}
                                  size={28}
                                  className="flex mt-2"
                                />
                              )}
                            />
                            {errors.reviewRating && (
                              <p className="text-red-500 text-xs mt-1">
                                {errors.reviewRating.message}
                              </p>
                            )}
                          </div>
                          <InputComponent
                            labelTextName="Review Headline"
                            autoComplete="Off"
                            register={register("reviewHeadline")}
                            errorMsg={errors.reviewHeadline}
                            placeholder="What's the most important thing to know?"
                          />
                          <div>
                            <Label className="text-base font-medium text-neutral-900 dark:text-neutral-200">
                              Your Review
                            </Label>
                            <Textarea
                              placeholder="What did you like or dislike about the product? What did you use the product for?"
                              className={`mt-1.5 ${
                                errors.reviewCustomerReview &&
                                "border-red-600 focus:ring-red-200"
                              }`}
                              {...register("reviewCustomerReview")}
                              autoComplete="Off"
                              maxLength={300}
                              onChange={(e) =>
                                setTextAreaCount(e.target.value.length)
                              }
                            />
                            {errors.reviewCustomerReview && (
                              <p className="text-red-500 text-xs mt-1">
                                {errors.reviewCustomerReview.message}
                              </p>
                            )}
                            <p className="text-gray-500 text-xs mt-1 flex justify-end">
                              <span>{textAreaCount}</span>
                              <span>{`/300`}</span>
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col mt-6">
                          <span className="text-sm">
                            {`Please Note: Your account's name `}
                            {shortUserName ? (
                              <span className="font-bold">{shortUserName}</span>
                            ) : null}
                            {` will appear publicly with your review. If your review contains offensive language, it will be removed at our discretion.`}
                          </span>
                          <div className="flex flex-col sm:flex-row sm:space-x-2 pt-6">
                            <ButtonPrimary
                              loading={isSubmitDisable}
                              disabled={isSubmitDisable}
                              className="sm:!px-7 shadow-none"
                            >
                              Post Review
                            </ButtonPrimary>
                            <ButtonSecondary
                              className="mt-3 sm:mt-0 sm:ml-3"
                              type="button"
                              onClick={onCloseModal}
                            >
                              Cancel
                            </ButtonSecondary>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default WriteReview;
