import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

interface CSCProductCardProp {
  className?: string;
  name: string;
  category?: string;
  description?: string;
  image: string | undefined;
  link: string;
  status?: string;
  linkTarget?: string;
  onClick?: () => void; // Define onClick prop
}

const CSCProductCard: FC<CSCProductCardProp> = ({
  className = "",
  name,
  category,
  description,
  image,
  link,
  status,
  linkTarget = "_blank",
  onClick,
}) => {
  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-white dark:text-white";
    if (status === "Clearance") {
      return (
        <div className={`${CLASSES} bg-red-600`}>
          <CheckBadgeIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">CLEARANCE</span>
        </div>
      );
    }
    if (status === "End of Life") {
      return (
        <div className={`${CLASSES} bg-cyan-500`}>
          <i className="fi fi-rr-clock-three w-3.5 h-3.5"></i>
          <span className="ml-1 leading-none">{`WHILE SUPPLIES LAST`}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`nc-ProductCard relative flex flex-col bg-transparent group ${className}`}
      data-nc-id="ProductCard"
    >
      <div
        className="relative flex-shrink-0 bg-white dark:bg-slate-300 rounded-3xl overflow-hidden z-1  group-hover:-translate-y-2 transition-all duration-400 
      shadow group-hover:shadow-lg group-hover:shadow-slate-400"
      >
        <Link target={linkTarget} to={link} className="block" onClick={onClick}>
          <NcImage
            containerClassName="flex aspect-w-10 aspect-h-7 w-full h-0"
            src={image || undefined}
            className="object-contain w-full h-full group-hover:scale-110 transform transition-transform duration-300"
          />
        </Link>
        {renderStatus()}
      </div>

      <div className="space-y-4 px-2.5 pt-5 pb-2.5">
        <div>
          <Link
            target={linkTarget}
            to={link}
            className="block"
            onClick={onClick}
          >
            <h2
              className={`nc-ProductCard__title text-base sm:text-lg font-semibold transition-colors group-hover:text-csccolor`}
            >
              {name}
            </h2>
          </Link>
          {category && (
            <h3
              className={`nc-ProductCard__title text-base font-semibold transition-colors`}
            >
              {category}
            </h3>
          )}
          {description && (
            <p className={`text-sm text-black dark:text-slate-400 mt-1`}>
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CSCProductCard;
