import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { config } from "utils/Constance";

export interface RewardsShopModalQuickViewProps {
  show: boolean;
  image_url: string;
  onCloseModalQuickView: () => void;
}

const RewardsShopModalQuickView: FC<RewardsShopModalQuickViewProps> = ({
  show,
  image_url,
  onCloseModalQuickView,
}) => {
    
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={onCloseModalQuickView}
      >
        <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 w-full h-full">
              <div
                className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
              dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
              >
                <span className="absolute right-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalQuickView} />
                </span>

                <div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar flex justify-center items-center">
                <div className="bg-white rounded-lg p-4 object-cover object-center">
                    <img
                    src={`${config.url.AWS_S3_URL}${image_url}`}
                    className="max-w-full max-h-full w-auto h-auto"
                    />
               </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RewardsShopModalQuickView;
