import { yupResolver } from "@hookform/resolvers/yup";
import axiosInstance from "api/AxiosInstance";
import PageLoader from "components/CSCLedLoader/PageLoader";
import ErrorMessage from "components/ErrorMessage";
import GoogleRecaptcha,{ GoogleRecaptchaRef }  from "components/GoogleRecaptcha";
import Label from "components/Label/Label";
import SuccessMessage from "components/SuccessMessage";
import InputComponent from "containers/Careers/InputComponent";
import { BaseProp } from "entities/BaseProp";
import React, { FC, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import AppendFormData from "utils/AppendFormData";
import handleScrollToEl from "utils/HandleScrollToEl";
import * as yup from "yup";

interface ContactFormData {
  howCanWeHelp: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  companyName: string;
  branchLocation: string;
  subject: string;
  attachment?: FileList | null;
  description: string;
  orderNo?: number;
  siteName: string;
}

interface ContactusFormProp extends BaseProp {
  location: string;
}

// Define the validation schema
const schema = yup.object().shape({
  firstName: yup
    .string()
    .transform((value) => (typeof value === "string" ? value.trim() : value))
    .matches(/^[A-Za-z ]*$/, "First name must contain only letters and spaces.")
    .required("First name is required.")
    .max(30, "Maximum 30 characters are allowed."),
  lastName: yup
    .string()
    .transform((value) => (typeof value === "string" ? value.trim() : value))
    .matches(/^[A-Za-z ]*$/, "Last name must contain only letters and spaces.")
    .required("Last name is required.")
    .max(30, "Maximum 30 characters are allowed."),
  email: yup
    .string()
    .transform((value) => (typeof value === "string" ? value.trim() : value))
    .email("Invalid email.")
    .required("Email is required.")
    .max(70, "Maximum 70 characters are allowed."),
  subject: yup
    .string()
    .transform((value) => (typeof value === "string" ? value.trim() : value))
    .required("Subject is required."),
  description: yup
    .string()
    .transform((value) => (typeof value === "string" ? value.trim() : value))
    .required("Description is required.")
    .max(500, "Maximum 500 characters are allowed."),
  orderNo: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === "") return undefined;
      return isNaN(originalValue) ? "NaN" : Number(originalValue);
    })
    .typeError("Order number must be a valid number."),
});

const ContactUsForm: FC<ContactusFormProp> = ({ className = "", location }) => {
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isConfirmMsg, setConfirmMsg] = useState<boolean>(false);
  const [isTechnicalHelp, setIsTechnicalHelp] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const recaptchaRef = useRef<GoogleRecaptchaRef>(null);

  // Use the useForm hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<ContactFormData>({
    resolver: yupResolver(schema) as any,
  });

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaToken(value);
  };

  const handleRecaptchaReset = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const scrollToErrorMsg = (value: string) => {
    setErrorText(value);
    handleScrollToEl("ContactForm");
  };

  const onSubmit: SubmitHandler<ContactFormData> = async (data) => {
    if (!recaptchaToken) {
      scrollToErrorMsg(
        "Please indicate you are not a robot using the captcha below, and resubmit the form."
      );
      return;
    }

    data.siteName = location;
    let formData = AppendFormData(data);
    setIsPageLoading(true)
    const response = await axiosInstance
      .post("/Home/contactRequest", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          GRecAuthorization: `Bearer ${recaptchaToken}`,
        },
      })
      .then((response) => {
        setIsPageLoading(false)
        setConfirmMsg(response.data as boolean);
        setErrorText(null);
        handleScrollToEl("ContactForm");
      })
      .catch((error) => {
        setIsPageLoading(false)
        if (error.response.status === 401) {
          scrollToErrorMsg(
            `Please indicate you are not a robot using the captcha below, and resubmit the form.`
          );
        } else if (typeof error.response.data.message === "string") {
          scrollToErrorMsg(error.response.data.message);
          handleRecaptchaReset();
        } else {
          scrollToErrorMsg("Something went wrong.");
        }
      });
  };

  const toggleOrderInput = (value: string) => {
    setValue("orderNo", undefined);
    setIsTechnicalHelp(value === "3");
  };

  const renderConytactForm = () => {
    return (
      <form
        className="grid grid-cols-1 gap-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        {errorText && (
          <div id="ErrorMessage" className="scroll-mt-32">
            <ErrorMessage errorTitle={errorText} />
          </div>
        )}

        <div>
          <Label>How can we help?</Label>
          <Select
            className="mt-1.5"
            register={register("howCanWeHelp")}
            onChange={(e) => toggleOrderInput(e.target.value)}
          >
            <option value="1">I have general question.</option>
            <option value="2">I would like to place an order.</option>
            <option value="3">I need some technical support.</option>
          </Select>
        </div>
        <InputComponent
          labelTextName="Email"
          register={register("email")}
          errorMsg={errors.email}
          autoComplete="Off"
        />
        <InputComponent
          labelTextName="First Name"
          register={register("firstName")}
          errorMsg={errors.firstName}
          autoComplete="Off"
        />
        <InputComponent
          labelTextName="Last Name"
          register={register("lastName")}
          errorMsg={errors.lastName}
          autoComplete="Off"
        />
        <InputComponent
          labelTextName="Phone Number"
          register={register("phoneNo")}
          errorMsg={errors.phoneNo}
          mask="(999) 999-9999"
          autoComplete="Off"
        />
        <InputComponent
          labelTextName="Company Name"
          register={register("companyName")}
          errorMsg={errors.companyName}
          autoComplete="Off"
        />
        <InputComponent
          labelTextName="Branch Location"
          register={register("branchLocation")}
          errorMsg={errors.branchLocation}
          autoComplete="Off"
        />
        <InputComponent
          labelTextName="Subject"
          register={register("subject")}
          errorMsg={errors.subject}
          autoComplete="Off"
        />
        <div>
          <Label className="text-base font-medium text-neutral-900 dark:text-neutral-200">
            Description
          </Label>
          <Textarea
            className={`mt-1.5 ${
              errors.description && "border-red-600 focus:ring-red-200"
            }`}
            {...register("description")}
            autoComplete="Off"
          />
          {errors.description && (
            <p className="text-red-500 text-xs mt-1">
              {errors.description.message}
            </p>
          )}
        </div>
        {isTechnicalHelp && (
          <InputComponent
            labelTextName="Order Number"
            register={register("orderNo")}
            errorMsg={errors.orderNo}
            autoComplete="Off"
          />
        )}
        <InputComponent
          placeholder=""
          type={"file"}
          labelTextName="Attachment"
          register={register("attachment")}
          errorMsg={errors.attachment}
          autoComplete="Off"
        />
        <GoogleRecaptcha ref={recaptchaRef} onChange={handleRecaptchaChange} />
        <div className="flex flex-col sm:flex-row pt-6">
          <ButtonPrimary
            className="sm:!px-7 shadow-none"
          >
            Send
          </ButtonPrimary>
          <ButtonSecondary className="mt-3 sm:mt-0 sm:ml-3" href="/contact-us">
            Cancel
          </ButtonSecondary>          
        </div>
      </form>
    );
  };

  return (
    <div id="ContactForm" className="w-full scroll-mt-32">
      {isConfirmMsg ? (
        <SuccessMessage headerTitle="Message Received" bodyMessage={`Your message has been received and we will be in touch as soon as possible.<br><br>The CSC LED Team`} />
      ) : (
        renderConytactForm()
      )}
      <PageLoader isLoading={isPageLoading}/>
    </div>
  );
};

export default ContactUsForm;
