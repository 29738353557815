import Label from "components/Label/Label";
import React, { FC, InputHTMLAttributes } from "react";
import Input from "shared/Input/Input";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import InputMask from 'react-input-mask';

interface InputComponentProp extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  labelTextName: string;
  errorMsg?: FieldError;
  defaultValue?: any;
  register: UseFormRegisterReturn;
  mask?:string;
}

const InputComponent: FC<InputComponentProp> = ({
  className = "",
  labelTextName,
  errorMsg,
  register,
  ...arg
}) => {
  const fileUploader = () => {
    return (
      <input
        className={`file:mr-5 file:py-2.5 file:px-6 hover:file:cursor-pointer file:bg-slate-900 file:text-slate-50 
                             mt-1.5 block w-full text-sm text-gray-900 border border-neutral-200 rounded-2xl cursor-pointer bg-gray-50 
                             dark:text-gray-400 focus:outline-none dark:placeholder-gray-400 dark:bg-black ${
                                errorMsg &&
                               "border-red-600 focus:ring-red-200"
                             }`}
        {...register}
        {...arg}
      />
    );
  };

  const inputTextbox = () => {
    return (
      <Input
        className={`mt-1.5 ${
          errorMsg && "border-red-600 focus:ring-red-200 dark:border-red-600 dark:focus:ring-red-200"
        }`}
        defaultValue={arg.defaultValue}
        {...register}
        {...arg}
      />
    );
  };

  const phoneInput = () => {
    return (
      <InputMask
        mask="(999) 999-9999"
        className={`block w-full border border-neutral-200 
          bg-white focus:outline-none focus:ring focus:ring-primary-200 focus:ring-opacity-50
          rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5 ${
          errorMsg && "border-red-600 focus:ring-red-200 dark:border-red-600 dark:focus:ring-red-200"
        }`}
        defaultValue={arg.defaultValue}
        {...register}
        {...arg}
      />
    );
  };

  return (
    <div>
      <Label className="text-base font-medium text-neutral-900 dark:text-neutral-200">
        {labelTextName}
      </Label>
     {arg.type === "file" ? fileUploader():arg.mask == null ? inputTextbox():phoneInput()}
      {errorMsg && (
        <span className="text-red-500 text-xs">{errorMsg.message}</span>
      )}
    </div>
  );
};

export default InputComponent;
