import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { protectedRoutes } from "./protectedRoutes";
import { useUserState } from "hooks/useUserState";


const PrivateRoute = () => {
  const isAuthenticated = useAppSelector((state: RootState) => state.auth.isAuthenticated);
  const location = useLocation();
  const _userStateConfig = useUserState();
 
  const isProtectedRoute = protectedRoutes.some(route => location.pathname.match(new RegExp(`^${route.replace(/:[^\s/]+/, '[^/]+')}$`)));
  if (isProtectedRoute && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname  }} />;
    //return <Navigate to="/login" />;
  }

  /**Admin user configuration */
  if (location.pathname === "/account-change-password" && _userStateConfig?.isViewChangePassword) {
    return <Navigate to="/" state={{ from: location.pathname  }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
