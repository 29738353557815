import axiosInstance from "api/AxiosInstance";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { BaseProp } from "entities/BaseProp";
import { SubstituteClearanceItem } from "entities/ProductDetailsDTO";
import React, { FC, useState } from "react";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import Input from "shared/Input/Input";
import { useAppDispatch } from "../../store/hooks";
import { getCartData } from "../../store/slices/cartSlice";
import { getCartListData } from "../../store/slices/cartDetailListSlice";
import { getCartIndexListData } from "store/slices/cartIndexListSlice";

interface Props extends BaseProp {
  item: SubstituteClearanceItem;
  min?: number;
  max?: number;
  defaultValue?: number;
  onShowAddToCartModal: (isHide: boolean) => void;
  setAddToCartMessage: (successMsg: any) => void;
  setIsModalWarningMessage: (isHide: boolean) => void;
}

const AddToCartComponent: FC<Props> = ({
  className = "",
  item,
  min = 1,
  max = 500,
  defaultValue=1,
  onShowAddToCartModal,
  setAddToCartMessage,
  setIsModalWarningMessage,
}) => {
  const [isCartButtonDisable, setIsCartButtonDisable] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const dispatch = useAppDispatch();  

  const addToCart = async (item: SubstituteClearanceItem) => {    
    if (isNaN(value) || value <= 0) {
      setIsModalWarningMessage(true);
      return;
    }
    setIsCartButtonDisable(true);
    var obj = {
      productId: item.productId,
      qty: value,
      productValue: item.price,
    };
    const response = await axiosInstance
      .post("/Cart/saveProductToCart", obj)
      .then((response) => {        
        setAddToCartMessage(response.data.data);
        onShowAddToCartModal(true);
        dispatch(getCartData());
        dispatch(getCartListData());
        dispatch(getCartIndexListData());
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setIsCartButtonDisable(false);
  };

  const handleInputChange = (event:any) => {
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && (inputValue >= min) && (!max || inputValue <= max)) {
      setValue(inputValue);
    } else if(Number.isNaN(inputValue)){
      setValue(0);
    }
  };

  return (
    <div
      key={`${item.productId}`}
      className={`flex flex-row space-x-2 py-3 ${className}`}
    >
      <Input
        className="select-none text-center leading-none w-14"
        type="text"
        value={value}
        onChange={handleInputChange}
      />
      <ButtonCSCLed
        loading={isCartButtonDisable}
        disabled={isCartButtonDisable}
        className="w-full"
        onClick={() => addToCart(item)}
      >
        {!isCartButtonDisable ? <BagIcon className="w-5 h-5 mb-0.5" />: <span></span>}
      </ButtonCSCLed>
    </div>
  );
};

export default AddToCartComponent;
