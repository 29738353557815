import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { config } from "utils/Constance";
import warehouse from "images/site/Warehouse_Pic.png"

interface OurPurposeContainerProps {
  className?: string;
}

const OurPurposeContainer: FC<OurPurposeContainerProps> = ({
  className = "",
}) => {

  const svgSizeClass=`h-[70px] w-[70px] sm:h-[100px] sm:w-[100px]`;

  return (
    <div className={`nc-OurPurpose ${className}`}>
      <Heading className="mb-12 text-neutral-900 dark:text-neutral-50 !font-poppins">Our Purpose</Heading>
      <div className="flex flex-col lg:flex-row">
        <div className="space-y-8">
          <div className="bg-custom-neutral-grey rounded-xl overflow-hidden z-0 border border-slate-200 
          dark:border-slate-700 
          halloween:border-white">
            <div className="flex flex-row sm:flex-row p-6 items-center space-x-5">
              <span className="">
                <svg
                  className={svgSizeClass}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#034c9e"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                      fill="#034c9e"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                      fill="#034c9e"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </span>
              <div className="sm:ml-8 !font-poppins">
                <h3 className="font-semibold text-slate-700 dark:text-slate-300 flex ">
                  <span className="uppercase tracking-tight">Our Vision</span>
                </h3>
                <div className=" mt-4 text-sm sm:text-base">
                  <span className="text-black">
                    To be the best and most reliable business partner your
                    company has.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-custom-neutral-grey rounded-xl overflow-hidden z-0 border border-slate-200 
          dark:border-slate-700 
          halloween:border-white">
            <div className="flex flex-row sm:flex-row p-6 items-center space-x-5">
              <span className="">
                <svg
                  className={svgSizeClass}
                  xmlns="http://www.w3.org/2000/svg"                 
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="3"
                      y="3"
                      width="18"
                      height="18"
                      rx="1"
                    />
                    <path
                      d="M11,11 L11,13 L13,13 L13,11 L11,11 Z M10,9 L14,9 C14.5522847,9 15,9.44771525 15,10 L15,14 C15,14.5522847 14.5522847,15 14,15 L10,15 C9.44771525,15 9,14.5522847 9,14 L9,10 C9,9.44771525 9.44771525,9 10,9 Z"
                      fill="#034c9e"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="5"
                      y="5"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="5"
                      y="9"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="5"
                      y="13"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="9"
                      y="5"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="13"
                      y="5"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="17"
                      y="5"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="17"
                      y="9"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="17"
                      y="13"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="5"
                      y="17"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="9"
                      y="17"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="13"
                      y="17"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                    <rect
                      fill="#034c9e"
                      opacity="0.3"
                      x="17"
                      y="17"
                      width="2"
                      height="2"
                      rx="0.5"
                    />
                  </g>
                </svg>
              </span>
              <div className="sm:ml-8 !font-poppins">
                <h3 className="font-semibold text-slate-700 dark:text-slate-300 flex ">
                  <span className="uppercase tracking-tight">Our Mission</span>
                </h3>
                <div className=" mt-4 text-sm sm:text-base">
                  <span className="text-black">
                    To support our business partners in the industrial and
                    commercial construction sector with persistent communication
                    and collaboration.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 halloween:border-white my-5 lg:my-0 lg:mx-10 xl:lg:mx-10 2xl:mx-10 "></div>
        <div className="w-full lg:w-[34%]">
          <NcImage
            containerClassName="rounded-2xl"
            className="w-full h-full rounded-2xl"
            src={warehouse}
          />
        </div>
      </div>
    </div>
  );
};

export default OurPurposeContainer;
