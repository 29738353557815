import React from "react";
import {
  GST_5_SetTaxCodes,
  GST_QSTQC9_975_SetTaxCodes,
  HST_NB_NS_NF_SetTaxCodes,
  HST_PEI_SetTaxCodes,
  HST_ON_SetTaxCodes,
} from "utils/configration";

// Assuming these are your enum mappings
const TaxCodeEnum = {
  GST_5: "GST 5%",
  GSTQSTQC9_975: "GST/QST QC 9.975%",
  HST_NB_NS_NF: "HST (NB/NS/NF)",
  HST_PEI: "HST (PEI)",
  HST_ON: "HST ON",
  HST_BC:"HST BC"
};

// Function to simulate GetEnumDisplayName
const getEnumDisplayName = (taxCode: keyof typeof TaxCodeEnum): string => {
  return TaxCodeEnum[taxCode];
};

const getTaxCodeDisplayName = (shippingCode: string): string => {
  if (GST_5_SetTaxCodes.includes(shippingCode)) {
    return getEnumDisplayName("GST_5");
  } else if (GST_QSTQC9_975_SetTaxCodes.includes(shippingCode)) {
    return getEnumDisplayName("GSTQSTQC9_975");
  } else if (HST_NB_NS_NF_SetTaxCodes.includes(shippingCode)) {
    return getEnumDisplayName("HST_NB_NS_NF");
  } else if (HST_PEI_SetTaxCodes.includes(shippingCode)) {
    return getEnumDisplayName("HST_PEI");
  } else if (HST_ON_SetTaxCodes.includes(shippingCode)) {
    return getEnumDisplayName("HST_ON");
  } else if (HST_ON_SetTaxCodes.includes(shippingCode)) {
    return getEnumDisplayName("HST_BC");
  } 
  else {
    return "Tax TBD";
  }
};

// React component example
interface TaxCodeComponentProps {
  shippingCode: string;
}

const TaxCodeComponent: React.FC<TaxCodeComponentProps> = ({
  shippingCode,
}) => {
  const taxCodeDisplayName = getTaxCodeDisplayName(shippingCode);

  return (
    <div>
      <p>{taxCodeDisplayName}</p>
    </div>
  );
};

export default TaxCodeComponent;