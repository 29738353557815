import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/solid";
import { BaseFilter } from "entities/BaseEntities";
import { BaseProp } from "entities/BaseProp";
import { BreadcrumbsParams } from "entities/BreadcrumbsParams";
import { FilterItems } from "entities/ProductsDTO";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { stringToSlug } from "utils/stringToSlug";

interface BreadcrumbsProp extends BaseProp, BreadcrumbsParams {
  filterItems?: FilterItems;
}

const Breadcrumbs: FC<BreadcrumbsProp> = ({
  className = "block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400 halloween:text-black",
  department,
  productType,
  productSeries,
  filterItems,
}) => {
  const {
    departmentList = [],
    productSeriesList = [],
    productTypeList = [],
  } = filterItems || {};
  const [crubObj, setcrubObj] = useState<BreadcrumbsParams>({
    department: department,
    productType: productType,
    productSeries: productSeries,
  });

  useEffect(() => {
    getBreadcrumb("department", department, departmentList);
    getBreadcrumb("productType", productType, productTypeList);
    getBreadcrumb("productSeries", productSeries, productSeriesList);
  }, [filterItems]);

  const getBreadcrumb = (
    key: keyof BreadcrumbsParams,
    value: string | null | undefined,
    filterArr: BaseFilter[]
  ) => {
    if (value) {
      updateCrubObj(key, value);
      return;
    }
    if (filterArr && filterArr.length == 1) {
      updateCrubObj(key, filterArr[0]?.filterName || "");
      return;
    }
    updateCrubObj(key, "");
  };

  const updateCrubObj = (
    key: keyof BreadcrumbsParams,
    value: string | null
  ) => {
    setcrubObj((prev) => ({ ...prev, [key]: value }));
  };

  const renderLink = (key: string) => {
    var link = "/";
    switch (key) {
      case "department":
        link = `/product-type${`/${crubObj.department?.toLocaleLowerCase()}`}`;
        break;
      case "productType":
        link = `/product-series/${stringToSlug(crubObj.productType ?? "")}`;
        break;
      case "productSeries":
        link = `/products?pser=${crubObj.productSeries}`;
        break;
    }
    return link;
  };

  const renderBody = () => {
    const validValues = Object.entries(crubObj).filter(
      ([key,value]) => value !== undefined && value !== null && value !== ""
    );
    if (validValues.length == 0) {
      return <></>;
    }

    return (
      <nav
        aria-label="Breadcrumb"
        className={`nc-Breadcrumbs flex ${className}`}
        id="nc-Breadcrumbs"
      >
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <Link to="/" className="hover:text-csccolor">
                <HomeIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0"
                />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {Object.entries(crubObj).map(([key, value]) =>
            value ? (
              <li key={key}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="h-5 w-5 flex-shrink-0"
                  />
                  <Link
                    className="ml-4 text-sm font-medium hover:text-csccolor"
                    to={renderLink(key)}
                  >
                    {value}
                  </Link>
                </div>
              </li>
            ) : null
          )}
        </ol>
      </nav>
    );
  };

  return renderBody();
};

export default Breadcrumbs;
