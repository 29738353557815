import axios, { AxiosInstance } from "axios";
import { getAccessToken, getOrCreateDeviceId, getRefreshToken, setTokens } from "utils/auth";
import { config } from "utils/Constance";
import { encryptAES } from "utils/Encryption";
import { navigateToLogin } from "utils/eventEmitter";

// Select the appropriate base URL based on the environment
let baseURL = config.url.API_URL;
let apiKeyHeader=config.apiKey.headerName;
let apiaccessKey=config.apiKey.clientid;
let secretKey=config.apiKey.clientsecret;
const _deviceFingerPrint = getOrCreateDeviceId();
const _fingerPrintHeaderName=`Device-Fingerprint`;

// Create an instance of Axios with the selected base URL
const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseURL,
});

let isRefreshing = false;
let refreshSubscribers: any[] = [];

function onRrefreshed(token: string) {
  refreshSubscribers.map((callback) => callback(token));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback: (token: string) => void) {
  refreshSubscribers.push(callback);
}

axiosInstance.interceptors.request.use(
  (config) => {
    if (_deviceFingerPrint) {
      config.headers[_fingerPrintHeaderName] = _deviceFingerPrint;
    }
    const token = getAccessToken();
    config.headers[apiKeyHeader] = encryptAES(apiaccessKey,secretKey);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // Create a promise that resolves once the token is refreshed
        return new Promise((resolve) => {
          addRefreshSubscriber((token: string) => {
            // Update the Authorization header and resolve the original request
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        //Call Refresh token if 401 error occur.
        const response = await axios.post(
          `${baseURL}SFRegister/auth/request-refreshToken`,
          {
            refreshToken: encryptAES(getRefreshToken(),secretKey),
          },{
            headers: {
              [`${apiKeyHeader}`]:encryptAES(apiaccessKey,secretKey),
              ...(!!_deviceFingerPrint && { [`${_fingerPrintHeaderName}`]: _deviceFingerPrint }),
            },
          }
        );
        const newAccessToken = response.data.access_token;
        const refreshToken = response.data.refresh_token;

        // Update the tokens in the store
        setTokens(newAccessToken, refreshToken);

        isRefreshing = false;
        onRrefreshed(newAccessToken);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        isRefreshing = false;
        console.error("Token refresh failed:", err);
        navigateToLogin();
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
