import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getContactUs } from "store/slices/contactUsSlice";
import ContactUsRawHTML from "./ContactUsRawHTML";
import ContactUsForm from "./ContactUsForm";
import ContactHeader from "./ContactHeader";
import ContactUsSkeleton from "./ContactUsSkeleton";
import { BaseProp } from "entities/BaseProp";
import Divider from "components/Divider";

interface ContactUsDetailProp extends BaseProp {
}

const ContactUsDetail: FC<ContactUsDetailProp> = ({ className = "" }) => {
  const { pageid } = useParams<{ pageid: string }>();

  const dispatch = useAppDispatch();
  const response = useAppSelector((state) => state.contactUs.data);

  useEffect(() => {
    if (!response) {
      dispatch(getContactUs());
    }
  }, [dispatch, response]);

  const selectedBranch = response?.data.find((a) => a.systemName === pageid);

  const renderBranchDetail = () => {
    return (
      <>
        <div className="">
          <h2 className="block text-2xl sm:text-3xl font-medium">
            {selectedBranch?.metaTitle}
          </h2>
        </div>
        <Divider/>
        <div
          className="flex flex-col sm:flex-row sm:space-x-14"          
        >
          <div className="flex-shrink-0 grow max-w-md space-y-8">
            {selectedBranch?.pageHtml && (
              <ContactUsRawHTML rawHtml={selectedBranch.pageHtml} />
            )}
          </div>
          <div className="my-8 sm:hidden"><Divider/></div>
          {selectedBranch?.systemName && (
            <ContactUsForm location={selectedBranch?.systemName} />
          )}
        </div>
      </>
    );
  };

  const renderMetaData = () => {
    return (
      <>
        {selectedBranch?.metaTitle && (
          <>
            <title>{selectedBranch?.metaTitle} | CSC LED</title>
            <meta name="title" content={selectedBranch?.metaTitle} />
          </>
        )}
        {selectedBranch?.metaDescription && (
          <meta name="description" content={selectedBranch?.metaDescription} />
        )}
        {selectedBranch?.metaKeyWords && (
          <meta name="keywords" content={selectedBranch.metaKeyWords} />
        )}
      </>
    );
  };

  return (
    <div
      className={`nc-ContactUsDetail overflow-hidden ${className}`}
      data-nc-id="ContactUsDetail"
    >
      <Helmet>{renderMetaData()}</Helmet>
      <ContactHeader bodyMsg="Have a question? Contact us today!" />
      <div className="container max-w-7xl mx-auto py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-20">
        {/* HEADING */}
        <div className="space-y-10 lg:space-y-14">
          {/***/}
          {selectedBranch && renderBranchDetail()}

          {/***/}
          {!selectedBranch && <ContactUsSkeleton />}
        </div>
      </div>
    </div>
  );
};

export default ContactUsDetail;
