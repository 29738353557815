import React, { FC } from "react";
import Verify_Email_Icon from "images/emails_icon/Verify_Email_Icon.png";

export interface RegisterEmailSendPageProps {
  className?: string;
}

const RegisterEmailSendPage: FC<RegisterEmailSendPageProps> = ({
  className = "",
}) => {
  return (
    <main className="container py-16 lg:pb-28 lg:pt-20 ">
      <div
        className={`nc-RegisterEmailSendPage  ${className}`}
        data-nc-id="RegisterEmailSendPage"
      >
        <div>
          <div className="w-full">
            <div
              className={` ${true ? "col-lg-2" : ""} ${
                false ? "col-md-4" : ""
              } ${false ? "col-sm-12" : ""}`}
            >
                <div className="w-full md:flex hidden items-center">
               
                <div className="w-32 mr-6">
                  {/* <img src={Verify_Email_Icon} style={{maxWidth:'100%' ,height:'150px'}} alt="Verify Email Icon"/> */}
                  <img
                    className="w-full h-full object-fill"
                    src={Verify_Email_Icon}
                    alt="Verify Email Icon"
                  />
                </div>                             
                <div className="w-full">
                <h1 className="IconHeaderStyle sm:text-3xl text-2xl font-medium capitalize text-gray-800">
                    Perfect! Just one more step to go.
                  </h1>
                  <p className="mt-2 text-base text-black">
                    We just sent you an email with a link to verify your email
                    address. You just need to open that email and hit the{" "}
                    <strong>Verify Email Address</strong> button and we can
                    start setting up your account on our end.
                  </p>
                  <p className="mt-2 text-black">
                    If wondering why this step is necessary, it is to protect
                    your identity, so someone cannot sign up pretending to have
                    your email.
                  </p>
                </div>
              </div>
              <div className="w-full md:hidden block  items-center">
                <div className="flex w-full items-center">
                <div className="w-16 mr-6">
                  {/* <img src={Verify_Email_Icon} style={{maxWidth:'100%' ,height:'150px'}} alt="Verify Email Icon"/> */}
                  <img
                    className="w-full h-full object-fill"
                    src={Verify_Email_Icon}
                    alt="Verify Email Icon"
                  />
                </div>
                <h1 className="IconHeaderStyle sm:text-3xl text-2xl font-medium capitalize text-gray-800">
                    Perfect! Just one more step to go.
                  </h1>
                  </div>
                <div className="w-full mt-4 text-black">
                  <p className="mt-2 text-base">
                    We just sent you an email with a link to verify your email
                    address. You just need to open that email and hit the{" "}
                    <strong>Verify Email Address</strong> button and we can
                    start setting up your account on our end.
                  </p>
                  <p className="mt-2">
                    If wondering why this step is necessary, it is to protect
                    your identity, so someone cannot sign up pretending to have
                    your email.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RegisterEmailSendPage;
