import { BaseProp } from "entities/BaseProp";
import React from "react";

interface AlertMessageProps extends BaseProp {
  message: string;
  title?: string;
  colorText?: string;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
  message,
  colorText = "red",
  title = "Whoops! ",
  className = "",
}) => {
  return (
    <div
      className={`bg-${colorText}-50 text-${colorText}-700 border border-${colorText}-700 px-4 py-3 rounded relative ${className}`}
      role="alert"
    >
      <strong className="font-bold">{title}</strong>
      <span
        className={`block sm:inline`}
        dangerouslySetInnerHTML={{ __html: message }}
      ></span>
    </div>
  );
};

export default AlertMessage;
