import React, { FC ,useState,useEffect } from "react";
import axiosInstance from "api/AxiosInstance";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link , useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CSC_LED_Rewards_Logo from "images/rewards/CSC-LED-Rewards-Logo-V1-FC.png";
import Alert from "containers/PageLogin/Alert";
import { login } from "store/slices/authSlice";
import Cookies from "js-cookie";
import { decryptData } from "utils/Encryption";
import { useAppDispatch } from "store/hooks";
import { setAccessToken, setTokens } from "utils/auth";
import { getCartListData } from "store/slices/cartDetailListSlice";
import { getCartData } from "store/slices/cartSlice";
import { getCartIndexListData } from "store/slices/cartIndexListSlice";

export interface RewardsLoginProps {
  className?: string;
}

const RewardsLogin: FC<RewardsLoginProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [headermsg, SetHeaderMSG] = useState('');
  const [errormsg, SetErrorMSG] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    RememberMe:false
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    RememberMe:false
  });
  const [LoginbuttonClicked, setLoginButtonClicked] = useState(false);

  const handleCheckboxChange = (e: any) => {
    setRememberMe(!rememberMe);
  };
  
  useEffect(() => {
    setLoginButtonClicked(false);
    const authState = Cookies.get("_authState");
    if (authState) {
      const data = decryptData(authState);
      const savedEmail = data.email;
      const savedPassword = data.password;
      const rememberme = data.keepLoggedIn;
      setFormData({ ...formData, email: savedEmail, password: savedPassword });
      setRememberMe(rememberme);
    } else {
      setFormData({ ...formData, email: "", password: "" });
      setRememberMe(false);
    }
  }, [setFormData,setLoginButtonClicked]);

  // const Email = Cookies.get("_AuthE1");
    // const Password = Cookies.get("_AuthP2");
    // const keepinLogged = Cookies.get("_AuthR3");
    // if (keepinLogged && Email && Password) {
    //   const savedEmail = decryptData(Email);
    //   const savedPassword = decryptData(Password);
    //   const rememberme = decryptData(keepinLogged);
    //   setFormData({ ...formData, email: savedEmail, password: savedPassword });
    //   setRememberMe(rememberme);
    // } 
    // else{
    //   setFormData({ ...formData, email: '', password: '' });
    //   setRememberMe(false);
    // }
    // const savedEmail = localStorage.getItem('email');
    // const rememberme = localStorage.getItem('rememberMe') === 'true'; // Convert string to boolean
    // const pass = localStorage.getItem('password');
  
    // if (rememberme && savedEmail && pass) {
    //   console.log("if");
    //   setFormData({ ...formData, email: savedEmail, password: pass });
    //   setRememberMe(rememberme);
    // } 

    // if (rememberMe) {
    //   localStorage.setItem('rememberMe', "true");
    //   localStorage.setItem('email', formData.email);
    //   localStorage.setItem('password', formData.password);
    // } else {
    //   localStorage.removeItem('rememberMe');
    //   localStorage.removeItem('email'); 
    //   localStorage.removeItem('password');
    // }    
    // //localStorage.setItem("rememberMe", "true");
    // localStorage.setItem("user", JSON.stringify(response.data));
    // localStorage.setItem('token', JSON.stringify(response.data.token));

  const handleInputChange = (e : any) => {
    const { id, value } = e.target;
    let errorMessage = '';
    switch (id) {
      case "email":
        errorMessage = value.trim() === '' ? "Your email address is required." : '';
        break;
      case "password":
        errorMessage = value.trim() === '' ? "A password is required." : '';
        break;
      default:
        break;
    }
  
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: errorMessage });
  };

  const handleBlur = (e: any) => {
    const { id, value } = e.target;
    if (value !== "" && !/^\S+@\S+\.\S+$/.test(value)) {
      setErrors({ ...errors, email: "This is not a valid email address." });
    }
  };
  
  const validateForm = () =>{
    const newErrors: any = {};
    if (!formData.email.trim()) {
      newErrors.email = 'Your email address is required.';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'This is not a valid email address.';
    }
    if (!formData.password.trim()) {
      newErrors.password = 'A password is required.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length != 0 ? true : false;
  }

  const handleLogin = async (e : any) => {
    e.preventDefault();
    var isValid = validateForm();
    if(isValid){
      return;
    }
    try {
       // Send data to the API using Axios
      if(!LoginbuttonClicked){ 
        setLoginButtonClicked(true);
        formData.RememberMe = rememberMe;
        const url = 'Rewards/RewardsLogin';
        const params = {
          email: formData.email,
          password: formData.password,
          RememberMe: formData.RememberMe
        };
        await axiosInstance.post(url,params )
        .then(response => {
         if (response.data.loginStatus != "" && response.data.loginStatus.errorMsg == "success") {
          dispatch(login({
            isAuthenticated:true,
            userData: {
              email: formData.email,
              password: formData.password,
              userId: response.data.userId,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
            },
            token:response.data.token,
            keepLoggedIn: formData.RememberMe,
          }));
               //Set access tokens
              setTokens(response.data.token,response.data.refreshToken);
              dispatch(getCartListData());
              dispatch(getCartData());
              dispatch(getCartIndexListData());
              navigate("/rewards");
          }
          else if(response.data.loginStatus.isAccountApproved == false && response.data.loginStatus.headerErrorMsg != ""){
              SetHeaderMSG(response.data.loginStatus.headerErrorMsg); 
              setLoginButtonClicked(false);   
              SetErrorMSG(response.data.loginStatus.errorMsg);       
          }
          else if(response.data.loginStatus.errorMsg  != "")
          {
            setLoginButtonClicked(false);
            SetErrorMSG(response.data.loginStatus.errorMsg);
          }
          else {
            setLoginButtonClicked(false);
            SetErrorMSG(response.data);
            // Handle other statuses if needed
            console.error('Request failed with status:', response.data);
          }
        });
      }
     
    } catch (error) {
      console.error("Your Username or password was invalid. Please try again");
      console.error("API Error:", error);
    }
  };

  return (
    <div className={`nc-RewardsLogin ${className}`} data-nc-id="RewardsLogin">
      <Helmet>
        <title>Login | CSC LED</title>
      </Helmet>
      <div className="flex flex-wrap">
          <div className="md:w-3/5 w-full">
            <div className="container mb-24 lg:mb-32" style={{ marginTop: "-45px" }}>
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Welcome Back
          </h2>
          <div className="sm:mx-5 mx-0">
            <hr className="border-t border-gray-300" style={{marginTop:'-2.5rem',marginBottom:'2.5rem'}} />
          </div>
          <div className="lg:max-w-xl max-w-md mx-auto space-y-6">
            {/* <div className="grid gap-3">
              {loginSocials.map((item, index) => (
                <a
                  key={index}
                  href={item.href}
                  className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                >
                  <img
                    className="flex-shrink-0"
                    src={item.icon}
                    alt={item.name}
                  />
                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                    {item.name}
                  </h3>
                </a>
              ))}
            </div> */}
            {/* OR */}
            {/* <div className="relative text-center">
              <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                OR
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div> */}
            {/* FORM */}
            {/* <div>
              {headermsg && errormsg &&  
              <Alert
              headerErrorMsg={headermsg}
              errorMessage={errormsg}
            />}
             {headermsg == "" && errormsg && (<Alert headerErrorMsg={headermsg} errorMessage={errormsg} />)}
          </div> */}
          <div>
            {headermsg && errormsg && (
              <Alert headerErrorMsg={headermsg} errorMessage={errormsg} />
            )}
            {headermsg == "" && errormsg && (<Alert headerErrorMsg={headermsg} errorMessage={errormsg} />)}
          </div>
            <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin}  method="post">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  id="email"
                  type="email"
                  className="mt-1"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  //value={formData.email}
                  style={{ border: errors.email && errors.email != "" ? '1px solid #fd397a' : '' }}
                />
                {errors.email && <span style={{ color: '#fd397a' }}>{errors.email}</span>}
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                  <Link to="/forgot-pass" className="text-sm text-csccolor">
                    Forgot password?
                  </Link>
                </span>
                <Input 
                id="password"
                onChange={handleInputChange}
                type="password" 
                className="mt-1"
                style={{ border: errors.password && errors.password != "" ? '1px solid #fd397a' : '' }}
                //value={formData.password}
                 />
                {errors.password && <span style={{ color: '#fd397a' }}>{errors.password}</span>}
              </label>
              <label className="block">
              <input
                id="rememberMe"
                type="checkbox"
                checked={rememberMe}
                onChange={handleCheckboxChange}
              />
                <span className="ml-3">Keep me logged in to the website.</span>
            </label>
            {/* onClick={() => setLoggedIn(isLoggedIn)} */}
              <ButtonPrimary type="submit"  disabled={LoginbuttonClicked}>Login</ButtonPrimary>
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Don't have an account? {` `}
              <Link className="text-csccolor" to="/rewards/signup">
              Sign up here.
              </Link>
            </span>
          </div>
            </div>
          </div>
          <div className="md:w-2/5 md:mb-0 mb-20 w-full flex justify-start items-center">
             <img
              className="h-auto lg:w-4/5 w-full object-fill object-left"
              src={CSC_LED_Rewards_Logo}
              alt="CSC LED Rewards Logo"
                />
          </div>
      </div>
    </div>
  );
};

export default RewardsLogin;
