import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
    useEffect,
  } from "react";
  
  type Theme = "light" | "dark" | "halloween";
  
  interface ThemeContextProps {
    theme:Theme
    assigntheme:string
    setAssignTheme:(theme:string)=>void;
  }
  
  const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);
  
  export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const [theme, setTheme] = useState<Theme>("light");
    const [assigntheme, setAssignTheme] = useState<string>("");
  
    useEffect(() => {
      let themeType=localStorage.theme;
      if(assigntheme){
        themeType=assigntheme;
      }
      switch (themeType) {
        case "dark":
          setTheme("dark");
          break;
        case "halloween":
          setTheme("halloween");
          break;
  
        default:
          setTheme("light");
          break;
      }
      localStorage.theme = themeType;
    }, [assigntheme]);
  
    useEffect(() => {
      document.documentElement.className = theme;
    }, [theme]);
  
    return (
      <ThemeContext.Provider value={{theme, assigntheme,setAssignTheme }}>
        {children}
      </ThemeContext.Provider>
    );
  };
  
  export const useTheme = (): ThemeContextProps => {
    const context = useContext(ThemeContext);
    if (!context) {
      throw new Error("useTheme must be used within a ThemeProvider");
    }
    return context;
  };
  