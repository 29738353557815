import { BaseProp } from 'entities/BaseProp'
import React, { FC } from 'react'
import NcImage from 'shared/NcImage/NcImage'
import sorry from "images/sorry.svg"

interface Prop extends BaseProp{

}

const MatchNotFound:FC<Prop> = ({
    className=""
}) => {
  return (
    <div className={`nc-MatchNotFound ${className}`}>
    <div className="py-16 max-w-xl">
      <div className="flex space-x-3 items-center p-5 border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden">
        <NcImage className="w-20 h-20" src={sorry} alt="" />   
        <div className="flex flex-col">
          <span className="text-black font-semibold text-xl py-3">
            We couldn't find a match for your search.
          </span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default MatchNotFound
