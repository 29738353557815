import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/AxiosInstance";
import { RootState } from "store/store";

interface CartState {
  CartDetailList: [];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CartState = {
  CartDetailList: [],
  status: 'idle',
  error: null,
};


  export const getCartListData = createAsyncThunk('cart/getCartList', async (_, { getState }) => { 
    const state = getState() as RootState;
    const user = state.auth; // Adjust based on your auth state structure

    const response = await axiosInstance.get(`/Cart/GetCartSListById`);
    //console.log("response data", response.data);
    return response.data;
  });


const cartDetailListSlice = createSlice({
  name: 'cartList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCartListData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCartListData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.CartDetailList = action.payload; // Assuming the API returns { count: number }
      })
      .addCase(getCartListData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to load cart data';
      });
  },
});

export default cartDetailListSlice.reducer;
