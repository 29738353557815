import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axiosInstance from "api/AxiosInstance";
import { config } from "utils/Constance";
import { Link } from "react-router-dom";
import ImageNotFound from "images/NoImageAvailable.png";
import CurrencyFormat from "components/CurrencyFormatter";
import SkeletonYourOrders from "./SkeletonYourOrders";
import FlyerPagination from "containers/SeriesFlyers/FlyerPagination";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";

export interface CloseOrdersPageProps {
  className?: string;
  searchValue: string;
}

const CloseOrdersPage: FC<CloseOrdersPageProps> = ({
  className = "",
  searchValue,
}) => {
  const [closeOrdersList, setCloseOrdersList] = useState<any>([]);
  const [filteredOrdersList, setFilteredOrdersList] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (page: number) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll
    });
    setCurrentPage(page);
  };

  // Calculate start and end index for current page
  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = Math.min(startIndex + itemsPerPage, closeOrdersList.length);

  // Get data for the current page
  let currentData2 = closeOrdersList.slice(startIndex, endIndex);
  const user = useAppSelector((state: RootState) => state.auth);

  const fetchData = async () => {
    const response = await axiosInstance
      .get(`/YourOrders/CloseOrderTab`)
      .then((response) => {
        // console.log("==> response", response.data);
        setCloseOrdersList(response.data);
        setFilteredOrdersList(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 3000); // Example: 3 seconds delay
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Apply filtering whenever searchValue changes
    if (searchValue) {
      const filtered = closeOrdersList.filter((item: any) => {
        return (
          item.orderNumber.toString().includes(searchValue) ||
          (item.uploadedPurchaseOrderNo &&
            item.uploadedPurchaseOrderNo
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        );
      });

      // Update the filtered list and currentData
      setFilteredOrdersList(filtered);
    } else {
      setFilteredOrdersList(closeOrdersList);
    }
  }, [searchValue, closeOrdersList]);

  useEffect(() => {
    // Update currentData based on pagination
    setCurrentData(filteredOrdersList.slice(startIndex, endIndex));
  }, [filteredOrdersList, startIndex, endIndex]);

  function formatDate(timestamp: string): string {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const renderStatusCellValue = (value: any) => {
    switch (value) {
      case "Store Front":
        return "Order Received";

      case "Quote":
        return "Order Received";

      case "Sales Order":
        return "Being Processed";

      case "Back Order":
        return "Awaiting Product Restock";

      case "Picking":
        return "Order is being Processed";

      case "Shipping":
        return "Preparing to Ship";

      case "Invoice":
        return "Order Shipped";

      case "Complete":
        return "Order Shipped";
    }
  };

  const renderOrders = () => {
    if(filteredOrdersList.length === 0){
      return renderNoOrderToDisplay();
    }

    return (
      <table className="min-w-full divide-y divide-gray-300">
        <tbody>
          {/* Repeat tr */}
          {currentData.map((_item: any, index: any) => (
            <tr
              className="flex w-full mb-10 border border-gray-200 halloween:border-white rounded-lg p-6"
              key={index}
            >
              <td className="w-full">
                <table className="w-full">
                  <tbody>
                    <tr className="flex w-full">
                      <td className="w-full">
                        <div className="customOrderlist lg:grid-rows-4 md:grid-rows-3 rounded-t-lg flex w-full justify-between flex-wrap">
                          <div>
                            <label className="p-2 md:pl-2  pl-0 pr-6 capitalize text-gray-900 font-semibold">
                              order created
                            </label>
                            <p className="p-2 md:pl-2 pl-0 pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.sfOrderDate == null
                                ? formatDate(_item.dateSalesOrderCreated)
                                : formatDate(_item.sfOrderDate)}
                            </p>
                          </div>
                          <div className="md:mt-0 mt-2 ">
                            <label className="pb-2 pt-0 flex pr-6 text-gray-900 capitalize font-semibold">
                              invoice to
                            </label>

                            <p className="pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.customerModel.customer1}
                            </p>
                            <p className="pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.customerModel.billingStreet1 != null
                                ? _item.customerModel.billingStreet1
                                : ""}
                            </p>
                            <p className="pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.customerModel.billingStreet2 != null
                                ? _item.customerModel.billingStreet2
                                : ""}
                            </p>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.customerModel.billingCity != null
                                ? _item.customerModel.billingCity + ", "
                                : ""}
                              {_item.customerModel.billingProvince != null
                                ? _item.customerModel.billingProvince + ", "
                                : ""}
                              {_item.customerModel.billingPostalCode != null
                                ? _item.customerModel.billingPostalCode
                                : ""}
                            </p>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.customerModel.billingCountry}
                            </p>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.customerModel.billingPhoneNumber}
                            </p>
                            <p className=" pt-0  pr-6 camel-case font-normal text-black-800 halloween:text-black">
                              {_item.customerModel.billingEmail}
                            </p>
                          </div>
                          <div>
                            <label className=" pt-0 md:mt-0 mt-3 pb-2 flex pr-6 text-gray-900 capitalize font-semibold">
                              ship to
                            </label>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.shippingCompanyName}
                            </p>
                            <p className="pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.contactFirstName} {_item.contactLastName}
                            </p>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.shippingStreet1}
                            </p>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.shippingStreet2}
                            </p>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.shippingCity != null
                                ? _item.shippingCity + ", "
                                : ""}
                              {_item.shippingProvince != null
                                ? _item.shippingProvince + ", "
                                : ""}
                              {_item.shippingPostalCode != null
                                ? _item.shippingPostalCode
                                : ""}
                            </p>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.shippingCountry}
                            </p>
                            <p className=" pt-0  pr-6 capitalize font-normal text-black-800 halloween:text-black">
                              {_item.shippingPhoneNumber}
                            </p>
                            <p className=" pt-0  pr-6 font-normal text-black-800 halloween:text-black">
                              {_item.shippingEmail}
                            </p>
                          </div>
                          <div className="md:mt-0 mt-3">
                            <label className="p-2 md:pl-2  pl-0 pr-6 text-gray-900 capitalize font-semibold">
                              order status
                            </label>
                            <p className="p-2 md:pl-2  pl-0 capitalize font-normal text-black-800 halloween:text-black">
                              {renderStatusCellValue(
                                _item.currentStatuModel.currentStatusName
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table className="flex w-full my-6 mb-8 flex-col bg-white border-gray-200 border rounded-lg">
                          <thead>
                            <tr className="flex">
                              <th className="w-full">
                                <div className="bg-gray-50 flex border-b p-4 border-gray-200">
                                  <p className="text-lg capitalize font-medium">
                                    order details
                                  </p>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="p-4 border-b border-gray-200">
                            <tr className="grid sm:grid-cols-2">
                              <td>
                                <div className="w-full flex flex-wrap">
                                  <label className="capitalize font-medium">
                                    CSC LED Order Number:
                                  </label>
                                  <span className="px-1"></span>
                                  <p className="text-black-800 font-normal">
                                    {_item.orderNumber}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="w-full sm:pt-0 pt-2 flex flex-wrap">
                                  <label className="capitalize font-medium">
                                    carrier:
                                  </label>
                                  <span className="px-1"></span>
                                  <p className="text-black-800 font-normal capitalize">
                                    {_item.carrierModel !== null ? (
                                      _item.carrierModel.carrierName ===
                                      "Other" ? (
                                        <span>{_item.carrierOther}</span>
                                      ) : (
                                        <span>
                                          {_item.carrierModel.carrierName}
                                        </span>
                                      )
                                    ) : (
                                      "TBD"
                                    )}
                                  </p>
                                </div>
                              </td>
                            </tr>
                            <tr className="grid sm:grid-cols-2">
                              <td>
                                <div className="w-full pt-2 flex flex-wrap">
                                  <label className="capitalize font-medium">
                                    purchase order number:
                                  </label>
                                  <span className="px-1"></span>
                                  <p className="text-black-800 font-normal capitalize">
                                    {_item.uploadedPurchaseOrderNo}
                                  </p>
                                </div>
                              </td>
                              {_item.carrierModel !== null &&
                              _item.carrierModel.carrierName !== "Pick up" ? (
                                <td>
                                  <div className="w-full pt-2 flex flex-wrap">
                                    <label className="capitalize font-medium">
                                      tracking number:
                                    </label>
                                    <span className="px-1"></span>
                                    <p className="text-black-800 font-normal capitalize">
                                      {_item.trackingNumber !== null
                                        ? _item.trackingNumber
                                        : ""}
                                    </p>
                                  </div>
                                </td>
                              ) : (
                                <td></td>
                              )}
                            </tr>
                          </tbody>
                          <tfoot className="py-4 px-4 w-full">
                            <tr>
                              <td>
                                <div className="w-full flex flex-wrap">
                                  {_item.uploadPurchaseOrderURL !== null ? (
                                    <a
                                      target="blank"
                                      href={`${config.url.AWS_S3_URL}${_item.uploadPurchaseOrderURL}`}
                                      className="flex rounded-full mr-2 bg-white sm:px-4 px-2 sm:py-2.5 py-2 text-sm font-medium text-black-800 my-1 capitalize shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#034c9e"
                                        className="mr-1"
                                        viewBox="0 0 384 512"
                                        width="20"
                                        height="20"
                                      >
                                        <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V320 240c0-8.8 7.2-16 16-16zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-64c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V304 240c0-8.8 7.2-16 16-16z" />
                                      </svg>
                                      open purchase order
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {_item.invoiceFileURL !== null ? (
                                    <a
                                      target="blank"
                                      href={`${config.url.AWS_S3_URL}${_item.invoiceFileURL}`}
                                      className="flex rounded-full mr-2 bg-white sm:px-4 px-2 sm:py-2.5 py-2 text-sm font-medium text-black-800 my-1 capitalize shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#034c9e"
                                        className="mr-1"
                                        viewBox="0 0 384 512"
                                        width="20"
                                        height="20"
                                      >
                                        <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V320 240c0-8.8 7.2-16 16-16zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-64c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V304 240c0-8.8 7.2-16 16-16z" />
                                      </svg>
                                      open invoice
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {_item.packingSlipFIleURL !== null ? (
                                    <a
                                      target="blank"
                                      href={`${config.url.AWS_S3_URL}${_item.packingSlipFIleURL}`}
                                      className="flex rounded-full mr-2 bg-white sm:px-4 px-2 sm:py-2.5 py-2 text-sm font-medium text-black-800 my-1 capitalize shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#034c9e"
                                        className="mr-1"
                                        viewBox="0 0 384 512"
                                        width="20"
                                        height="20"
                                      >
                                        <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V320 240c0-8.8 7.2-16 16-16zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-64c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V304 240c0-8.8 7.2-16 16-16z" />
                                      </svg>
                                      open packing slip
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {/* responsive product list plz make it dynamic  */}
                        <div className="sm:hidden block w-full flex-col bg-white border-gray-200 border rounded-lg">
                          <div className="bg-gray-50 rounded-t-lg flex border-b p-4 border-gray-200">
                            <p className="text-base capitalize font-medium">
                              order summary
                            </p>
                          </div>
                          <ul className="divide-y divide-gray-200">
                            {_item.salesOrderInventoryModel != null ? (
                              _item.salesOrderInventoryModel.map(
                                (_prod: any, index: any) => (
                                  <li
                                    className="flex w-full p-4 gap-x-6"
                                    key={index}
                                  >
                                    <div className="flex-col w-full">
                                      <div className="flex items-baseline">
                                        <p className="text-base w-28 my-1 font-medium capitalize text-gray-900">
                                          product
                                        </p>
                                        <p className="text-base w-10/12 flex ml-4 text-black-800">
                                          {_prod.inventoryModels.productNumber}
                                        </p>
                                      </div>
                                      <div className="flex items-baseline">
                                        <p className="text-base  w-28 my-1 font-medium capitalize text-gray-900">
                                          quantity
                                        </p>
                                        <p className="text-base w-10/12 flex ml-4 text-black-800">
                                          {_prod.quantity}
                                        </p>
                                      </div>
                                      <div className="flex items-baseline">
                                        <p className="text-base  w-28 my-1 font-medium capitalize text-gray-900">
                                          unit Price
                                        </p>
                                        <p className="text-base w-10/12 flex ml-4 text-black-800">
                                          {_prod.unitPrice != null ? (
                                            <CurrencyFormat
                                              value={_prod.unitPrice.toFixed(2)}
                                            />
                                          ) : (
                                            "$0.00"
                                          )}
                                        </p>
                                      </div>
                                      <div className="flex items-baseline">
                                        <p className="text-base  w-28 my-1 font-medium capitalize text-gray-900">
                                          price
                                        </p>
                                        <p className="text-base w-10/12 flex ml-4 text-black-800">
                                          <CurrencyFormat
                                            value={_prod.price.toFixed(2)}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )
                            ) : (
                              <li></li>
                            )}
                          </ul>
                        </div>
                        {/* responsive product list end */}
                        <table className="sm:flex hidden w-full flex-col bg-white border-gray-200 border rounded-lg">
                          <thead>
                            <tr className="bg-gray-50 rounded-t-lg grid-cols-5 grid border-b sm:p-4 p-2 border-gray-200">
                              <th className="sm:text-base col-span-2 text-sm flex capitalize font-medium">
                                Product
                              </th>
                              <th className="sm:text-base col-span-1 text-sm table-cell text-right capitalize font-medium">
                                Quantity
                              </th>
                              <th className="sm:text-base col-span-1 text-sm table-cell sm:text-right text-center capitalize font-medium">
                                Unit Price
                              </th>
                              <th className="sm:text-base col-span-1 text-sm table-cell text-right capitalize font-medium">
                                Price
                              </th>
                            </tr>
                          </thead>
                          <tbody className="items-center">
                            {_item.salesOrderInventoryModel != null ? (
                              _item.salesOrderInventoryModel.map(
                                (_prod: any, index: any) => (
                                  <tr
                                    className="grid grid-cols-5 sm:p-4 p-2 items-center"
                                    key={index}
                                  >
                                    <td className="col-span-2">
                                      <div className="truncate md:flex-row flex-col flex sm:items-center items-start sm:align-center align-start gap-2">
                                        {_prod.inventoryModels != null &&
                                        _prod.inventoryModels
                                          .inventoryImagesLowReModel.length !==
                                          0 ? (
                                          <Link
                                            to={
                                              config.url.ERP_Domain +
                                              _prod.inventoryModels
                                                .inventoryImagesLowReModel[0]
                                                .imageURL
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              className="sm:h-24 sm:w-24 w-auto h-auto rounded-full"
                                              src={
                                                config.url.ERP_Domain +
                                                _prod.inventoryModels
                                                  .inventoryImagesLowReModel[0]
                                                  .imageURL
                                              }
                                              alt={`productImage`}
                                            />
                                          </Link>
                                        ) : (
                                          <img
                                            className="sm:h-24 sm:w-24 w-auto h-auto rounded-full"
                                            src={ImageNotFound}
                                            alt={`productImageNotfound`}
                                          />
                                        )}
                                        {/* <p className="text-black-800 sm:text-base text-sm truncate font-normal">
                                        {
                                          _prod.inventoryModels
                                            .productNumber
                                        }
                                      </p> */}
                                        <Link
                                          to={`/product-details/${encodeURIComponent(
                                            _prod.inventoryModels.productNumber
                                          ).toUpperCase()}`}
                                        >
                                          {_prod.inventoryModels.productNumber}
                                        </Link>
                                      </div>
                                    </td>
                                    <td className="flex">
                                      <div className="w-full justify-end pr-6 flex">
                                        <p className="text-black-800 sm:text-base text-sm font-normal">
                                          {_prod.quantity}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="flex">
                                      <div className="w-full justify-end flex">
                                        <p className="text-black-800 sm:text-base text-sm pr-2 font-normal">
                                          {_prod.unitPrice != null ? (
                                            <CurrencyFormat
                                              value={_prod.unitPrice.toFixed(2)}
                                            />
                                          ) : (
                                            "$0.00"
                                          )}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="flex">
                                      <div className="w-full justify-end flex">
                                        <p className="text-black-800 sm:text-base text-sm font-normal capitalize">
                                          <CurrencyFormat
                                            value={_prod.price.toFixed(2)}
                                          />
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr></tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table className="block mt-4 w-full text-black-800 halloween:text-black">
                          <tr className="w-full items-end block mt-4 gap-2">
                            <td className="capitalize flex justify-end font-normal ">
                              Subtotal{" "}
                              <span className="  sm:w-40 w-24 sm:ml-20 ml-16 flex justify-end">
                                {_item.orderTotal != null ? (
                                  <CurrencyFormat
                                    value={_item.orderTotal.subTotal.toFixed(2)}
                                  />
                                ) : (
                                  "$0.00"
                                )}
                              </span>
                            </td>
                            <td className="capitalize flex justify-end font-normal">
                              Discount{" "}
                              <span className="  sm:w-40 w-24 sm:ml-20 ml-16 flex justify-end">
                                {_item.orderTotal != null ? (
                                  <CurrencyFormat
                                    value={_item.orderTotal.discountSum.toFixed(
                                      2
                                    )}
                                  />
                                ) : (
                                  "$0.00"
                                )}
                              </span>
                            </td>
                            <td className="capitalize flex justify-end font-normal ">
                              shipping{" "}
                              <span className="  sm:w-40 w-24 sm:ml-20 ml-16 flex justify-end">
                                {_item.orderTotal != null ? (
                                  <CurrencyFormat
                                    value={_item.orderTotal.freightCost.toFixed(
                                      2
                                    )}
                                  />
                                ) : (
                                  "$0.00"
                                )}
                              </span>
                            </td>
                            <td className="capitalize flex justify-end font-normal ">
                              {_item.orderTotal != null &&
                              _item.orderTotal.taxType != null
                                ? _item.orderTotal.taxType
                                : "Tax"}{" "}
                              <span className="  sm:w-40 w-24 sm:ml-20 ml-16 flex justify-end">
                                {_item.orderTotal != null ? (
                                  <CurrencyFormat
                                    value={_item.orderTotal.tax.toFixed(2)}
                                  />
                                ) : (
                                  "$0.00"
                                )}
                              </span>
                            </td>
                            <td className="capitalize flex justify-end font-semibold text-black-800 halloween:text-black">
                              Total CDN{" "}
                              <span className="text-black-800  sm:w-40 w-24 font-semibold sm:ml-20 ml-16 flex justify-end">
                                {_item.orderTotal != null ? (
                                  <CurrencyFormat
                                    value={_item.orderTotal.grandTotal.toFixed(
                                      2
                                    )}
                                  />
                                ) : (
                                  "$0.00"
                                )}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
          {/* Repeat tr */}
        </tbody>
      </table>
    );
  };

  const renderNoOrderToDisplay=()=>{
    return(
      <div className="nc-NoOrderToDisplay text-slate-500 dark:text-slate-400">
        <span>It looks like there are no closed orders here. Try a different search or submit an order today.</span>
      </div>
    )
  }

  return (
    <div
      className={`nc-ShippingAddressPage ${className}`}
      data-nc-id="ShippingAddressPage"
    >
      <div>
        <div>
          <Helmet>
            <title>Your Orders | CSC LED</title>
          </Helmet>
          {/* <CommonLayout>             */}

          {/* <div className="overflow-hidden sm:rounded-lg pb-0 p-6 shadow ring-1 ring-black ring-opacity-5"> */}
          {loading ? <SkeletonYourOrders /> : renderOrders()}
          {/* </div> */}
          {/* pagination code */}

          <div className="flex w-full justify-between items-center -mt-6">
            <div className="w-full">
              {/* Your content here */}
              <FlyerPagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={filteredOrdersList?.length as number}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseOrdersPage;
