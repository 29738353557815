import React from 'react';

interface CurrencyProps {
    value: number;
    currencyCode?: string;
}

const CurrencyFormatter: React.FC<CurrencyProps> = ({ value, currencyCode = 'USD' }) => {
    // Format the currency using Intl.NumberFormat
    const formattedCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    }).format(value);

    return <span>{formattedCurrency}</span>;
};

export default CurrencyFormatter;
