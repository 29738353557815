import {
  InvProgrammingLanguage,
  RefreshTokenLangArr,
} from "entities/TechnicalInventoryApi";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CodeContainer from "./CodeContainer";
import Divider from "components/Divider";

const InvTokenRefresh = () => {
  const [activeTab, setActiveTab] = useState(0);

  const renderProgrammingTab = (
    item: InvProgrammingLanguage,
    index: number
  ) => {
    const isActive = index === activeTab;
    return (
      <li className="me-2" key={index}>
        <Link
          to={""}
          onClick={() => setActiveTab(index)}
          className={`inline-block py-4 px-1 border-b-2 rounded-t-lg hover:text-gray-600 dark:hover:text-gray-300 ${
            isActive
              ? "border-black text-gray-600"
              : "border-transparent text-slate-500 dark:text-slate-400"
          }`}
        >
          {item.language}
        </Link>
      </li>
    );
  };

  const renderCodeHtml = (codeSyntax: string) => {
    return <CodeContainer codeText={codeSyntax} />;
  };

  return (
    <>
      {/* HEADING */}
      <div className="max-w-screen-sm">
        <h2 className="block text-2xl sm:text-3xl font-medium">
          Refreshing Your Access Token
        </h2>
      </div>
      <Divider/>
      <div className="space-y-4 dark:text-slate-400 text-sm sm:text-base">
        <div className="">
          To refresh your access token, you will need to send your access_token
          as "Authorization" in your request header with current refresh_token,
          and set grant_type to refresh_token as body parameters.
        </div>
        <div>
          As result, the body parameters of your POST request should now contain
        </div>
        <div className="overflow-auto">
          {renderCodeHtml(
            `grant_type=refresh_token&refresh_token=123456890-7ae6-4717-94ed-634550d75c62`
          )}
        </div>

        <div>instead of</div>
        <div className="overflow-auto">
          {renderCodeHtml(
            `grant_type=password&username=youremail@domain.com&password=password`
          )}
        </div>

        <div>Example:</div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px space-x-5">
            {RefreshTokenLangArr.map(renderProgrammingTab)}
          </ul>
        </div>
        <div className="overflow-auto p-2.5 border border-gray-200 dark:text-gray-400 dark:border-gray-700 select-all">
          {renderCodeHtml(RefreshTokenLangArr[activeTab].tabContent)}
        </div>
        <div>
          If your refresh token request is successful, you will receive an
          updated access_token and refresh_token. Use these to fetch data from
          our API or when refreshing your token.
        </div>
        <div>
          If your request is successful, you will receive response as below:
        </div>
        <div>
          <div className="overflow-auto border border-gray-200 dark:text-gray-400 dark:border-gray-700">
            {renderCodeHtml(`{
  "access_token": "123456789-0123456-cB9GVU9PNCGosw0edOKNPF-9wmGdPwaOvaoR2ehdazR_2Xr_ZxySf2e2oDxiMGeMtGUGRYXjaCWVjinmKrdOxQj6k2Gcr4Bts-g55GOG3s1iLNVSf0wb2_ZApi4WuIBQddUIuJ9v8kYnUoHqap95XO1zCe4xzxQNebQrwss5AHpwRANI1deVaZWPS3dX6s0-5gHdKoO1utFRZAvl36b7HBUjsejWcq1HLZv2dwsjx8xX8M885B7qrfjRO305",  // updated access_token
  "token_type": "bearer",
  "expires_in": 3599,
  "refresh_token": "12345678-99ae-4aa6-a278-7aa1c36721c9",  // new refresh_token
  "userName": "youremail@gmail.com",
  "issued": "Mon, 22 Jan 2024 11:26:59 GMT",
  "expires": "Mon, 22 Jan 2024 12:26:59 GMT"
}`)}
          </div>
        </div>
        <div>
          If the refresh token, provided by you was not correct; you will
          receive an error as below.
        </div>
        <div>
          {renderCodeHtml(`{
 "error": "invalid_grant"
}`)}
        </div>
        <div>
          If your access_token or refresh_token has expired or your API access
          rights have been revoked, you will receive the following error:
        </div>
        <div className="overflow-auto">
          {renderCodeHtml(`{
    "Message": "Authorization has been denied for this request."
}`)}
        </div>
        <div className="py-6 px-2.5 select-none">
          <blockquote className="relative bg-gray-100 py-2 px-2.5 border-l-8 border-gray-400 dark:text-gray-400 dark:border-gray-700 indent-2">
            <span className="text-4xl absolute top-1 -left-1">
              “
            </span>
            <span>
              The refresh token expires after 2 hours, while the access token
              expires after 1 hour. You must keep your refresh_token in order to
              refresh the access_token, and be able to generate a new
              access_token and refresh_token. If your refresh token gets
              expired, you will need to request a new one using your API
              registered email address and password as explained previously.
            </span>
            <span className="text-4xl absolute -ml-[0.4rem]">“</span>
          </blockquote>
        </div>
      </div>
    </>
  );
};

export default InvTokenRefresh;
