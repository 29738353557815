import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "api/AxiosInstance";
import { HomePageBannerList } from "entities/HomePageBannerList";

export interface homePageState {
  isLoading: boolean;
  data: HomePageBannerList | null;
  error: string | null;
}

const initialState: homePageState = {
  isLoading: false,
  data:null,
  error: "",
};

export const getHomePageData = createAsyncThunk("homePage", async () => {
  return await axiosInstance.get("/Home/getHomePageData").then((res) => res.data);
});

export const homePageSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(getHomePageData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHomePageData.fulfilled, (state, actions) => {
      state.isLoading = false;
      state.data = actions.payload;
      state.error = null;
    });
    builder.addCase(
        getHomePageData.rejected,
      (state, actions: PayloadAction<any>) => {
        state.isLoading = false;
        state.data = null;
        state.error = actions.payload ?? "An error occurred.";
      }
    );
  },
});

export const {} = homePageSlice.actions;

export default homePageSlice.reducer;
