import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import FilterSeriesFlyers from "./FilterSeriesFlyers";
import axiosInstance from "api/AxiosInstance";
import { FlyerDTO, FlyersFilterParameter } from "entities/FlyerDTO";
import FlyerPagination from "./FlyerPagination";
import handleScrollToEl from "utils/HandleScrollToEl";
import CSCProductCard from "components/CSCProductCard";
import { BaseProp } from "entities/BaseProp";
import Divider from "components/Divider";
import MatchNotFound from "components/MatchNotFound";

interface SeriesFlyersProp extends BaseProp {}

const SeriesFlyers: FC<SeriesFlyersProp> = ({ className = "" }) => {
  const [dataResult, setDataResult] = useState<FlyerDTO | null>(null);
  const [filters, setFilters] = useState<FlyersFilterParameter>({
    departmentId: 0,
    languageId: 0,
    productTypeId: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const filteredItems = dataResult?.seriesFlyersList?.filter((item) => {
    if (filters.departmentId && item.departmentId !== filters.departmentId) {
      return false;
    }
    if (filters.productTypeId && item.productTypeId !== filters.productTypeId) {
      return false;
    }
    if (filters.languageId && item.languageId !== filters.languageId) {
      return false;
    }
    if (
      filters.searchText &&
      !item.flyerName.toLowerCase().includes(filters.searchText.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    handleScrollToEl("scrollSeriesTab");
  };

  const handleFilterChange = (
    filterName: string,
    value: number | string | undefined
  ) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
    setCurrentPage(1);
  };

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getSeriesFlyers")
      .then((response) => {
        setDataResult(response.data);
        //setLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const itemsPerPage = 24;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem);

  const renderSeriesFlyers = () => {
    return (
      <main>
        {/* FILTER */}
        {dataResult && (
          <FilterSeriesFlyers
            filterParam={filters}
            handleFilterChange={handleFilterChange}
            flyersFilter={dataResult}
            filteredItems={currentItems}
          />
        )}
        {/**product tiles */}
        {currentItems && currentItems.length > 0 ? (
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {currentItems.map((item, index) => (
              <CSCProductCard
                key={index}
                name={item.flyerName}
                image={item.flyerImageURL || undefined}
                link={item.flyerDocURL}
              />
            ))}
          </div>
        ) : <MatchNotFound/>}

        {/* PAGINATION */}
        <FlyerPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={filteredItems?.length as number}
          onPageChange={handlePageChange}
        />
      </main>
    );
  };

  return (
    <div className={`nc-SeriesFlyers ${className}`} data-nc-id="SeriesFlyers">
      <Helmet>
        <title>Series Flyers | CSC LED</title>
      </Helmet>
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm scroll-mt-32" id="scrollSeriesTab">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Series Flyers
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base halloween:text-black">
              From vision to illumination: Discover our amazing LED series
            </span>
          </div>
          <Divider/>
          {dataResult && renderSeriesFlyers()}
        </div>
      </div>
    </div>
  );
};

export default SeriesFlyers;
