import { config } from "utils/Constance";

export interface TechInvParameter {
  name: string;
  description: string;
  dataType: string;
}

export interface InvEndPointsSample {
  api: string;
  description: string;
  url: string;
}

export interface InvProgrammingLanguage {
  language: string;
  tabContent: string;
}

const TechInvParameterArr: TechInvParameter[] = [
  { name: "Amperage", description: `Amperage`, dataType: "decimal" },
  { name: "Base", description: `Products base.`, dataType: "string" },
  {
    name: "BeamAngle",
    description: `Product's beam angle.`,
    dataType: "string",
  },
  {
    name: "CartonHeight",
    description: `The carton's height in inches.`,
    dataType: "string",
  },
  {
    name: "CartonLength",
    description: `The carton's length in inches.`,
    dataType: "string",
  },
  {
    name: "CartonWeight",
    description: `The carton's weight.`,
    dataType: "string",
  },
  {
    name: "CartonWidth",
    description: `The carton's width in inches.`,
    dataType: "string",
  },
  {
    name: "CertificationListed",
    description: `Product's certifications.`,
    dataType: "string",
  },
  {
    name: "ColorTemperature",
    description: `Product's colour temperature.`,
    dataType: "string",
  },
  { name: "CRI", description: `Product's CRI.`, dataType: "string" },
  {
    name: "CSCcULNumber",
    description: `Product's cUL number.`,
    dataType: "string",
  },
  {
    name: "CSCDLCNumber",
    description: `Product's DLC number.`,
    dataType: "string",
  },
  {
    name: "Department",
    description: `CSC LED's department assignment.`,
    dataType: "string",
  },
  {
    name: "Dimmable",
    description: `Product's dimmable setting.`,
    dataType: "string",
  },
  { name: "Efficacy", description: `Product's efficacy.`, dataType: "string" },
  {
    name: "Finish",
    description: `Product's finish material.`,
    dataType: "string",
  },
  {
    name: "FinishColor",
    description: `Product's finish colour.`,
    dataType: "string",
  },
  { name: "GTIN", description: `Product's GTIN.`, dataType: "string" },
  {
    name: "HighResolutionPhotosList",
    description: `The URL paths for the product's high resolution photos.`,
    dataType: "array",
  },
  {
    name: "Housing",
    description: `Product's housing material.`,
    dataType: "string",
  },
  {
    name: "IESFilesList",
    description: `The URL paths for the product's IES files.`,
    dataType: "array",
  },
  {
    name: "InitialLumens",
    description: `Product's initial lumens.`,
    dataType: "string",
  },
  { name: "IPRating", description: `Product's IP rating.`, dataType: "string" },
  {
    name: "IsClearance",
    description: `Indicates whether the product is classified as clearance.`,
    dataType: "boolean",
  },
  {
    name: "LifecycleActiveDate",
    description: `The date in which CSC LED released the product.`,
    dataType: "string",
  },
  {
    name: "LifecycleStatus",
    description: `CSC LED's assigned lifecycle status.`,
    dataType: "string",
  },
  { name: "LifeL70", description: `Product's Life (L70).`, dataType: "string" },
  {
    name: "LowResolutionPhotoURL",
    description: `The URL path for the product's low resolution photo.`,
    dataType: "string",
  },
  {
    name: "MarketingMaterialsList",
    description: `The URL paths for the product's Marketing Material files.`,
    dataType: "array",
  },
  {
    name: "MasterCartonHeight",
    description: `The master carton's height in inches.`,
    dataType: "string",
  },
  {
    name: "MasterCartonLength",
    description: `The master carton's length in inches.`,
    dataType: "string",
  },
  {
    name: "MasterCartonWeight",
    description: `The master carton's weight in pounds.`,
    dataType: "string",
  },
  {
    name: "MasterCartonWidth",
    description: `The master carton's width in inches.`,
    dataType: "string",
  },
  {
    name: "MetaDescription",
    description: `The product's meta description as created by CSC LED.`,
    dataType: "string",
  },
  {
    name: "MetaKeywords",
    description: `The product's meta keywords as assigned by CSC LED.`,
    dataType: "string",
  },
  {
    name: "Miscellaneous1URL",
    description: `The URL path for the product's miscellaneous file 01.`,
    dataType: "string",
  },
  {
    name: "Miscellaneous2URL",
    description: `The URL path for the product's miscellaneous file 02.`,
    dataType: "string",
  },
  {
    name: "MountingType",
    description: `Product's mounting type.`,
    dataType: "string",
  },
  {
    name: "OperatingTemperature",
    description: `Product's operating temperature range in celcius.`,
    dataType: "string",
  },
  {
    name: "OperationInstructionsURL",
    description: `The URL path for product's operation instruction file.`,
    dataType: "string",
  },
  {
    name: "OtherList",
    description: `The URL paths for the product's files categorized as other.`,
    dataType: "array",
  },
  {
    name: "PowerCordLength",
    description: `Included power cord length, if applicable.`,
    dataType: "string",
  },
  {
    name: "PowerFactor",
    description: `Product's power factor.`,
    dataType: "string",
  },
  {
    name: "ProductDescription",
    description: `Short product description.`,
    dataType: "string",
  },
  {
    name: "ProductId",
    description: `Our unique CSC LED product Id.`,
    dataType: "integer",
  },
  {
    name: "ProductNumber",
    description: `Our unique CSC LED product number.`,
    dataType: "string",
  },
  {
    name: "ProductSeries",
    description: `The CSC LED product series this product belongs to.`,
    dataType: "string",
  },
  {
    name: "ProductType",
    description: `CSC LED's product type classification.`,
    dataType: "string",
  },
  {
    name: "QtyPerCarton",
    description: `The number of products within each carton.`,
    dataType: "string",
  },
  {
    name: "QtyPerMasterCarton",
    description: `The number of cartons within each master carton.`,
    dataType: "integer",
  },
  {
    name: "QtyPerSkid",
    description: `The number of products which can be stored on a single skid.`,
    dataType: "string",
  },
  {
    name: "SpecSheetFilesList",
    description: `The URL paths for the product's Spec Sheet files.`,
    dataType: "array",
  },
  {
    name: "StockImmediate",
    description: `Total available stock at AB, BC and ON warehouses.`,
    dataType: "integer",
  },
  {
    name: "StockOnHand",
    description: `Total available stock at AB, BC and ON warehouses, inclusive of branch transfers.`,
    dataType: "integer",
  },
  {
    name: "SuggestedTrade",
    description: `Suggested trade unit price.`,
    dataType: "decimal number",
  },
  {
    name: "TechnicalDocumentsList",
    description: `The URL paths for the product's Technical Document files.`,
    dataType: "array",
  },
  {
    name: "THD",
    description: `Product's THD as percentage.`,
    dataType: "string",
  },
  {
    name: "TotalAvailableForSale",
    description: `Total available stock at AB, BC and ON warehouses, inclusive of branch transfers and incoming restock orders.`,
    dataType: "integer",
  },
  {
    name: "TradeSize",
    description: `The product's approximate size for website filtering.`,
    dataType: "string",
  },
  {
    name: "UnitGrossWeight",
    description: `The product's gross weight.`,
    dataType: "string",
  },
  {
    name: "UnitHeight",
    description: `The product's height in inches.`,
    dataType: "string",
  },
  {
    name: "UnitLength",
    description: `The product's length in inches.`,
    dataType: "string",
  },
  {
    name: "UnitWidth",
    description: `The product's width in inches.`,
    dataType: "string",
  },
  { name: "UPC", description: `Product's UPC.`, dataType: "string" },
  { name: "Voltage", description: `Product's voltage.`, dataType: "string" },
  { name: "Watts", description: `Product's wattage.`, dataType: "string" },
  {
    name: "WebsiteDescription",
    description: `Product's full website description.`,
    dataType: "string",
  },
  {
    name: "WebsiteName",
    description: `The name of the product.`,
    dataType: "string",
  },
  {
    name: "YourPrice",
    description: `Price per unit.`,
    dataType: "decimal number",
  },
];

export const TechInventoryArr = [...TechInvParameterArr].sort((a, b) =>
  a.name.localeCompare(b.name)
);

export const apiEndPointArr: InvEndPointsSample[] = [
  {
    api: `GET api/inventory/getInventoryList?pageNumber={pageNumber}&pageSize={pageSize}`,
    description: `Call this function to retrieve all available inventory data`,
    url: `/technical-inventory-api`,
  },
  {
    api: `GET api/inventory/get?productNumber={productNumber}`,
    description: `Call this function to get specific inventory details`,
    url: `/technical-inventory-api`,
  },
];

export const RefreshTokenLangArr: InvProgrammingLanguage[] = [
  {
    language: "C#",
    tabContent: `var client = new RestClient("${config.url.Tech_API_URL}/token");
  client.Timeout = -1; 
  var request = new RestRequest(Method.POST);
  request.AddHeader("Content-Type", "text/plain");
  request.AddParameter("text/plain", "grant_type=refresh_token&refresh_token=123456890-7ae6-4717-94ed-634550d75c62",ParameterType.RequestBody); 
  IRestResponse response = client.Execute(request); 
  Console.WriteLine(response.Content);`,
  },
  {
    language: "Javascript",
    tabContent: `var myHeaders = new Headers();
  myHeaders.append("Content-Type", "text/plain");

  var raw = "grant_type=refresh_token&refresh_token=123456890-7ae6-4717-94ed-634550d75c62";

  var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
  };

  fetch("${config.url.Tech_API_URL}/token", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`,
  },
  {
    language: "Java",
    tabContent: `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
  MediaType mediaType = MediaType.parse("text/plain");
  RequestBody body = RequestBody.create(mediaType, "grant_type=refresh_token&refresh_token=123456890-7ae6-4717-94ed-634550d75c62");
  Request request = new Request.Builder()
  .url("${config.url.Tech_API_URL}/token")
  .method("POST", body)
  .addHeader("Content-Type", "text/plain")
  .build();
  Response response = client.newCall(request).execute();`,
  },
  {
    language: "NodeJs",
    tabContent: `var https = require('follow-redirects').https;
  var fs = require('fs');

  var options = {
  'method': 'POST',
  'hostname': 'csc-led.com',
  'path': '/token?',
  'headers': {
  'Content-Type': 'text/plain'
  },
  'maxRedirects': 20
  };

  var req = https.request(options, function (res) {
  var chunks = [];

  res.on("data", function (chunk) {
  chunks.push(chunk);
  });

  res.on("end", function (chunk) {
  var body = Buffer.concat(chunks);
  console.log(body.toString());
  });

  res.on("error", function (error) {
  console.error(error);
  });
  });

  var postData =  "grant_type=refresh_token&refresh_token=123456890-7ae6-4717-94ed-634550d75c62";

  req.write(postData);

  req.end();`,
  },
];

export const GenerateTokenLangArr: InvProgrammingLanguage[] = [
  {
    language: "C#",
    tabContent: `var client = new RestClient("${config.url.Tech_API_URL}/token");
    client.Timeout = -1;
    var request = new RestRequest(Method.POST);
    request.AddHeader("Content-Type", "text/plain");
    request.AddParameter("text/plain", "grant_type=password&username=youremail@domain.com&password=password",  ParameterType.RequestBody);
    IRestResponse response = client.Execute(request);
    Console.WriteLine(response.Content);`,
  },
  {
    language: "Javascript",
    tabContent: `var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    var raw = "grant_type=password&username=youremail@domain.com&password=password";

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };

    fetch("${config.url.Tech_API_URL}/token", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`,
  },
  {
    language: "Java",
    tabContent: `OkHttpClient client = new OkHttpClient().newBuilder()
    .build();
    MediaType mediaType = MediaType.parse("text/plain");
    RequestBody body = RequestBody.create(mediaType, "grant_type=password&username=youremail@domain.com&password=password");
    Request request = new Request.Builder()
    .url("${config.url.Tech_API_URL}/token")
    .method("POST", body)
    .addHeader("Content-Type", "text/plain")
    .build();
    Response response = client.newCall(request).execute();`,
  },
  {
    language: "NodeJs",
    tabContent: `var http = require('follow-redirects').http;
  var fs = require('fs');

  var options = {
  'method': 'POST',
  'hostname': '${config.url.Tech_API_URL}',
  
  'path': '/token?',
  'headers': {
  'Content-Type': 'text/plain'
  },
  'maxRedirects': 20
  };

  var req = http.request(options, function (res) {
  var chunks = [];

  res.on("data", function (chunk) {
  chunks.push(chunk);
  });

  res.on("end", function (chunk) {
  var body = Buffer.concat(chunks);
  console.log(body.toString());
  });

  res.on("error", function (error) {
  console.error(error);
  });
  });

  var postData =  "grant_type=password&username=youremail@domain.com&password=password";

  req.write(postData);

  req.end();`,
  },
];
