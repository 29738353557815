import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import HeaderBanner from "./HeaderBanner";
import Cookies from "js-cookie";
import { RootState } from "store/store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useUserState } from "hooks/useUserState";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const [showSearchForm, setShowSearchForm] = useState(false);
  // const user = localStorage.getItem("user");
  const _userStateConfig = useUserState();
  const user = useAppSelector((state: RootState) => state.auth);
  let cookieVal = Cookies.get('googtrans');
  cookieVal = cookieVal?.replace('/en', '').replace('/', '');

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="halloween:text-black"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderUserLogin = () => {
    return (
      <>
        {!_userStateConfig?.isHideCart && <CartDropdown />}
        <AvatarDropdown />
      </>
    );
  };

  const renderLoginButtons = () => {
   
    return (
      <>
        <div className="hidden xl:flex space-x-2 whitespace-nowrap">
          <ButtonSecondary className={`${cookieVal == 'fr' ? 'whitespace-nowrap' : ''}`} fontSize="text-sm font-medium" href="/login">
            Log in
          </ButtonSecondary>
          <ButtonPrimary className={`${cookieVal == 'fr' ? 'whitespace-nowrap' : ''}`} fontSize="text-sm font-medium" href="/signup">
            Sign up
          </ButtonPrimary>
        </div>
      </>
    );
  };

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between">
        <div className="flex items-center xl:hidden flex-1">
          <MenuBar />
        </div>
        <div className="lg:flex-1 lg:ml-[-45px] flex items-center">
          <Logo className="flex-shrink-0" />
        </div>

        <div className="flex-[2] hidden xl:flex justify-center mx-4" style={{marginLeft: cookieVal == 'fr' ? '220px' : ''}}>
          {showSearchForm ? (
            <SearchBar setShowSearchForm={setShowSearchForm} />
          ) : (
            <Navigation />
          )}
        </div>

        <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100" style={{marginLeft: cookieVal == 'fr'? '-20px' : ''}}>
          {!showSearchForm && (
            <button
              className="hidden xl:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
              onClick={() => setShowSearchForm(!showSearchForm)}
            >
              {renderMagnifyingGlassIcon()}
            </button>
          )}
          {user.isAuthenticated ? renderUserLogin() : renderLoginButtons()}
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-custom-grey dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700 
    halloween:bg-orange-600 halloween:text-black halloween:border-orange-600">
      <HeaderBanner />
      <div className="container !font-poppins">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
function dispatch(arg0: { payload: undefined; type: "auth/loadUserFromCookies"; }) {
  throw new Error("Function not implemented.");
}

