import React, { useEffect, useState } from "react";
import toast, { ToastOptions } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import { ToastDataDTO } from "entities/ToastDataDTO";
import axiosInstance from "api/AxiosInstance";
import { getUserLoginData } from "utils/auth";

const PageNotifications: React.FC = () => {
  const [dataResult, setDataResult] = useState<ToastDataDTO[]>([]); // State to hold toasts data
  const location = useLocation();
  const _userLoginData = getUserLoginData();

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getPagewisePopups")
      .then((response) => {
        if (response.data.success) {
          setDataResult(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let currentRoute = location.pathname;
    if(location.search){
      currentRoute+=location.search;
    }
    
    if (dataResult.length > 0) {
      const toastObj = dataResult.filter((x) => {
        const targetPage = x.targetPageList.find((y) => y === currentRoute);
        return targetPage !== undefined;
      });

      toastObj.map((toast) => {
        if (toast) {
          // Check for toast show to only login user.
          if (toast.isLoginUser && !_userLoginData) {
            return;
          }
          // display toast
          renderToaster(toast);
        }
      });
    }
  }, [location.pathname, dataResult]);

  const handleToastEnd = (toastId: string) => {
    toast.dismiss(toastId);
  };

  const renderToaster = (item: ToastDataDTO) => {
    toast.custom(
      (t) => {
        return (
          <ProgressBar
            key={item.id}
            toastVisible={t.visible}
            toastData={item}
            onToastEnd={() => handleToastEnd(t.id)}
          />
        );
      },
      {
        id: item.id.toLocaleString(),
        position: item.placementPosition ?? "bottom-right",
        duration: item.duration ?? 5000,
        className: "nc-PageNotifications",
      } as ToastOptions
    );
  };

  return <></>;
};

export default PageNotifications;
