import { BaseFilter, BaseFilterString } from "entities/BaseEntities";
import { BaseProp } from "entities/BaseProp";
import { FilterItems, ProductItemRequest } from "entities/ProductsDTO";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import ProductSortFilter from "./ProductSortFilter";
import { ProductSortData } from "data/cscdata";
import { stringToSlug } from "utils/stringToSlug";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import handleScrollToEl from "utils/HandleScrollToEl";

interface SideFilterProp extends BaseProp {
  dataResult: FilterItems;
  selectedFilter: ProductItemRequest;
  isShowAccessories: boolean;
  rangeInitialLumen: number[];
  sortproperty?: string;
  onChangeOptionalAcc: (isCheck: boolean) => void;
  assignParameters: (filterProp: string, filterVal: any) => void;
  onCheckboxChange: (
    filterProp: keyof ProductItemRequest,
    checked: boolean,
    value: any
  ) => void;
  onClearFilter: () => void;
}

const SideFilter: FC<SideFilterProp> = ({
  className = "",
  dataResult,
  selectedFilter,
  isShowAccessories,
  rangeInitialLumen,
  sortproperty,
  onChangeOptionalAcc,
  onCheckboxChange,
  assignParameters,
  onClearFilter,
}) => {
  const appliedFilters = [
    selectedFilter.pTypeArr,
    selectedFilter.pserArr,
    selectedFilter.deptArr,
    selectedFilter.wattArr,
    selectedFilter.voltArr,
    selectedFilter.tradesizeArr,
    selectedFilter.fcolorArr,
    selectedFilter.certArr,
    selectedFilter.ctempArr,
  ].filter((value) => value?.length !== 0).length;

  {
    /**Use States */
  }
  const [rangePrices, setRangePrices] = useState<number[]>(rangeInitialLumen);
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  {
    /**End use states */
  }

  {
    /**Handle Events */
  }
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const handleSortFilterChange = (filterId: any) => {
    assignParameters(`sortproperty`, filterId);
    handleScrollToEl("scrollSeriesTab");
  };

  const checkFilter = (
    filterName: string,
    selectedArr: string[],
    isSlug: boolean
  ) => {
    if (isSlug) {
      const slugifiedArr: string[] = selectedArr.map(stringToSlug);
      const slugFilter = stringToSlug(filterName);
      return slugifiedArr.includes(slugFilter);
    }
    return selectedArr.includes(filterName);
  };

  const renderTabsFilters = <T extends BaseFilter | BaseFilterString>(
    items: T[],
    title: string,
    filterProp: keyof ProductItemRequest,
    selectedArr: string[]
  ) => {
    return (
      <>
        {items.length > 0 && (
          <div className="relative flex flex-col pb-4">
            <h3 className="font-semibold my-2.5 px-1.5">{title}</h3>
            <div className="max-h-[245px] overflow-y-auto space-y-4 px-1.5 py-1.5 customScrollBar">
              {items.map((item, index) => (
                <div key={`div${item.filterName}${item.filterId}`} className="">
                  <Checkbox
                    key={`chk${item.filterName}${item.filterId}`}
                    name={`chk${item.filterName}${item.filterId}`}
                    label={item.filterName}
                    checked={items.length === 1 || checkFilter(item.filterName, selectedArr, filterProp === "pserArr")}
                    sizeClassName="w-5 h-5"
                    labelClassName="text-sm font-normal hover:translate-x-1 transition duration-500 hover:text-csccolor ease-in-out"
                    onChange={(checked) =>
                      onCheckboxChange(filterProp, checked, item.filterName)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    arrElement: number
  ) => {
    const newValue = event.target.value;

    // Allow only numeric values and empty input
    if (newValue === "" || /^[0-9]*$/.test(newValue)) {
      const newMaxPrice = newValue === "" ? 0 : parseInt(newValue, 10);

      setRangePrices((prevPrices) => {
        const newPrices = [...prevPrices];
        newPrices[arrElement] = newMaxPrice;
        return newPrices;
      });
    }
  };

  const handleInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    var val = parseInt(event.target.value);
    var elementId = event.target.id;
    var arrElement = elementId == `minILum` ? 0 : 1;
    setRangePrices((prevPrices) => {
      const newPrices = [...prevPrices];
      newPrices[arrElement] = val;
      return newPrices;
    });
    assignParameters(elementId, val);
    handleScrollToEl("scrollSeriesTab");
  };

  const renderTabsInitialLumens = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-5 pr-3">
        <div className="space-y-5">
          <span className="font-semibold">Initial Lumens</span>
        </div>

        <div className="flex justify-between space-x-5">
          <div>
            <label
              htmlFor="minILum"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Min
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                name="minILum"
                id="minILum"
                autoComplete="Off"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[0]}
                onChange={(e) => handleInputChange(e, 0)}
                onBlur={(e) => handleInputBlur(e)}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxILum"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Max
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                name="maxILum"
                id="maxILum"
                autoComplete="Off"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                onChange={(e) => handleInputChange(e, 1)}
                onBlur={(e) => handleInputBlur(e)}
                value={rangePrices[1]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAllFilters = () => {
    return (
      <div
        className={`${className} divide-y divide-slate-200 dark:divide-slate-700 halloween:divide-white`}
      >
        {renderTabsFilters(
          dataResult.departmentList,
          `Department`,
          "deptArr",
          selectedFilter.deptArr || []
        )}
        {renderTabsFilters(
          dataResult.productTypeList,
          `Product Type`,
          "pTypeArr",
          selectedFilter.pTypeArr || []
        )}
        {renderTabsFilters(
          dataResult.productSeriesList,
          `Product Series`,
          "pserArr",
          selectedFilter.pserArr || []
        )}
        {renderTabsFilters(
          dataResult.wattageList,
          `Wattage`,
          "wattArr",
          selectedFilter.wattArr || []
        )}
        {renderTabsFilters(
          dataResult.voltageList,
          `Voltage`,
          "voltArr",
          selectedFilter.voltArr || []
        )}
        {renderTabsInitialLumens()}
        {renderTabsFilters(
          dataResult.colorTemperatureList,
          `Color Temperature`,
          "ctempArr",
          selectedFilter.ctempArr || []
        )}
        {renderTabsFilters(
          dataResult.finishColorList,
          `Finish Color`,
          "fcolorArr",
          selectedFilter.fcolorArr || []
        )}
        {renderTabsFilters(
          dataResult.tradeSizeList,
          `Trade Size`,
          "tradesizeArr",
          selectedFilter.tradesizeArr || []
        )}
        {renderTabsFilters(
          dataResult.certificationsList,
          `Certifications`,
          "certArr",
          selectedFilter.certArr || []
        )}
      </div>
    );
  };

  const renderProductFilters = () => {
    return (
      <>
        <div>
          <ProductSortFilter
            data={ProductSortData}
            onChange={handleSortFilterChange}
            selectedValue={sortproperty}
          />
          <div className="">
            <button
              onClick={() => {
                setRangePrices(rangeInitialLumen);
                onClearFilter();
              }}
              className="w-full py-2
            text-black dark:text-neutral-200 
            border border-neutral-200 dark:border-neutral-700 
            hover:bg-neutral-100 rounded-2xl px-4 
            font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 flex justify-center items-center transition-colors
            halloween:bg-white halloween:text-black halloween:hover:bg-neutral-100"
            >
              Clear all filters
            </button>
          </div>
        </div>
        <div className="divide-y divide-slate-200 dark:divide-slate-700 halloween:divide-white">
          <div className="py-4 pr-2">
            <span className="text-xl font-medium">Filters</span>
          </div>
          <div className="py-4 px-2">
            <Checkbox
              key={`chkShowAccessories`}
              name={`chkShowAccessories`}
              label={`Show Accessories`}
              checked={isShowAccessories}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              onChange={(check) => {
                onChangeOptionalAcc(check);
              }}
            />
          </div>
        </div>
        {dataResult && renderAllFilters()}
      </>
    );
  };

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabMobileFilter = () => {
    return (
      <div className="flex-shrink-0">
        <div
          className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer select-none halloween:bg-white halloween:text-black
          ${
            appliedFilters > 0
              ? "!border-primary-500 bg-primary-50 text-primary-900"
              : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
          }
          `}
          onClick={openModalMoreFilter}
        >
          <svg
            className="w-4 h-4"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 6.5H16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 17.5H18"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 17.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="ml-2">Product filters ({appliedFilters})</span>
          {appliedFilters > 0 && renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full text-left align-middle transition-all transform bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 halloween:bg-orange-400 h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Product filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>
                  <div className="flex-grow overflow-y-auto">
                    <div className="px-6 sm:px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <ProductSortFilter
                          data={ProductSortData}
                          onChange={handleSortFilterChange}
                        />
                      </div>
                      <div className="py-7">
                        <div className="py-4 px-2">
                          <Checkbox
                            key={`chkShowAccessories`}
                            name={`chkShowAccessories`}
                            label={`Show Accessories`}
                            checked={isShowAccessories}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(check) => {
                              onChangeOptionalAcc(check);
                            }}
                          />
                        </div>
                        {renderAllFilters()}
                      </div>
                      {/* --------- */}
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        onClearFilter();
                        closeModalMoreFilter();
                      }}
                      sizeClass="py-2.5 px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="py-2.5 px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };
  return (
    <>
      {dataResult && (
        <div className="flex">
          <div className="hidden lg:flex lg:flex-col pr-4">
            {renderProductFilters()}
          </div>
          {/* FOR RESPONSIVE MOBILE */}
          <div className="flex flex-col overflow-x-auto lg:hidden space-y-4">
            {renderTabMobileFilter()}
          </div>
        </div>
      )}
    </>
  );
};

export default SideFilter;
