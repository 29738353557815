import { XMarkIcon } from "@heroicons/react/24/solid";
import axiosInstance from "api/AxiosInstance";
import { ToastDataDTO } from "entities/ToastDataDTO";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

interface ProgressBarProps {
  toastVisible: boolean;
  toastData: ToastDataDTO;
  onToastEnd: () => void;
}

interface CssClass {
  textcss: string;
  bodycss: string;
  progressbarcss: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  toastVisible,
  //duration,
  toastData,
  onToastEnd,
}) => {
  const [progress, setProgress] = useState(100); // Start at 100% width
  const [isPaused, setIsPaused] = useState(false);
  const [dynamicCss, setDynamicCss] = useState<CssClass>({
    textcss: "",
    bodycss: "",
    progressbarcss: "",
  });

  const handleBannerInteractionClick = (notificationId: number) => {
    const logClick = async () => {
      const response = await axiosInstance
        .post(`/Home/logNotificationsClick/${notificationId}`)
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    
    logClick();
    onToastEnd();
  };

  const {
    id,
    toastType,
    title,
    message,
    isCloseButton,
    isProgressBar,
    duration,
    linkUrl,
  } = toastData;

  const onMouseEnter = () => setIsPaused(true);
  const onMouseLeave = () => setIsPaused(false);

  useEffect(() => {
    getCssClass(toastType);
  }, [toastType]);

  useEffect(() => {
    if (isPaused) return;

    const interval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev - 100 / ((duration / 1000) * 10); // Decrease width every 100ms
        if (newProgress <= 0) {
          clearInterval(interval);
          onToastEnd(); // Trigger end of toast if progress is 0
          return 0;
        }
        return newProgress;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [isPaused, duration, onToastEnd]);

  const renderMessageBody = () => {
    return (
      <p className={`mt-1 text-${dynamicCss.textcss}-600 text-sm font-normal`}>
        {message}
      </p>
    );
  };

  const renderToastBody = () => {
    const isAbsoluteUrl = linkUrl?.startsWith('http') ? linkUrl : `https://${linkUrl}`;
    return (
      <div
        className="p-4 flex"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="ml-1 flex-1">
          <span className={`text-${dynamicCss.textcss}-700 font-semibold`}>
            {title}
          </span>
          {message &&
            (linkUrl ? (
              <Link to={isAbsoluteUrl} target="_blank" onClick={()=>handleBannerInteractionClick(id)}>
                {renderMessageBody()}
              </Link>
            ) : (
              renderMessageBody()
            ))}
        </div>
        {isCloseButton && (
          <div
            className="flex flex-shrink-0 items-center cursor-pointer"
            onClick={onToastEnd}
          >
            <XMarkIcon className="h-6 w-6" />
          </div>
        )}
      </div>
    );
  };

  const renderProgressBar = () => {
    return (
      <div className="w-full bg-gray-200 h-1">
        <div
          className={`${dynamicCss.progressbarcss} h-full transition-all duration-100 ease-linear`}
          style={{ width: `${progress}%` }}
        />
      </div>
    );
  };

  const getCssClass = useCallback(
    (toastType: string) => {
      switch (toastType) {
        case "error":
          setDynamicCss({
            textcss: "red",
            bodycss: "bg-red-200 border-red-600",
            progressbarcss: "bg-red-600",
          });
          break;
        case "info":
          setDynamicCss({
            textcss: "blue",
            bodycss: "bg-blue-200 border-blue-600",
            progressbarcss: "bg-blue-600",
          });
          break;
        case "success":
          setDynamicCss({
            textcss: "green",
            bodycss: "bg-green-200 border-green-600",
            progressbarcss: "bg-green-600",
          });
          break;
        case "warning":
          setDynamicCss({
            textcss: "yellow",
            bodycss: "bg-yellow-200 border-yellow-600",
            progressbarcss: "bg-yellow-600",
          });
          break;
        default:
          break;
      }
    },
    [setDynamicCss]
  );

  return (
    <div
      key={id}
      className={`nc-PageNotifications transition ease-in-out delay-300 hover:-translate-y-1 hover:scale-105 duration-300 flex flex-col rounded-lg max-w-sm shadow-lg border-l-8 ${dynamicCss.bodycss}`}
      style={{
        opacity: toastVisible ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
      }}
    >
      {renderToastBody()}
      {isProgressBar && renderProgressBar()}
    </div>
  );
};

export default ProgressBar;
