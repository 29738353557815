import Cookies from "js-cookie";
import { decryptData } from "./Encryption";

interface User {
  userId: number;
  email: string;
}

const _accessTokenParam ="_SAC"
const _refreshTokenParam ="_SRF"
const _deviceIdParam ="_SDI"

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(_accessTokenParam, accessToken);
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem(_accessTokenParam);
};

export const setTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(_accessTokenParam, accessToken);
  Cookies.set(_refreshTokenParam, refreshToken, {
    expires: 30,
    secure: true,
    sameSite: "strict",
  });
};

export const removeTokens = () => {
  localStorage.removeItem(_accessTokenParam);
  Cookies.remove(_refreshTokenParam);
};

export const getRefreshToken = (): string | null => {
  return Cookies.get(_refreshTokenParam) as string;
};


export const getUserLoginData = (): User | null => {
  const storedUser = Cookies.get("_authState");
  if (storedUser) {
    try {
      const jsonObject = decryptData(storedUser);
      if (!jsonObject) {
        return null;
      }
      if (jsonObject.userId === 0 || jsonObject.userId === "0") {
        return null;
      }
      var obj = { userId: jsonObject.userId, email: jsonObject.email };
      return obj;
    } catch (error) {
      console.error("Failed to decrypt user data:", error);
      return null;
    }
  }

  return null;
};

export const getOrCreateDeviceId = ():string => {
  let deviceId = Cookies.get(_deviceIdParam) as string;
  if (!deviceId) {
    deviceId = crypto.randomUUID();
      Cookies.set(_deviceIdParam, deviceId, {
        expires: 180,
        secure: true,
        sameSite: "strict",
      });
  }
  return deviceId;
};