import { Popover, Transition } from "@headlessui/react";
import React, { FC, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseFilter } from "entities/BaseEntities";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Radio from "shared/Radio/Radio";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface FilterRadioComponentProp {
  className?: string;
  filterLabel: string;
  filterPropertyName: string;
  filterId: number;
  filterList: BaseFilter[];
  fontAwsomeIcon?: IconProp;
  handleFilterChange: (
    filterName: string,
    value: number | string | undefined
  ) => void;
}

const FilterRadioComponent: FC<FilterRadioComponentProp> = ({
  filterLabel,
  filterPropertyName,
  filterId,
  filterList,
  fontAwsomeIcon,
  handleFilterChange,
}) => {
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm border rounded-full focus:outline-none select-none halloween:bg-white halloween:text-black
                  ${open ? "!border-primary-500 " : ""}
                    ${
                      filterId > 0
                        ? "!border-primary-500 bg-primary-50 text-primary-900"
                        : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                    }
                    `}
            >
              {fontAwsomeIcon && (
                <FontAwesomeIcon
                  icon={fontAwsomeIcon}
                  className="w-4 h-4 ml-3"
                />
              )}
              <span className="ml-2">
                {filterId && filterList
                  ? (filterList.find((i) => i.filterId === filterId) || {})
                      .filterName || filterLabel
                  : filterLabel}
              </span>
              {filterId <= 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => handleFilterChange(filterPropertyName, 0)}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {filterList &&
                      filterList.map((item) => (
                        <Radio
                          id={item.filterId.toLocaleString()}
                          key={item.filterId}
                          name={filterPropertyName}
                          label={item.filterName}
                          defaultChecked={filterId === item.filterId}
                          onChange={() =>
                            handleFilterChange(
                              filterPropertyName,
                              item.filterId
                            )
                          }
                        />
                      ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        handleFilterChange(filterPropertyName, 0);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default FilterRadioComponent;
