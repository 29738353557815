import React, { FC } from "react";
import { Link } from "react-router-dom";

interface HomePaginationProps {
  className?: string;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (pageNumber: number) => void;
}

const HomePagination: FC<HomePaginationProps> = ({
  className = "",
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) => {
  const renderPaginationItems = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i}>
          {i === currentPage ? (
            <span className="inline-flex w-11 h-11 items-center justify-center rounded-full bg-custom-Blue text-white">
              {i}
            </span>
          ) : (
            <Link
              to={`/`}
              className="inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
              onClick={() => onPageChange(i)}
            >
              {i}
            </Link>
          )}
        </li>
      );
    }

    return pages;
  };

  return (
    <div className="flex flex-col mt-5 lg:mt-5 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
      <nav
        className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
      >
        <ul className="flex space-x-1">{renderPaginationItems()}</ul>
      </nav>
    </div>
  );
};

export default HomePagination;
