import { BaseProp } from 'entities/BaseProp'
import React, { FC } from 'react'

interface DividerProps extends BaseProp{

}

const Divider:FC<DividerProps> = ({className=""}) => {
  return (
    <hr className={`nc-Divider border-slate-200 dark:border-slate-700 halloween:border-white ${className}`} />
  )
}

export default Divider
