import { useState } from "react";
import handleScrollToEl from "utils/HandleScrollToEl";

const usePagination = (
  array: any[] | null | undefined,
  pageSize: number = 24,
  scrollToId?: string,
  handlePageChange?: (pageNumber: number) => void
) => {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = pageSize;

  const validArray = array ?? [];
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = validArray.slice(indexOfFirstItem, indexOfLastItem);
  const totallength = validArray.length;

  const defaultHandlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (scrollToId) {
      handleScrollToEl(scrollToId);
    }
  };

  const onPageChange = (pageNumber: number) => {
    if (handlePageChange) {
      handlePageChange(pageNumber);
    } else {
      defaultHandlePageChange(pageNumber);
    }
  };

  return {
    currentItems,
    currentPage,
    itemsPerPage,
    totallength,
    setCurrentPage,
    onPageChange,
  };
};

export default usePagination;
