import { SubmitHandler } from "react-hook-form";

const AppendFormData: SubmitHandler<any> = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key as keyof typeof data];
    if (value instanceof FileList) {
      // Append each file in FileList individually
      for (let i = 0; i < value.length; i++) {
        formData.append(key, value[i]);
      }
    } else if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  });

  return formData;
};

export default AppendFormData;
