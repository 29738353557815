import Heading from "components/Heading/Heading";
import { BaseProp } from "entities/BaseProp";
import React, { FC } from "react";

const IframeInstagram: FC<BaseProp> = ({ className = "" }) => {
  const iframeSrc = "https://www.instagram.com/cscledcorp/embed/";

  return (
    <div
      className={`nc-IframeInstagram scroll-mt-32 ${className}`}
      id="IframeInstagram"
    >
      <div className={`flow-root`}>
        <Heading
          className="mb-12 text-neutral-900 dark:text-neutral-50 font-poppins"
          isCenter={false}
        >
          {`Stay up to date on Instagram`}
        </Heading>
        <div className="mt-10 flex justify-center">
          <iframe
            className="instagram-media-rendered h-[450px] sm:h-[700px] md:h-[730px] lg:h-[900px] xl:h-[1080px] w-full rounded-xl"
            id="instagram-embed-0"
            src={iframeSrc}
            data-instgrm-payload-id="instagram-media-payload-0"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default IframeInstagram;
