import axiosInstance from "api/AxiosInstance";
import ContactUsRawHTML from "containers/ContactUs/ContactUsRawHTML";
import { BaseApiResponse } from "entities/ApiResponse";
import { BaseProp } from "entities/BaseProp";
import { HeaderBannerDTO } from "entities/HeaderBannerDTO";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface HeaderBannerProp extends BaseProp{

}

const HeaderBanner:FC<HeaderBannerProp> = ({className=""}) => {

  const [dataResult, setDataResult] =
    useState<BaseApiResponse<HeaderBannerDTO> | null>(null);

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getHeaderBanner")
      .then((response) => {
        setDataResult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {dataResult?.data && (
        <div
           style={{ backgroundColor: dataResult.data.bannerbgColor }}
          className={`nc-HeaderBanner py-1 flex justify-center items-center ${className}`}
        >
          <Link to={`${dataResult?.data.bannerLink}`} target="_blank" className="">
            {dataResult?.data.bannerText && (
              <ContactUsRawHTML
                className="ck-content"
                rawHtml={dataResult?.data.bannerText}
              />
            )}
          </Link>
        </div>
      )}
    </>
  );
};

export default HeaderBanner;
