export const protectedRoutes = [
    '/account',
    '/account-change-password',
    '/shipping-address',
    '/account-savelists',
    '/open-orders',
    '/back-order',
    '/close-order',
    '/Cart/index',
    '/Cart/checkout-order',
    '/Cart/review-order',
    '/Cart/order-placed',
    '/rewards/rewards-shop', 
    '/edit-shipping-address/:id',
    '/registration-success/:activation',
  ];