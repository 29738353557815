import { BaseProp } from "entities/BaseProp";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getProductTypeData,
  setClearProductFilter,
  setProductFilter,
} from "store/slices/productTypeSlice";
import SkeletonProductType from "./SkeletonProductType";
import CSCProductCard from "components/CSCProductCard";
import ProductSideFilters from "./ProductSideFilters";
import { InventoryItem, ProductSeriesItem } from "entities/ProductTypeDTO";
import Divider from "components/Divider";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/solid";
import { BreadcrumbsParams } from "entities/BreadcrumbsParams";
import { stringToSlug } from "utils/stringToSlug";

interface ProductSeriesProp extends BaseProp {}

const ProductSeries: FC<ProductSeriesProp> = ({ className = "" }) => {
  const { series } = useParams<{ series: string }>();

  const dispatch = useAppDispatch();
  const response = useAppSelector((state) => state.productType.data?.data);
  const isAPILoad = useAppSelector(
    (state) => state.productType.isLoading
  ) as boolean;
  const _storeFilter = useAppSelector((state) => state.productType.filter);

  const [commonValues, setCommonValues] = useState<
    ProductSeriesItem[] | undefined
  >([]);
  const [inventoryList, setInventoryList] = useState<
    InventoryItem[] | undefined
  >([]);
  const [crubObj, setcrubObj] = useState<BreadcrumbsParams>({});

  useEffect(() => {
    if (!response) {
      dispatch(getProductTypeData());
    }
  }, [response]);

  const urlProduct = response?.productDataList.find(
    (a) => a.systemId === series
  );

  useEffect(() => {
    dispatch(setClearProductFilter());
    dispatch(setProductFilter({ key: "ptypeId", value: urlProduct?.id }));
  }, [series, response, dispatch]);

  useEffect(() => {
    const invResult = response?.inventoryItemList.filter((item) => {
      if (_storeFilter.deptId && item.departmentId !== _storeFilter.deptId) {
        return false;
      }
      if (_storeFilter.ptypeId && item.productTypeId !== _storeFilter.ptypeId) {
        return false;
      }
      if (_storeFilter.pserId && item.productSeriesId !== _storeFilter.pserId) {
        return false;
      }
      if (_storeFilter.wattId && item.wattage !== _storeFilter.wattId) {
        return false;
      }
      if (_storeFilter.voltId && item.voltage !== _storeFilter.voltId) {
        return false;
      }
      if (_storeFilter.ctempId && item.colorTemp !== _storeFilter.ctempId) {
        return false;
      }
      if (_storeFilter.fcolorId && item.finishColor !== _storeFilter.fcolorId) {
        return false;
      }
      if (
        _storeFilter.tradesizeId &&
        item.tradeSizeId !== _storeFilter.tradesizeId
      ) {
        return false;
      }
      if (
        _storeFilter.certId &&
        (!item.certification ||
          !item.certification.includes(_storeFilter.certId))
      ) {
        return false;
      }
      return true;
    });

    setInventoryList(invResult);

    var prodIdArr = invResult?.map((item) => item.productSeriesId) || [];

    const result = response?.productSeriesItemList
      .filter((value) => prodIdArr.includes(value.id))
      .sort((a, b) => a.name.localeCompare(b.name));

    setCommonValues(result);
  }, [_storeFilter]);

  const updateCrubObj = (key: string, value: string | null | undefined) => {
    setcrubObj((prev) => ({ ...prev, [key]: value }));
  };

  const renderProductBody = useCallback(() => {
    return (
      <>
        {response && (
          <main>
            {/* LOOP ITEMS */}
            <div
              className="flex flex-col lg:flex-row scroll-mt-32"
              id="scrollSeriesTab"
            >
              <div className="lg:w-1/3 xl:w-1/4 pr-4">
                <ProductSideFilters
                  inventoryData={inventoryList}
                  handleBreadCrumb={updateCrubObj}
                />
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 lg:border-t-0"></div>
              <div className="flex-1 ">
                <div className="flex-1 grid grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10 ">
                  {commonValues &&
                    commonValues.map((item, index) => (
                      <CSCProductCard
                        key={index}
                        name={item.name}
                        image={item.imageUrl || undefined}
                        description={item.description}
                        link={`/products?ptype=${urlProduct?.productTypeName}&pser=${item.name}`}
                        linkTarget="_self"
                      />
                    ))}
                </div>
              </div>
            </div>
          </main>
        )}
      </>
    );
  }, [commonValues, inventoryList]);

  const renderBreadcrumbs = () => {
    const renderLink = (key: string) => {
      var link = "/";
      switch (key) {
        case "department":
          link = `/product-type${`/${crubObj.department?.toLocaleLowerCase()}`}`;
          break;
        case "productType":
          link = `/product-series/${stringToSlug(crubObj.productType ?? "")}`;
          break;
        case "productSeries":
          link = `/products?pser=${crubObj.productSeries}`;
          break;
      }
      return link;
    };

    const validValues = Object.entries(crubObj).filter(
      ([key, value]) => value !== undefined && value !== null
    );
    if (validValues.length == 0) {
      return <></>;
    }

    return (
      <nav
        aria-label="Breadcrumb"
        className={`nc-Breadcrumbs flex mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400 halloween:text-black`}
        id="nc-Breadcrumbs"
      >
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <Link to="/" className="hover:text-csccolor">
                <HomeIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0"
                />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {Object.entries(crubObj).map(([key, value]) =>
            value ? (
              <li key={key}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="h-5 w-5 flex-shrink-0"
                  />
                  <Link
                    className="ml-4 text-sm font-medium hover:text-csccolor"
                    to={renderLink(key)}
                  >
                    {value}
                  </Link>
                </div>
              </li>
            ) : null
          )}
        </ol>
      </nav>
    );
  };

  return (
    <div className={`nc-ProductSeries ${className}`} data-nc-id="ProductSeries">
      <Helmet>
        <title>Product Series | CSC LED</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
              Select a Product Series
            </h2>
            {renderBreadcrumbs()}
          </div>
          <Divider />
          {isAPILoad ? <SkeletonProductType /> : renderProductBody()}
        </div>
      </div>
    </div>
  );
};

export default ProductSeries;
