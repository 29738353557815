import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/AxiosInstance";
import { RootState } from "store/store";

interface CartState2 {
  CartIndexDetailList: [];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CartState2 = {
  CartIndexDetailList: [],
  status: 'idle',
  error: null,
};


  export const getCartIndexListData = createAsyncThunk('cart/getCartIndexList', async (_, { getState }) => {    
    const state = getState() as RootState;
   
    const response = await axiosInstance.get(`/Cart`);
  //  console.log("Cart indecx response data", response.data);
    return response.data;
  });


const cartIndexListSlice = createSlice({
  name: 'cartIndexList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCartIndexListData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCartIndexListData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.CartIndexDetailList = action.payload; // Assuming the API returns { count: number }
      })
      .addCase(getCartIndexListData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to load cart data';
      });
  },
});

export default cartIndexListSlice.reducer;
