
  
{/**NOTE: Please configure .env file for PROD & DEV object to avoid bugs in build. */}

{/**NOTE: process.env value are different values according to "development", "staging", "production"*/}

{/**NOTE: Always add new .env variable starting with "REACT_APP_" */}
const enviConfig = {
  url: {
    AWS_S3_URL: process.env.REACT_APP_AWS_S3_URL,
    ERP_Domain: process.env.REACT_APP_ERP_Domain,
    WebSite_Domain: process.env.REACT_APP_WebSite_Domain,
    API_URL: process.env.REACT_APP_API_URL,
    Tech_API_URL: process.env.REACT_APP_Tech_API_URL,
  },
  emails: {
    EmailMarketing: 'marketing@csc-led.com',
    EmailITSupport: 'ITSupport@csc-led.com',
    EmailDataCove: 'datacove414@gmail.com',
    EmailOrders: 'orders@csc-led.com'
  },
  Encryption : {
    EncryptionKey: 'o~z@nm#({r43GK>wuJ6\Sl]gY2zT=7',
  },
  apiKey:{
    clientid:`Rhv44r0gz1QO8Tt8wyyc952bCFkdB19N`,
    clientsecret: `00v9gprb0hLN3L7rNPCuyiTAMOjnX27S3H3kA7PqBxZc9dncVK`,
    headerName:`x-accept-id`,
  },
  recaptchaKey:process.env.REACT_APP_GRecaptcha_secret,
};

export const config = enviConfig;
