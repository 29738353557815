export const GST_5_SetTaxCodes: string[] = [
    "Alberta", "AB", "British Columbia", "BC",
    "Manitoba", "MB", "Northwest Territories", "NT",
    "Nunavut", "NU", "Saskatchewan", "SK",
    "Yukon", "YK", "YT", "nwt"
];

// Add other configurations as necessary
export const GST_QSTQC9_975_SetTaxCodes: string[] = [
    // Add your actual tax codes here
    "québec","Quebec","QC"
];


export const HST_NB_NS_NF_SetTaxCodes : string[] = [
    "New Brunswick","NB","Nova Scotia"
    ,"NS","newfoundland","NL"
    ,"Newfoundland and Labrador"
];

export const HST_PEI_SetTaxCodes : string[] = [
  "Prince Edward Island","PEI","PE"
];

export const HST_ON_SetTaxCodes : string[] = [
  "Ontario","ON","ONT"
];

export const isOutOfStock = (
  qty: number,
  abQty: number,
  onQty: number,
  bcQty: number
) => {
  if (qty > abQty + onQty + bcQty) {
    return true;
  }
  return false;
};

export const ValidateQtyCount = (cart: [], isClearance: boolean) => {
  const count = cart.filter(item => {
    const { qty, invDTO } = item;
    const { abQty, onQTY, bcQty, clearance } = invDTO;
    return (qty > abQty + onQTY + bcQty && clearance === isClearance);
  }).length;

  return count;
}