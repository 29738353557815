import { StarIcon } from "@heroicons/react/24/solid";
import Heading from "components/Heading/Heading";
import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { config } from "utils/Constance";
import HomePagination from "./HomePagination";
import { TopSellingProduct } from "entities/HomePageBannerList";
import { getUserLoginData } from "utils/auth";
import { stringToSlug } from "utils/stringToSlug";
import Rating from "components/Rating";
import usePagination from "hooks/usePagination";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";

interface TopSellingProductsProps {
  className?: string;
  productList?: TopSellingProduct[];
  productTitle?: string;
}

const TopSellingProducts: FC<TopSellingProductsProps> = ({
  className = "",
  ...args
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const navigate = useNavigate();

  const handleButtonClick = (item : any) => {
    // Programmatically navigate to the product details page
    navigate(`/product-details/${stringToSlug(item.productNumber).toUpperCase()}`);
  };

  const renderRatings = (starCount: number) => {
    return <Rating size={22} count={starCount} value={starCount} />;
  };
  const userData = getUserLoginData();

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  {
    /**Hook for pagination */
  }
  const { currentItems, currentPage, itemsPerPage, totallength, onPageChange } =
    usePagination(args.productList, 5, "TopSelling");
    
  const renderProductImage = (item: TopSellingProduct) => {
    return (
      <div className={`justify-start items-center p-2 xl:px-1 xl:py-0`}>
        <Link
          to={`/product-details/${stringToSlug(item.productNumber).toUpperCase()}`}
          title={"title"}
          className={`block w-36 h-full relative overflow-hidden mb-5 sm:mr-4 sm:mb-0 group`}
        >

             <div className="flex aspect-w-4 aspect-h-3 sm:aspect-w-10 sm:aspect-h-7 w-full h-20 bg-white rounded-lg">
                <NcImage
                  containerClassName="absolute inset-0"
                  src={`${config.url.ERP_Domain}${item.lowResImage.replace(
                    "../../",
                    ""
                  )}`}
                  className="object-contain w-full h-full group-hover:scale-110 transform transition-transform duration-300"
                />
            </div>       
        </Link>
      </div>
    );
  };

  const renderProductTable = () => {
    return (   
        <table className="w-full text-sm text-left rtl:text-right text-black-500 dark:text-black-400 !font-poppins">
          <thead className="text-xs text-white bg-custom-Blue dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-2 rounded-tl-lg rounded-bl-lg">
                Product
              </th>
              <th></th>
              <th scope="col" className="px-3 py-2">
                Rating
              </th>
              {userData ? (
                <>
                  <th scope="col" className="px-1 py-1">
                    AB Qty
                  </th>
                  <th scope="col" className="px-1 py-1">
                    BC Qty
                  </th>
                  <th scope="col" className="px-1 py-1">
                    ON Qty
                  </th>
                </>
              ) : (
                <></>
              )}

              <th scope="col" className="px-3 py-2">
                Description
              </th>
              <th scope="col" className="px-3 py-2 rounded-tr-lg rounded-br-lg"></th>
            </tr>
          </thead>
          <div className="mt-4"></div>
          <tbody>
            {currentItems?.map((item, index) => {
               const isLastItem = index === currentItems.length - 1;
              return (
                <tr
                  key={index}
                  className={`bg-white ${isLastItem ? '' : 'border-b'} dark:bg-gray-800 dark:border-gray-700`}
                  //className="bg-custom-Alabaster border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td
                    scope="row"
                    className="px-3 py-2 font-medium whitespace-nowrap dark:text-white"
                  >
                    {renderProductImage(item)}
                  </td>
                  <td>
                    <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
                      <Link
                        to={`/product-details/${stringToSlug(
                          item.productNumber
                        ).toUpperCase()}`}
                        className="whitespace-nowrap capitalize text-csccolor font-bold"
                        title={"Product Number"}
                      >
                        {item.productNumber}
                      </Link>
                    </h2>
                  </td>
                  <td className="px-3 py-2">{renderRatings(item.rating)}</td>
                  {userData ? (
                    <>
                      <td className="px-1 py-1">{item.abQty}</td>
                      <td className="px-1 py-1">{item.bcQty}</td>
                      <td className="px-1 py-1">{item.onQty}</td>
                    </>
                  ) : (
                    <></>
                  )}

                  <td className="px-3 py-2">{item.productDescription}</td>
                  <td className="px-3 py-2 flex items-center justify-end">
                  <ButtonCSCLed
                    className="rounded-lg mt-4 text-sm px-5 py-2.5 me-2 mb-2 !font-poppins" 
                    onClick={() => handleButtonClick(item)}
                    fontSize="text-sm font-medium"
                  >
                   View
                  </ButtonCSCLed>
                    {/* <Link
                      to={`/product-details/${stringToSlug(item.productNumber).toUpperCase()}`}
                      className="text-gray-900 bg-csccolor border border-gray-300 focus:outline-none hover:bg-blue-700 focus:ring-4 focus:ring-blue-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-800 dark:text-white dark:border-blue-600 dark:hover:bg-blue-700 dark:hover:border-blue-600 dark:focus:ring-blue-700"
                    >
                      View
                    </Link> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
    );
  };

  const renderMobielProductTable = () => {
    return (
      <table className="w-full text-sm text-left rtl:text-right text-black-500 dark:text-black-400 !font-poppins">
        <thead className="text-xs text-white bg-custom-Blue dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-3 py-2 rounded-tl-lg rounded-bl-lg">
              Product
            </th>
            <th scope="col" className="px-3 py-2 rounded-tr-lg rounded-br-lg">
              Description
            </th>
          </tr>
        </thead>
        <div className="mt-4"></div>
        <tbody>
          {currentItems?.map((item, index) => {
             const isLastItem = index === currentItems.length - 1;
            return (
              <tr
                key={index}
                className={`bg-white ${isLastItem ? '' : 'border-b'} dark:bg-gray-800 dark:border-gray-700`}
                //className="bg-custom-Alabaster border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td scope="row" className="px-3 py-2">
                  {renderProductImage(item)}
                </td>
                <td className="px-3 py-2">
                  <div className="flex flex-col w-full gap-1">
                    <div>
                      <h2 className="nc-card-title block text-sm font-semibold text-neutral-900 dark:text-neutral-100">
                        <Link
                          to={`/product-details/${stringToSlug(
                            item.productNumber
                          ).toUpperCase()}`}
                          className="whitespace-nowrap capitalize text-csccolor font-bold"
                          title={"Product Number"}
                        >
                          {item.productNumber}
                        </Link>
                      </h2>
                    </div>
                    {item.rating > 0 && renderRatings(item.rating)}
                    <div className="text-sm">{item.productDescription}</div>
                    {userData && (
                      <div className="flex flex-col mt-1">
                        <div className="basis-full flex">
                          <span className="w-1/3">AB Qty: </span>
                          <span>{item.abQty}</span>
                        </div>
                        <div className="basis-full flex">
                          <span className="w-1/3">BC Qty: </span>
                          <span>{item.bcQty}</span>
                        </div>
                        <div className="basis-full flex">
                          <span className="w-1/3">ON Qty: </span>
                          <span>{item.onQty}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className={`nc-TopSelling scroll-m-32 ${className}`} id="TopSelling">
      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-center text-center mb-5 lg:mb-14 text-neutral-900 dark:text-neutral-50 w-full">
        <h2 className="text-3xl md:text-4xl font-semibold font-poppins">
          Weekly Best Sellers {!isMobile && `- ${args.productTitle}`}
        </h2>
        <h5 className="sm:hidden text-lg font-medium">{args.productTitle}</h5>
      </div>
      <div className="bg-white p-8 rounded-xl shadow-lg max-w-7xl mx-auto">
        <div className="relative overflow-x-auto">
          {isMobile ? renderMobielProductTable() : renderProductTable()}

          {/* PAGINATION */}
          {args.productList && args.productList.length > itemsPerPage && (
            <HomePagination                     
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totallength}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopSellingProducts;
