import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import CurrencyFormatter from "components/CurrencyFormatter";
import { BaseProp } from "entities/BaseProp";
import {
  Specification,
} from "entities/ProductDetailsDTO";
import React, { FC } from "react";

interface AccordianProductInfo extends BaseProp {
  panelClassName?: string;
  productDescription?: string;
  specificationList?: Specification[];
  isClearance?: boolean;
}

const AccordianProductInfo: FC<AccordianProductInfo> = ({
  panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 halloween:text-black leading-6",
  productDescription,
  specificationList,
  isClearance,
}) => {
  const renderProductDescriptionAccordian = () => {
    return (
      <Disclosure key={"acc_ProductDetails"} defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
              <span>Description</span>
              {!open ? (
                <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
              ) : (
                <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className={panelClassName} as="div">
              <>
                {isClearance && (
                  <div className="text-red-600 font-bold text-base mb-2">
                    <p>CLEARANCE ITEM - While supplies last</p>
                    <p>Final Sale - 1-Year Manufacturer Warranty</p>
                  </div>
                )}

                {productDescription && (
                  <div
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: productDescription || "",
                    }}
                  />
                )}
              </>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  };

  const renderSpecification = (obj: Specification, index: number) => {
    if (!obj.specificationList.length) return <></>;

    return (
      <div key={index} className="flex flex-col space-y-1 text-black dark:text-slate-300">
        {obj.title && (
          <h3 className="  flex mt-2">
            <span className="tracking-tight font-medium text-lg">
              {obj.title}
            </span>
          </h3>
        )}

        {obj.specificationList.map((spec, index) => (
          <div key={index} className="flex text-sm font-normal">
            <div className="shrink-0 w-1/2">{spec.heading}:</div>
            <div className="">{spec.value}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderSpecificationAccordian = () => {
    return (
      <>
        {specificationList && (
          <Disclosure key={"acc_specification"} defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                  <span>Specifications</span>
                  {!open ? (
                    <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                  ) : (
                    <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel
                  className={`${panelClassName} divide-y divide-slate-200 dark:divide-slate-700 halloween:divide-white space-y-3`}
                  as="div"
                >
                  {specificationList.map((item, index) =>
                    renderSpecification(item, index)
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )}
      </>
    );
  };

  return (
    <div
      className="nc-AccordianProductInfo w-full rounded-2xl space-y-2.5"
      data-nc-id="AccordianProductInfo"
    >
      {/* ============ */}
      {(productDescription || isClearance) &&
        renderProductDescriptionAccordian()}
      {renderSpecificationAccordian()}
      {/* ============ */}
    </div>
  );
};

export default AccordianProductInfo;
