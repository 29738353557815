import React, { useEffect } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { cscLedMenu } from "data/navigation";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import SearchBar from "components/Header/SearchBar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcLink from "shared/NcLink/NcLink";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = cscLedMenu,
  onClickClose,
}) => {
  const user = useAppSelector((state: RootState) => state.auth);

  const renderParentItems = (item: NavItemType, itemClass = "") => {
    return (
      <>
        <div className="flex w-full items-center py-2.5 px-4 font-medium tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg">
          <Disclosure.Button
            as="span"
            className={`flex flex-grow ${itemClass}`}
          >
            <span className={!item.children ? "block w-full" : ""}>
              {item.name}
            </span>
          </Disclosure.Button>

          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex justify-end flex-grow"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </div>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </>
    );
  };

  const renderChildlessItem = (item: NavItemType, itemClass = "") => {
    return (
      <NavLink
        className={({ isActive }) =>
          `flex w-full items-center py-2.5 px-4 tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg ${itemClass} ${
            isActive ? "text-secondary" : ""
          }`
        }
        to={item.href}
        target={item.targetBlank ? "_blank" : undefined}
      >
        <span
          className={!item.children ? "block w-full" : ""}
          onClick={onClickClose}
        >
          {item.name}
        </span>
      </NavLink>
    );
  };

  const _renderMenuChild = (
    item: NavItemType,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            {i.children ? renderParentItems(i, ``) : renderChildlessItem(i, ``)}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={index}
        as="li"
        className="text-black dark:text-white"
      >
        {item.children
          ? renderParentItems(item, `uppercase`)
          : renderChildlessItem(item, `uppercase font-medium`)}
      </Disclosure>
    );
  };

  const renderLoginButtons = () => {
    return (
      <div className="w-auto px-6 py-4 flex">
        <NcLink to={"/login"}>
          <ButtonSecondary
            className=""
            fontSize="text-base font-medium"
            onClick={onClickClose}
          >
            Log in
          </ButtonSecondary>
        </NcLink>
        <NcLink to={"/signup"}>
          <ButtonPrimary
            className=""
            fontSize="text-base font-medium"
            onClick={onClickClose}
          >
            Sign up
          </ButtonPrimary>
        </NcLink>
      </div>
    );
  };

  return (
    <div
      className="nc-NavMobile overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white 
    dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800
    halloween:bg-orange-400 halloween:text-black
    "
    >
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-slate-600 dark:text-slate-300 halloween:text-black text-sm">
          <span>
            Step into the future of lighting with CSC LED, where precision
            engineering meets eco-friendly innovation for Canadian businesses.
          </span>

          <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />
            <span className="hidden">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>

        <div className="mt-5">
          <SearchBar onClickClose={onClickClose} />
        </div>
      </div>
      {!user.isAuthenticated && renderLoginButtons()}
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
      </ul>
    </div>
  );
};

export default NavMobile;
