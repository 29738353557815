import React, { FC } from 'react'

interface CodeContainerProp{
    className?:string,
    codeText:string,
}

const CodeContainer:FC<CodeContainerProp> = ({codeText,className=""}) => {
  return (
    <>
       <pre>
          <code className={`bg-gray-200 text-gray-800 p-1 text-xs ${className}`}>
            {codeText}
          </code>
        </pre>
    </>
  )
}

export default CodeContainer
