import React, { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-common-types";
import axios from "axios";
import axiosInstance from "api/AxiosInstance";
import { config } from "utils/Constance";
import SkeletonPolicyForms from "./SkeletonPolicyForms";
import Divider from "components/Divider";
import HeadingText2 from "components/HeadingText/HeadingText2";

interface SubheaderItem {
  subheader: string;
  link: string;
  icon: string;
}

interface HeaderItem {
  name: string;
  subHeading: SubheaderItem[];
}

interface LanguageList {
  [key: string]: HeaderItem[];
}

export interface PolicyFormProps {
  className?: string;
}

const PolicyForm: FC<PolicyFormProps> = ({ className = "" }) => {
  const [isLoading, setLoad] = useState(true);
  const [dataList, setDataList] = useState<LanguageList>({});

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getDonwloadForms")
      .then((response) => {
        const { dataList } = response.data;
        setDataList(dataList);
        setLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderHeaders = (headerItem: HeaderItem) => {
    return (
      <div key={headerItem.name} className="space-y-6">
        <h3 className="text-lg font-semibold">{headerItem.name}</h3>
        <div className="flex flex-col space-y-2.5">
          {headerItem.subHeading.map((subItem, index) => (
            <Link
              key={index}
              to={config.url.AWS_S3_URL + subItem.link}
              target="_blank"
              className="w-[300px] py-2.5 px-5 h-auto inline-flex items-center justify-start text-[14px] 
              font-normal text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 
              focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 
              dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              <FontAwesomeIcon
                icon={subItem.icon as IconName}
                className="text-base mr-2"
              />
              {subItem.subheader}
            </Link>
          ))}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <SkeletonPolicyForms/>;
  } else {
    return (
      <div
        className={`nc-PolicyForm ${className}`}
        data-nc-id="PolicyForm"
      >
        <Helmet>
          <title>Policies & Forms | CSC LED</title>
        </Helmet>
        <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
          <div className="space-y-10">
            {/* HEADING */}
            <div className="max-w-screen-sm">              
              <HeadingText2 className="font-poppins">
                Policies and Forms <span className="text-csccolor">(English)</span>
              </HeadingText2>
            </div>
            <Divider/>
            <div className="space-y-6">
              {dataList.english?.map(renderHeaders)}
            </div>
            {/* HEADING */}
            <div className="max-w-screen-sm">
              <HeadingText2 className="font-poppins">
                Policies and Forms <span className="text-csccolor">(French)</span>
              </HeadingText2>
            </div>
            <Divider/>
            <div className="space-y-6">
              {dataList.french?.map(renderHeaders)}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PolicyForm;
