import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "api/AxiosInstance";
import { BaseApiResponse } from "entities/ApiResponse";
import { ContactUsPageDTO } from "entities/ContactUsPageDTO";

export interface contactUsState {
  data: BaseApiResponse<ContactUsPageDTO[]> | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: contactUsState = {
  data: null,
  isLoading: false,
  error: "",
};

export const getContactUs = createAsyncThunk("contactUs", async () => {
  return await axiosInstance.get("/Home/getContactUs").then((res) => res.data);
});

export const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getContactUs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getContactUs.fulfilled, (state, actions) => {
      state.isLoading = false;
      state.data = actions.payload;
      state.error = null;
    });
    builder.addCase(
      getContactUs.rejected,
      (state, actions: PayloadAction<any>) => {
        state.isLoading = false;
        state.data = null;
        state.error = actions.payload ?? "An error occurred.";
      }
    );
  },
});

export const {} = contactUsSlice.actions;

export default contactUsSlice.reducer;