import React from "react";

const SkeletonYourOrders = () => {
  return (
    <div
      role="status"
      className="border border-gray-300 shadow animate-pulse md:p-6 mb-10 dark:border-gray-700"
    >
      <div className="lg:grid-rows-4 md:grid-rows-3 flex w-full justify-between flex-wrap">
        <div>
          <div className="h-2.5 ms-2 mb-4 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          <div className="h-2.5 ms-2 mb-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
        </div>
        <div className="md:mt-0 mt-2 ">
          <div>
            <div className="h-2.5 ms-2 mb-4 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
            <div className="h-2.5 ms-2 mb-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
            <div className="h-2.5 ms-2 mb-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
            <div className="h-2.5 ms-2 mb-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
          </div>
        </div>
        <div>
          <div>
            <div className="h-2.5 ms-2 mb-4 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
            <div className="h-2.5 ms-2 mb-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
            <div className="h-2.5 ms-2 mb-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
            <div className="h-2.5 ms-2 mb-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
          </div>
        </div>
        <div className="md:mt-0 mt-3">
          <div>
            <div className="h-2.5 ms-2 mb-4 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
            <div className="h-2.5 ms-2 mb-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
          </div>
        </div>
      </div>
      <div className="flex w-full my-4 flex-col bg-white border-gray-200 mb-6 border rounded-lg">
        <div className="bg-gray-100 flex  p-4">
          <div className="h-2.5 bg-white rounded-full dark:bg-gray-700 w-20"></div>
        </div>

        <div className="p-4 py-6">
          <div className="grid sm:grid-cols-2 mb-6">
            <div className="flex">
              <div className="h-2.5 mr-2 bg-gray-300 rounded-full dark:bg-gray-700 w-40"></div>
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-600 w-24"></div>
            </div>
            <div className="flex">
              <div className="h-2.5 mr-2 bg-gray-300 rounded-full dark:bg-gray-700 w-40"></div>
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-600 w-24"></div>
            </div>
          </div>
          <div className="grid sm:grid-cols-2 mb-6">
            <div className="flex">
              <div className="h-2.5 mr-2 bg-gray-300 rounded-full dark:bg-gray-700 w-40"></div>
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-600 w-24"></div>
            </div>
            <div className="flex">
              <div className="h-2.5 mr-2 bg-gray-300 rounded-full dark:bg-gray-700 w-40"></div>
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-600 w-24"></div>
            </div>
          </div>
        </div>
        <div className="flex p-4 py-6 border-t border-gray-200">
          <div className="h-8 mr-4 w-28 bg-gray-100 rounded-md"></div>
          <div className="h-8 mr-4 w-28 bg-gray-100 rounded-md"></div>
        </div>
      </div>
      <div className="flex w-full my-6 flex-col bg-white border-gray-200 border rounded-lg">
        <div className="bg-gray-100 flex justify-between p-4">
          <div className="h-2.5 bg-white rounded-full dark:bg-gray-700 w-32"></div>
          <div className="flex">
            <div className="h-2.5 bg-white rounded-full dark:bg-gray-700 ml-12 w-20"></div>
            <div className="h-2.5 bg-white rounded-full dark:bg-gray-700 ml-12 w-20"></div>
            <div className="h-2.5 bg-white rounded-full dark:bg-gray-700 ml-12 w-20"></div>
          </div>
        </div>
        <div className="space-y-4 border border-gray-200 divide-y divide-gray-200">
          <div className="flex items-center justify-between  p-4 py-6">
            <div className="flex items-center">
              <div className="flex mr-4 items-center justify-center rounded-md h-10 w-10 bg-gray-100 dark:bg-gray-700">
                <svg
                  className="w-6 h-6 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-32"></div>
            </div>
            <div className="flex ">
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
            </div>
          </div>
          <div className="flex items-center justify-between  p-4 py-6">
            <div className="flex items-center">
              <div className="flex mr-4 items-center justify-center rounded-md h-10 w-10 bg-gray-100 dark:bg-gray-700">
                <svg
                  className="w-6 h-6 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-32"></div>
            </div>
            <div className="flex ">
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
            </div>
          </div>
          <div className="flex items-center justify-between  p-4 py-6">
            <div className="flex items-center">
              <div className="flex mr-4 items-center justify-center rounded-md h-10 w-10 bg-gray-100 dark:bg-gray-700">
                <svg
                  className="w-6 h-6 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-32"></div>
            </div>
            <div className="flex ">
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
              <div className="h-2.5 bg-gray-200 rounded-full ml-12 dark:bg-gray-700 w-20"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-2.5 bg-gray-200  dark:bg-gray-700 w-48 mb-4"></div>
      <div className="h-2 bg-gray-200  dark:bg-gray-700 mb-2.5"></div>
      <div className="h-2 bg-gray-200  dark:bg-gray-700 mb-2.5"></div>
      <div className="h-2 bg-gray-200  dark:bg-gray-700"></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default SkeletonYourOrders;
