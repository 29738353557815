import GoogleRecaptcha, {
  GoogleRecaptchaRef,
} from "components/GoogleRecaptcha";
import { BaseProp } from "entities/BaseProp";
import React, { FC, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputComponent from "containers/Careers/InputComponent";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import { BaseFilterString } from "entities/BaseEntities";
import { UserForm } from "entities/ContestDTO";
import handleScrollToEl from "utils/HandleScrollToEl";
import AppendFormData from "utils/AppendFormData";
import axiosInstance from "api/AxiosInstance";
import ErrorMessage from "components/ErrorMessage";
import PageLoader from "components/CSCLedLoader/PageLoader";
import SuccessMessage from "components/SuccessMessage";
import Divider from "components/Divider";

interface ApplyForContestProp extends BaseProp {
  contestId: number;
  contestTitle: string;
  FormTitle: string;
  occupationList: BaseFilterString[];
  userFormList: UserForm[];
}

interface FormSchema {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  attachment: FileList | null;
  occupation: string;
  companyName: string;
  branchLocation: string;
}

const ApplyForContest: FC<ApplyForContestProp> = ({
  className = "",
  contestId,
  contestTitle,
  FormTitle,
  occupationList,
  userFormList,
}) => {
  const [formControls, setFormControls] = useState<string[]>(
    userFormList.map((item) => item.controlName)
  );
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isConfirmMsg, setConfirmMsg] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const recaptchaRef = useRef<GoogleRecaptchaRef>(null);

  const schema = yup.object().shape({
    firstName: formControls.includes("FirstName")
      ? yup
          .string()
          .matches(
            /^[A-Za-z ]*$/,
            "First name must contain only letters and spaces."
          )
          .transform((value) =>
            typeof value === "string" ? value.trim() : value
          )
          .required("First name is required.")
          .trim()
          .max(30, "Maximum 30 characters are allowed.")
      : yup.string(),
    lastName: formControls.includes("LastName")
      ? yup
          .string()
          .matches(
            /^[A-Za-z ]*$/,
            "Last name must contain only letters and spaces."
          )
          .transform((value) =>
            typeof value === "string" ? value.trim() : value
          )
          .required("Last name is required.")
          .max(30, "Maximum 30 characters are allowed.")
      : yup.string(),
    email: formControls.includes("Email")
      ? yup
          .string()
          .transform((value) =>
            typeof value === "string" ? value.trim() : value
          )
          .email("Invalid email.")
          .required("Email is required.")
          .max(70, "Maximum 70 characters are allowed.")
      : yup.string().email(),
    phoneNo: formControls.includes("PhoneNumber")
      ? yup.string().required("Phone number is required.")
      : yup.string(),
    companyName: formControls.includes("CompanyName")
      ? yup
          .string()
          .transform((value) =>
            typeof value === "string" ? value.trim() : value
          )
          .required("Company Name is required.")
      : yup.string(),
    branchLocation: formControls.includes("BranchLocation")
      ? yup
          .string()
          .transform((value) =>
            typeof value === "string" ? value.trim() : value
          )
          .required("Branch Location is required.")
      : yup.string(),
    attachment: formControls.includes("Attachment")
      ? yup
          .mixed()
          .required("Please select a file")
          .test(
            "fileType",
            "Please update a .pdf, .jpg, .jpeg, or .png file.",
            (value: any) => {
              if (!value) return true;
              const fileExtension = value[0]?.name.split(".").pop();
              const allowedExtensions = ["pdf", "jpg", "jpeg", "png"];
              return allowedExtensions.includes(fileExtension || "");
            }
          )
      : yup.mixed(),
  });

  // Use the useForm hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormSchema>({
    resolver: yupResolver(schema) as any,
  });

  const scrollToErrorMsg = (value: string) => {
    setErrorText(value);
    handleScrollToEl("ErrorMessage");
  };

  const handleRecaptchaReset = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaToken(value);
  };

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    if (!recaptchaToken) {
      scrollToErrorMsg(
        "Please indicate you are not a robot using the captcha below, and resubmit the form."
      );
      return;
    }
    let formData = AppendFormData(data) as FormData;
    formData.append("ContestId", contestId.toLocaleString());
    formData.append("ContestTitle", contestTitle);

    //const hookData = getValues();
    setIsPageLoading(true);
    const response = await axiosInstance
      .post("/Home/applyForContest", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          GRecAuthorization: `Bearer ${recaptchaToken}`,
        },
      })
      .then((response) => {
        setIsPageLoading(false);
        setConfirmMsg(response.data as boolean);
        setErrorText(null);
        handleScrollToEl("FormScrollDiv");
      })
      .catch((error) => {
        setIsPageLoading(false);
        if (error.response.status === 401) {
          scrollToErrorMsg(
            `Please indicate you are not a robot using the captcha below, and resubmit the form.`
          );
        } else if (typeof error.response.data.message === "string") {
          scrollToErrorMsg(error.response.data.message);
          handleRecaptchaReset();
        } else {
          scrollToErrorMsg("Something went wrong.");
        }
      });
  };

  const renderFormBody = () => {
    return (
      <div className={`mx-auto space-y-6 ${className}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-grow mt-10 md:mt-0 space-y-6">
            <div id="ErrorMessage" className="scroll-mt-32">
              {errorText && <ErrorMessage errorTitle={errorText} />}
            </div>
            {/* ============ */}

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="grid grid-cols-1 gap-4 sm:gap-3">
                {formControls.includes("FirstName") && (
                  <InputComponent
                    labelTextName="First Name"
                    register={register("firstName")}
                    errorMsg={errors.firstName}
                    autoComplete="Off"
                  />
                )}
                {formControls.includes("LastName") && (
                  <InputComponent
                    labelTextName="Last Name"
                    register={register("lastName")}
                    errorMsg={errors.lastName}
                    autoComplete="Off"
                  />
                )}
                {formControls.includes("Email") && (
                  <InputComponent
                    labelTextName="Email"
                    register={register("email")}
                    errorMsg={errors.email}
                    autoComplete="Off"
                  />
                )}
                {formControls.includes("PhoneNumber") && (
                  <InputComponent
                    labelTextName="Phone Number"
                    register={register("phoneNo")}
                    errorMsg={errors.phoneNo}
                    mask="(999) 999-9999"
                    autoComplete="Off"
                  />
                )}
              </div>
              <div className="grid grid-cols-1 gap-4 sm:gap-3">
                <div>
                  <Label>Occupation</Label>
                  <Select className="mt-1.5" register={register("occupation")}>
                    {occupationList.map((item, index) => (
                      <option key={index} value={item.filterId}>
                        {item.filterName}
                      </option>
                    ))}
                  </Select>
                </div>
                {formControls.includes("CompanyName") && (
                  <InputComponent
                    labelTextName="Company Name"
                    register={register("companyName")}
                    errorMsg={errors.companyName}
                    autoComplete="Off"
                  />
                )}
                {formControls.includes("BranchLocation") && (
                  <InputComponent
                    labelTextName="Branch Location"
                    register={register("branchLocation")}
                    errorMsg={errors.branchLocation}
                    autoComplete="Off"
                  />
                )}
                {formControls.includes("Attachment") && (
                  <InputComponent
                    placeholder=""
                    type={"file"}
                    labelTextName="Attachment"
                    register={register("attachment")}
                    errorMsg={errors.attachment}
                    autoComplete="Off"
                  />
                )}
              </div>
            </div>
            <GoogleRecaptcha onChange={handleRecaptchaChange} />
            <div className="flex flex-col sm:flex-row pt-6">
              <ButtonPrimary className="sm:!px-7 shadow-none">
                Send Submission
              </ButtonPrimary>
              <ButtonSecondary className="mt-3 sm:mt-0 sm:ml-3" href="/contest">
                Cancel
              </ButtonSecondary>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div
      id="FormScrollDiv"
      className="w-full scroll-mt-32 space-y-10 lg:space-y-14"
    >
      {isConfirmMsg ? (
        <SuccessMessage
          headerTitle="Thank you for participating. Should you win, we will be in touch via the email you provided in your submission."
          bodyMessage={`CSC LED Marketing Team`}
        />
      ) : (
        <>
          <Divider/>
          <h2 className="block text-2xl sm:text-3xl font-medium">
            {FormTitle}
          </h2>
          {renderFormBody()}
        </>
      )}
      <PageLoader isLoading={isPageLoading} />
    </div>
  );
};

export default ApplyForContest;
