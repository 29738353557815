import React, { FC } from "react";
import CurrencyFormatter from "./CurrencyFormatter";

export interface PricesProps {
  className?: string;
  price?: number;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
}) => (
  <div className={`${className}`}>
    <div
      style={{ borderColor: "#27AD1F" }}
      className={`flex items-center border-2 halloween:border-white rounded-lg ${contentClass}`}
    >
      <span className="text-csccolor-green halloween:text-white !leading-none">
        <CurrencyFormatter value={price}/>
      </span>
    </div>
  </div>
);

export default Prices;
