import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ResetPasswordParams } from "routers/types";
import { Helmet } from "react-helmet-async";
import { useNavigate, Link } from "react-router-dom";
import axiosInstance from "api/AxiosInstance";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import reset_password_icon from "images/emails_icon/reset_password_icon.png";

export interface PageResetPasswordProps {
  className?: string;
}

const PageResetPassword: FC<PageResetPasswordProps> = ({ className = "" }) => {
  let { resetCode, mode } = useParams<ResetPasswordParams>();
  const [msg, SetMsg] = useState("");
  const [currentMode, setMode] = useState(mode);
  const [formData, setFormData] = useState({
    NewPassword: "",
    ConfirmPassword: "",
    ResetCode: resetCode,
    Mode: mode,
    token: "",
  });
  const [errors, setErrors] = useState({
    NewPassword: "",
    ConfirmPassword: "",
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    let errorMessage = "";
    switch (id) {
      case "NewPassword":
        errorMessage =
          value.trim() === ""
            ? "New password required"
            : value.length < 6
            ? "Your password must be at least 6 characters."
            : "";
        break;
      case "ConfirmPassword":
        errorMessage =
          value !== formData.NewPassword
            ? "Your passwords did not match, please try again."
            : "";
        break;
      default:
        break;
    }
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: errorMessage });
  };
  const validateForm = () => {
    const newResetErrors: any = {};
    if (!formData.NewPassword.trim()) {
      newResetErrors.NewPassword = "New password required";
    }
    if (formData.NewPassword !== formData.ConfirmPassword) {
      newResetErrors.ConfirmPassword =
        "Your passwords did not match, please try again.";
    }
    setErrors(newResetErrors);
    return Object.keys(newResetErrors).length != 0 ? true : false;
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    var isValid = validateForm();
    if (isValid) {
      return;
    }
    try {
      const url =
        mode == "NewAccount"
          ? "SFRegister/SetPasswordLogin"
          : "SFRegister/ResetPassword";
      // Send data to the API using Axios
      await axiosInstance.post(url, formData).then((response) => {
        if (response.data == "Success") setMode(response.data);
        else SetMsg(response.data);
      });
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <div>
      {currentMode && currentMode == "NewAccount" && (
        <div
          className={`nc-PageNewAccount ${className}`}
          data-nc-id="PageResetNewAccount"
        >
          <Helmet>
            <title>Set your password and log in | CSC LED</title>
          </Helmet>
          <div className="container mb-24 lg:mb-32">
            <h2 className="my-20 mt-10 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%]  font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Set your password and log in
            </h2>

            <div className="mx-5">
              <hr
                className="border-t border-gray-300"
                style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
              />
            </div>
            <div className="max-w-md mx-auto space-y-6">
              <form
                className="grid grid-cols-1 gap-6"
                onSubmit={handleSubmit}
                method="post"
              >
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    New Password
                  </span>
                  <Input
                    id="newPassword"
                    type="password"
                    className="mt-1"
                    onChange={handleInput}
                    value={formData.NewPassword}
                    style={{
                      border:
                        errors.NewPassword && errors.NewPassword != ""
                          ? "1px solid #fd397a"
                          : "",
                    }}
                  />
                  {errors.NewPassword && (
                    <span style={{ color: "#fd397a" }}>
                      {errors.NewPassword}
                    </span>
                  )}
                </label>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Confirm Password
                  </span>
                  <Input
                    id="confirmPassword"
                    type="password"
                    className="mt-1"
                    onChange={handleInput}
                    value={formData.ConfirmPassword}
                    style={{
                      border:
                        errors.ConfirmPassword && errors.ConfirmPassword != ""
                          ? "1px solid #fd397a"
                          : "",
                    }}
                  />
                  {errors.ConfirmPassword && (
                    <span style={{ color: "#fd397a" }}>
                      {errors.ConfirmPassword}
                    </span>
                  )}
                </label>
                <ButtonPrimary type="submit">Set password</ButtonPrimary>
              </form>
              {/* <NewAccountForm onSubmit={handleSubmit} ResetCode={resetCode} Mode={mode} /> */}
            </div>
          </div>
        </div>
      )}

      {currentMode && currentMode == "Success" && (
        <div className={`nc-PageSuccess ${className}`} data-nc-id="PageSuccess">
          <Helmet>
            <title>Password Saved | CSC LED</title>
          </Helmet>
          <div className="container mb-12 sm:mb-24 lg:mb-32">
            <h2 className="my-20 mt-10 flex items-center text-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              We did it! Your password has been reset.
            </h2>
            <div className="">
              <hr
                className="border-t border-gray-300"
                style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
              />
            </div>
            <div className="text-center alert alert-success mb-8" role="alert">
              You're good to go again. Click the link below to head over to the
              login page when you're ready.
            </div>
            <div className="max-w-md mx-auto space-y-6 flex justify-center">
              <ButtonPrimary type="button" href={"/login"}>
                I'm ready computer!
              </ButtonPrimary>
            </div>
          </div>
        </div>
      )}

      {currentMode == "InvalidRequest" && (
        <div
          className={`nc-PageInvalidRequest ${className}`}
          data-nc-id="PageInvalidRequest"
        >
          <Helmet>
            <title>Invalid Request | CSC LED</title>
          </Helmet>
          <div className="container mb-24 lg:mb-32">
            <div className="w-full md:flex hidden items-center">
              <div className="w-32 mr-6">
                <img
                  className="w-full h-full object-contain"
                  src={reset_password_icon}
                  alt="Password Reset Email Icon"
                />
              </div>

              <div className="w-full mt-4">
                <h1 className="IconHeaderStyle sm:text-3xl text-2xl capitalize text-gray-800">
                  password reset Link
                </h1>
                <h1 className="text-base text-gray-600 my-2">
                  Something's not right here. It looks like your password reset
                  link has already been used.
                  <br />
                  You can either try the forgot password button again on the Log
                  In page, or you can contact <strong>
                    info@csc-led.com
                  </strong>{" "}
                  for help.
                </h1>
              </div>
            </div>
            <div className="w-full md:hidden block  items-center">
              <div className="flex w-full items-center">
                <div className="w-16 mr-6">
                  <img
                    className="w-full h-full object-contain"
                    src={reset_password_icon}
                    alt="Password Reset Email Icon"
                  />
                </div>
                <h1 className="IconHeaderStyle sm:text-3xl text-2xl capitalize text-gray-800">
                  password reset Link
                </h1>
              </div>
              <div className="w-full mt-4">
                <h1 className="text-base text-gray-600 my-2">
                  Something's not right here. It looks like your password reset
                  link has already been used.
                  <br />
                  You can either try the forgot password button again on the Log
                  In page, or you can contact <strong>
                    info@csc-led.com
                  </strong>{" "}
                  for help.
                </h1>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentMode != "NewAccount" &&
        currentMode != "Success" &&
        currentMode != "InvalidRequest" && (
          <div
            className={`nc-PageResetPassword ${className}`}
            data-nc-id="PageResetPassword"
          >
            <Helmet>
              <title>Set your password and log in | CSC LED</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
              <h3 className="my-20 mt-10 flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                What do you want your new password to be?
              </h3>
              <div className="mx-5">
                <hr
                  className="border-t border-gray-300"
                  style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
                />
              </div>
              {msg && msg != "" && (
                <div
                  className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                >
                  <span className="font-medium">{msg}</span>
                </div>
              )}
              <div className="max-w-md mx-auto space-y-6">
                <form
                  className="grid grid-cols-1 gap-6"
                  onSubmit={handleSubmit}
                  method="post"
                >
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      New Password
                    </span>
                    <Input
                      id="NewPassword"
                      type="password"
                      className="mt-1"
                      onChange={handleInput}
                      value={formData.NewPassword}
                      style={{
                        border:
                          errors.NewPassword && errors.NewPassword != ""
                            ? "1px solid #fd397a"
                            : "",
                      }}
                    />
                    {errors.NewPassword && (
                      <span style={{ color: "#fd397a" }}>
                        {errors.NewPassword}
                      </span>
                    )}
                  </label>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Confirm Password
                    </span>
                    <Input
                      id="ConfirmPassword"
                      type="password"
                      className="mt-1"
                      onChange={handleInput}
                      value={formData.ConfirmPassword}
                      style={{
                        border:
                          errors.ConfirmPassword && errors.ConfirmPassword != ""
                            ? "1px solid #fd397a"
                            : "",
                      }}
                    />
                    {errors.ConfirmPassword && (
                      <span style={{ color: "#fd397a" }}>
                        {errors.ConfirmPassword}
                      </span>
                    )}
                  </label>
                  <ButtonPrimary type="submit">Save New Password</ButtonPrimary>
                </form>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default PageResetPassword;
