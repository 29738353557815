import React, { FC, useEffect, useRef, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BaseProp } from "entities/BaseProp";
import Autocomplete from "components/Autocomplete/Autocomplete";
import UseAutocomplete from "components/Autocomplete/UseAutocomplete";
import { SearchBarDTO } from "entities/SearchBarDTO";
import axiosInstance from "api/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { stringToSlug } from "utils/stringToSlug";

interface SearchBarProps extends BaseProp {
  setShowSearchForm?: (isShow: boolean) => void;
  onClickClose?: () => void;
}

const SearchBar: FC<SearchBarProps> = ({
  className = "",
  setShowSearchForm,
  onClickClose,
}) => {
  const navigate = useNavigate();
  const inputRef = React.createRef<HTMLInputElement>();
  const [dataResult, setDataResult] = useState<SearchBarDTO[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowSearchForm?.(false);
        clearInputVal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getProductSearchContent")
      .then((response) => {
        if (response.data.data) {
          setDataResult(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    searchedValue,
    suggestions,
    selectedSuggestion,
    activeSuggestion,
    handleChange,
    handleKeyDown,
    handleClick,
    clearInputVal,
  } = UseAutocomplete(dataResult, inputRef.current);

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderAutoComplete = () => {
    return (
      <div>
        <Autocomplete
          searchedValue={searchedValue}
          suggestions={suggestions}
          activeSuggestion={activeSuggestion}
          selectedSuggestion={selectedSuggestion}
          handleClick={handleClick}
          onClickClose={onClickClose}
        />
      </div>
    );
  };

  const onFormSubmit = () => {
    var url = selectedSuggestion ? `/product-details/${stringToSlug(searchedValue).toUpperCase()}` : `/products?psearch=${searchedValue}`;
    navigate(`${url}`);
  };

  const renderWebView = () => {
    return (
      <div
        ref={dropdownRef}
        className="nc-WebSearchBar hidden lg:flex lg:flex-1"
        id="nc-WebSearchBar"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onFormSubmit();
            setShowSearchForm?.(false);
          }}
          className="nc-SearchBar flex-1 py-2 text-slate-900 dark:text-slate-100"
        >
          <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
            {renderMagnifyingGlassIcon()}
            <input
              value={searchedValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              type="text"
              placeholder="Enter a product number"
              className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
              autoFocus
            />
            {setShowSearchForm && (
              <button type="button" onClick={() => setShowSearchForm(false)}>
                <XMarkIcon className="w-5 h-5" />
              </button>
            )}
          </div>
          {renderAutoComplete()}
          <input type="submit" hidden value="" />
        </form>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div
        ref={dropdownRef}
        className="nc-MobileSearchBar flex flex-1 xl:hidden"
        id="nc-MobileSearchBar"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onFormSubmit();
            onClickClose?.();
          }}
          className="flex-1 text-slate-900 dark:text-slate-200"
        >
          <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1 py-2 px-4 rounded-xl">
            {renderMagnifyingGlassIcon()}
            <input
              value={searchedValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              type="search"
              placeholder="Enter a product number"
              className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm "
              autoFocus
            />
          </div>
          {renderAutoComplete()}
          <input type="submit" hidden value="" />
        </form>
      </div>
    );
  };

  return (
    <>
      {setShowSearchForm && renderWebView()}
      {onClickClose && renderMobileView()}
    </>
  );
};

export default SearchBar;
