import React, { FC, useEffect, useState } from "react";
import JobOpeningsComponent from "./JobOpeningsComponent";
import Badge from "shared/Badge/Badge";
import NcLink from "shared/NcLink/NcLink";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { setActiveLocation } from "../../store/slices/careerSlice";
import { careerDTO } from "entities/careerDTO";

interface JobComponentProp {
  classNameName?: string;
}

const JobComponent: FC<JobComponentProp> = ({ classNameName = "" }) => {
  const args = useAppSelector((state) => state.career.data) as careerDTO;
  const activeLocation = useAppSelector(
    (state) => state.career.activeLocation
  ) as string;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeLocation && args?.jobLocationList) {
      setLocation();
    }
  }, [dispatch, activeLocation, args?.jobLocationList]);

  const setLocation = () => {
    if (!activeLocation && args?.jobLocationList) {
      for (const [location, count] of Object.entries(args.jobLocationList)) {
        if (count > 0) {
          dispatch(setActiveLocation(location));
          break;
        }
      }

      if (activeLocation === null && args.jobLocationList.length > 0) {
        const firstLocation = Object.keys(args.jobLocationList)[0];
        dispatch(setActiveLocation(firstLocation));
      }
    }
  };

  const handleClick = (location: string) => {
    dispatch(setActiveLocation(location));
  };

  return (
    <div className="nc-Job flex flex-col lg:flex-row my-2">
      <div className="lg:w-1/4" id="menuItems">
        <ul
          className="mr-4 flex list-none flex-col flex-wrap pl-0 border border-neutral-100 halloween:border-white rounded-md"
          role="tablist"
          data-te-nav-ref
        >
          {args?.jobLocationList &&
            Object.entries(args.jobLocationList).map(
              ([location, count], index) => (
                <li key={location} role="presentation" className={`flex-grow`}>
                  <NcLink
                    to={""}
                    onClick={() => handleClick(location)}
                    className={`${
                      activeLocation === location
                        ? "border-black bg-neutral-100 halloween:bg-black halloween:text-white"
                        : ""
                    } border-l-2 border-transparent flex items-center px-7 pb-3.5 pt-4 
                    text-black font-medium hover:isolate hover:bg-neutral-100 focus:isolate dark:text-neutral-400 dark:hover:bg-transparent`}
                  >
                    <span className="flex-grow">{location}</span>
                    <Badge name={count} color="purple" />
                  </NcLink>
                </li>
              )
            )}
        </ul>
      </div>
      <JobOpeningsComponent />
    </div>
  );
};

export default JobComponent;
