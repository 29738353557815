import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { getCareerData } from "../../store/slices/careerSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Textarea from "shared/Textarea/Textarea";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputComponent from "./InputComponent";
import axiosInstance from "api/AxiosInstance";
import AppendFormData from "utils/AppendFormData";
import GoogleRecaptcha from "components/GoogleRecaptcha";
import ErrorMessage from "components/ErrorMessage";
import handleScrollToEl from "utils/HandleScrollToEl";
import HeadingText1 from "components/HeadingText/HeadingText1";

interface ApplyNowProps {
  className?: string;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  position: string;
  resume: FileList | null;
  notes: string;
}

// Define the validation schema
const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required.")
    .max(30, "Maximum 30 characters are allowed."),
  lastName: yup
    .string()
    .required("Last name is required.")
    .max(30, "Maximum 30 characters are allowed."),
  email: yup
    .string()
    .email("Invalid email.")
    .required("Email is required.")
    .max(70, "Maximum 70 characters are allowed."),
  position: yup
    .string()
    .required("Position is required.")
    .max(30, "Maximum 30 characters are allowed."),
  notes: yup.string().max(500, "Maximum 500 characters are allowed."),
  resume: yup
    .mixed()
    .test(
      "fileType",
      "Please update a .pdf, .doc, or .docx file.",
      (value: any) => {
        if (!value) return true;
        const fileExtension = value[0]?.name.split(".").pop();
        const allowedExtensions = ["pdf", "doc", "docx"];
        return allowedExtensions.includes(fileExtension || "");
      }
    ),
});

const ApplyNow: FC<ApplyNowProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const careerData = useAppSelector((state) => state.career.data) as any;

  useEffect(() => {
    if (!careerData) {
      dispatch(getCareerData());
    }
    handleScrollToEl("divApplyNow");
  }, [dispatch, careerData]);

  // Use the useForm hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>({
    resolver: yupResolver(schema) as any,
  });

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isConfirmMsg, setConfirmMsg] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaToken(value);
  };

  const scrollToErrorMsg = (value: string) => {
    setErrorText(value);
    handleScrollToEl("ErrorMessage");
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (!recaptchaToken) {
      scrollToErrorMsg(
        "Please indicate you are not a robot using the captcha below, and resubmit the form."
      );
      return;
    }

    let formData = AppendFormData(data);

    //const hookData = getValues();
    setIsSubmitting(true);
    const response = await axiosInstance
      .post("/Home/applyForCareer", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          GRecAuthorization: `Bearer ${recaptchaToken}`,
        },
      })
      .then((response) => {
        //console.log("File upload successful:", response.data);
        setConfirmMsg(response.data as boolean);
        setErrorText(null);
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response.status === 401) {
          scrollToErrorMsg(
            `Please indicate you are not a robot using the captcha below, and resubmit the form.`
          );
        } else {
          scrollToErrorMsg("Something went wrong.");
        }
      });
  };

  const applyNowForm = () => {
    return (
      <div className="space-y-3">
        {/* HEADING */}
        <div>
          <HeadingText1 className="font-poppins">Apply Now</HeadingText1>
          <div className="mt-2 text-black dark:text-slate-400 text-sm sm:text-base">
            See something that looks like a good fit? Complete the form below
            and we will be in touch!
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-grow mt-10 max-w-3xl md:mt-0 space-y-6">
            <div id="ErrorMessage" className="scroll-mt-32">
              {errorText && <ErrorMessage errorTitle={errorText} />}
            </div>

            {/* ============ */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
              <InputComponent
                labelTextName="First Name"
                register={register("firstName")}
                errorMsg={errors.firstName}
                autoComplete="Off"
              />
              <div>
                <InputComponent
                  labelTextName="Last Name"
                  register={register("lastName")}
                  errorMsg={errors.lastName}
                  autoComplete="Off"
                />
              </div>
            </div>
            {/* ============ */}
            <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
              <div className="flex-1">
                <InputComponent
                  type={"text"}
                  labelTextName="Email"
                  register={register("email")}
                  errorMsg={errors.email}
                  autoComplete="Off"
                />
              </div>
            </div>
            {/* ============ */}
            <div className="grid grid-cols-1">
              <InputComponent
                placeholder=""
                type={"file"}
                labelTextName="Resume"
                register={register("resume")}
                errorMsg={errors.resume}
                autoComplete="Off"
              />
            </div>

            {/* ============ */}
            <div className="grid grid-cols-1">
              <InputComponent
                placeholder=""
                type={"text"}
                labelTextName="Which position are you interested in?"
                register={register("position")}
                errorMsg={errors.position}
                autoComplete="Off"
              />
            </div>

            {/* ============ */}
            <div className="grid grid-cols-1">
              <div>
                <Label className="text-base font-medium text-neutral-900 dark:text-neutral-200">
                  Anything else we should know?
                </Label>
                <Textarea
                  className="mt-1.5"
                  {...register("notes")}
                  autoComplete="Off"
                />
              </div>
            </div>
            <GoogleRecaptcha onChange={handleRecaptchaChange} />
            <div className="flex flex-col sm:flex-row pt-6">
              <ButtonPrimary
                className="sm:!px-7 shadow-none"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Send My Application
              </ButtonPrimary>
              <ButtonSecondary className="mt-3 sm:mt-0 sm:ml-3" href="/careers">
                Cancel
              </ButtonSecondary>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const confirmationMessage = () => {
    return (
      <div>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-2xl text-csccolor font-semibold">
            Your application has been received!
          </h2>
          <div className="flex-col text-sm mt-8 space-y-4 sm:text-base">
            <p>Thank you for your interest and application.</p>
            <p>We will be in touch as soon as we are able.</p>
            <p>
              Talk soon,
              <br />
              CSC LED Team
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-ApplyNow relative overflow-hidden">
      <Helmet>
        <title>Careers | CSC LED</title>
      </Helmet>
      {careerData?.careerBanner && (
        <div className="relative overflow-hidden lg:aspect-w-16 lg:aspect-h-8 2xl:aspect-h-7">
          <img
            className="relative w-full h-full object-fill"
            src={`${careerData.careerBanner}`}
            alt="Career Banner"
          />
        </div>
      )}

      <div
        className="container mt-5 lg:mt-11 pb-16 scroll-mt-32"
        id="divApplyNow"
      >
        {!isConfirmMsg ? applyNowForm() : confirmationMessage()}
      </div>
    </div>
  );
};

export default ApplyNow;
