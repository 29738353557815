import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import InvParameters from "./InvParameters";
import APIQuestion from "./APIQuestion";
import APIEndpointSample from "./APIEndpointSample";
import InvTokenRefresh from "./InvTokenRefresh";
import GenerateAccessToken from "./GenerateAccessToken";

interface TechInvAPIProps {
  className?: string;
}

const TechInvAPI: FC<TechInvAPIProps> = ({ className }) => {
  return (
    <div className={`nc-TechnicalInventoryAPI ${className}`} data-nc-id="TechnicalInventoryAPI">
      <Helmet>
        <title>CSC LED Technical Inventory API | CSC LED</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              CSC LED Technical Inventory API
            </h2>
          </div>
          <GenerateAccessToken />
          <InvTokenRefresh/>
          <APIEndpointSample/>
          <APIQuestion/>
          <InvParameters/>
        </div>
      </div>
    </div>
  );
};

export default TechInvAPI;
