import { BaseProp } from "entities/BaseProp";
import React, { FC } from "react";

interface Props extends BaseProp {
  children?: React.ReactNode;
}

const HeadingText2: FC<Props> = ({ 
    className = "my-2"
    , children 
}) => {
  return (
    <h2 className={`nc-HeaderText2 text-2xl font-semibold ${className}`}>
        {children}
    </h2>
  );
};

export default HeadingText2;
